import React, { useState } from "react";
import "./RefferCard.scss";

const RefferCard = () => {
  const [showAllTransaction, setShowAllTransaction] = useState(false);

  const toggleShowAllTransaction = () => {
    setShowAllTransaction(!showAllTransaction);
  };
  const data = [
    {
      id: 1,
      pmail: "Direct, email, IM",
      pview: "34",
      psales: "34",
      pconvertion: "10.5%",
      ptotal: "110",
    },

    {
      pmail: "dribbble.com",
      id: 2,
      pview: "23",
      psales: "75",
      pconvertion: "10.5%",
      ptotal: "110",
    },

    {
      id: 3,
      pmail: "Direct, email, IM",
      pview: "34",
      psales: "34",
      pconvertion: "10.5%",
      ptotal: "110",
    },

    {
      id: 4,
      pmail: "Direct, email, IM",
      pview: "34",
      psales: "34",
      pconvertion: "10.5%",
      ptotal: "110",
    },

    {
      pmail: "dribbble.com",
      id: 5,
      pview: "23",
      psales: "75",
      pconvertion: "10.5%",
      ptotal: "110",
    },

    {
      id: 6,
      pmail: "Direct, email, IM",
      pview: "34",
      psales: "34",
      pconvertion: "10.5%",
      ptotal: "110",
    },

    {
      id: 7,
      pmail: "Direct, email, IM",
      pview: "34",
      psales: "34",
      pconvertion: "10.5%",
      ptotal: "110",
    },
  ];
  return (
    <div className="custom-wrapper">
      <div className="card-header  d-flex justify-content-between">
        <div className="card-title">Referrer</div>
        <div className="cu-pointer  add-icon " type="button">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M7 12C7 10.8954 6.10457 10 5 10C3.89543 10 3 10.8954 3 12C3 13.1046 3.89543 14 5 14C6.10457 14 7 13.1046 7 12Z"
              fill="#92929D"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14C13.1046 14 14 13.1046 14 12Z"
              fill="#92929D"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M21 12C21 10.8954 20.1046 10 19 10C17.8954 10 17 10.8954 17 12C17 13.1046 17.8954 14 19 14C20.1046 14 21 13.1046 21 12Z"
              fill="#92929D"
            />
          </svg>
        </div>
      </div>
      <div className="Reffer-Card-wrapper   d-flex flex-column gap-4">
        <div className="card-content-otr">
          <div className="table-header d-flex ">
            <div className="header-title web-section">Referrer Sources</div>
            <div className="header-title view-section">View</div>
            <div className="header-title sale-section">Sales</div>
            <div className="header-title convertion-section  ">Convertion</div>
            <div className="header-title total-section d-flex justify-content-end">
              Total
            </div>
          </div>

          <div className="table-data  ">
            {data
              .slice(0, showAllTransaction ? data.length : 4)
              .map((item, index) => (
                <div className="record-row  d-flex py-2">
                  <div className="web-section d-flex align-items-center  gap-2 ">
                    <div className="table-data-desc">{item.pmail}</div>
                  </div>

                  <div className="view-section table-data-desc">
                    {item.pview}
                  </div>

                  <div className="sale-section table-data-desc">
                    {item.psales}
                  </div>

                  <div className="convertion-section table-data-desc ">
                    {item.pconvertion}
                  </div>

                  <div className="total-section table-data-desc  d-flex justify-content-end">
                    ${item.ptotal}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>

      <div
        className="card-footer d-flex justify-content-center  cu-pointer"
        onClick={toggleShowAllTransaction}
      >
        {showAllTransaction ? "View less Transaction" : "View more Transacton"}
      </div>
    </div>
  );
};

export default RefferCard;
