import React, { useState, useEffect } from "react";

import { Col, Row } from "react-bootstrap";
import SalesCard from "../Components/SalesCard/SalesCard";
import SyntaxHighlighter from "react-syntax-highlighter";
import { atomOneDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import MultipleLineGraph from "../Components/MultipleLineGraph/MultipleLineGraph";
import SalesLocation from "../Components/SalesLocation/SalesLocation";
import { CopyOutlined, CheckOutlined } from "@ant-design/icons";
import PhotoVideo from "../Components/PhotoVideo/PhotoVideo";
import LikePage from "../Components/LikePage/LikePage";
import PubgCard from "../Components/PubgCard/PubgCard";
import NotificationCard from "../Components/NotificationCard/NotificationCard";

const NotificationCode = () => {
  const codeString = `
    import React, { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import "./NotificationCard.scss";
import { Avatar, Space } from "antd";
import { UserOutlined } from "@ant-design/icons";
import img from "../../assets/images/Profile Picture.png";
import img1 from "../../assets/images/Random PP.png";
import img2 from "../../assets/images/profile2.png";
import dd from "../../assets/images/Initial.png";

const data = [
  {
    id: 1,
    pnam: "Any Carrol",
    pdesig: "Mobile Apps Redesign",
    pdesc:
      "Hey Cak, Could you free now? Can you look and read the brief first…",
    ptime: "12:48PM",
    pimg: img,
    file: true,
    un: true,
  },

  {
    id: 2,
    pnam: "Artem Sazonov",
    pdesig: "Codedellaroute Project",
    pdesc:
      "Hey Cak, Could you free now? Can you look and read the brief first…",
    ptime: "10:53PM",
    pimg: img1,
    file: true,
    un: false,
  },

  {
    id: 3,
    pnam: "Victor Pacheco",
    pdesig: "Inviting Join Apps Cont...",
    pdesc:
      "Hey Cak, Could you free now? Can you look and read the brief first…",
    ptime: "10:53PM",
    pimg: dd,
    file: true,
    un: false,
  },

  {
    id: 4,
    pnam: "Ren Xue",
    pdesig: "Fone Dynamics Website",
    pdesc:
      "Hey Cak, Could you free now? Can you look and read the brief first…",
    ptime: "10:53PM",
    pimg: img2,
    file: false,
    un: false,
  },
];

const NotificationCard = () => {
  const [selectedValue, setSelectedValue] = useState("Primary");
  const [Classactive, setClassActive] = useState(0);

  function toggleactive(id) {
    setClassActive(id);
  }

  const handleSelect = (eventKey) => {
    setSelectedValue(eventKey);
  };
  return (
    <div className="notification-card-wrappper  d-flex flex-column  ">
      <div className="d-flex justify-content-between align-items-center  notification-card-header  pb-4">
        <div>
          {" "}
          <Dropdown onSelect={handleSelect}>
            <Dropdown.Toggle
              variant="success"
              id="dropdown-basic"
              className="custom-dropdown"
            >
              {selectedValue}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item eventKey="Primary">Primary</Dropdown.Item>
              <Dropdown.Item eventKey="Secondary">Secondary</Dropdown.Item>
              <Dropdown.Item eventKey="Something else">
                Something else
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>

        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M2.76758 4.32024C5.52651 0.310665 10.8766 -1.1863 15.3835 1.01188C20.3474 3.43294 22.4088 9.41963 19.9878 14.3835C17.5667 19.3474 11.58 21.4088 6.61611 18.9878C4.62017 18.0143 3.03975 16.4346 2.06435 14.4906C1.81667 13.997 2.01606 13.396 2.50969 13.1483C3.00332 12.9006 3.60427 13.1 3.85195 13.5937C4.63236 15.149 5.89453 16.4106 7.49285 17.1902C11.464 19.127 16.2533 17.4779 18.1902 13.5068C20.127 9.53567 18.4779 4.74632 14.5068 2.80947C10.7004 0.95299 6.14234 2.39106 4.06503 6.01063H5.76758C6.31986 6.01063 6.76758 6.45834 6.76758 7.01063C6.76758 7.56291 6.31986 8.01063 5.76758 8.01063H1.76758C1.21529 8.01063 0.767578 7.56291 0.767578 7.01063V3.01063C0.767578 2.45834 1.21529 2.01063 1.76758 2.01063C2.31986 2.01063 2.76758 2.45834 2.76758 3.01063V4.32024Z"
              fill="#92929D"
            />
          </svg>
        </div>
      </div>

      <div className=" notification-contaner d-flex flex-column gap-2">
        {data.map((item, index) => (
          <div
            className="item-wraper"
            onMouseEnter={() => toggleactive(item.id)}
          >
            <div
              className={notiification-item  d-flex  gap-2 {
                item.un ? "bg-dblue " : ""
              }  {Classactive === item.id ? "bg-light" : ""}}
            >
              <div className="pic-area d-flex flex-column gap-2 align-items-center">
                <Avatar size={42} src={item.pimg} />

                <div className={cu-pointer   {item.file ? " " : "d-none"}}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="16"
                    viewBox="0 0 14 16"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M1.76127 6.99539L7.19898 1.55767C8.60921 0.147443 10.8956 0.147443 12.3059 1.55767C13.7161 2.9679 13.7161 5.25433 12.3059 6.66455L6.86815 12.1023C5.99739 12.9337 4.61739 12.9218 3.76166 12.0661C2.89383 11.1982 2.89383 9.7912 3.76166 8.92337L9.16317 3.52186C9.48861 3.19642 10.0162 3.19642 10.3417 3.52186C10.6671 3.84729 10.6671 4.37493 10.3417 4.70037L4.94017 10.1019C4.72321 10.3188 4.72321 10.6706 4.94017 10.8876C5.15713 11.1045 5.50889 11.1045 5.72584 10.8876L11.1274 5.48604C11.8867 4.72669 11.8867 3.49553 11.1274 2.73618C10.368 1.97683 9.13685 1.97683 8.37749 2.73618L2.97599 8.13769C1.67424 9.43944 1.67424 11.55 2.97599 12.8517C4.27773 14.1535 6.38828 14.1535 7.69003 12.8517L12.1094 8.43232C12.4349 8.10688 12.9625 8.10688 13.288 8.43232C13.6134 8.75776 13.6134 9.28539 13.288 9.61083L8.86854 14.0302C6.91592 15.9829 3.7501 15.9829 1.79747 14.0302C-0.143065 12.0897 -0.155073 8.95093 1.76145 6.99557L1.76127 6.99539Z"
                      fill={item.un ? "#FAFAFB" : "#92929D"}
                    />
                  </svg>
                </div>
              </div>

              <div className="notification-desc-area  ">
                <div className="d-flex flex-column gap-2">
                  <div className="">
                    <div className="d-flex justify-content-between align-items-center  pb-1">
                      <div
                        className=font-14 cl-blue 
                          item.un ? "col-white" : "cl-blue"
                        }
                      >
                        {" "}
                        {item.pnam}
                      </div>
                      <div
                        className=font-12 cl-gray  
                          item.un ? "col-white" : "cl-gray"
                        }
                      >
                        {item.ptime}
                      </div>
                    </div>

                    <div
                      className=font-14  font-sb  
                        item.un ? "col-white" : " "
                      
                    >
                      {item.pdesig}
                    </div>
                  </div>

                  <div
                    className={font-14    
                      item.un ? "col-white" : "cl-gray9d"
                    
                  >
                    {item.pdesc}
                  </div>
                </div>
              </div>
            </div>

            <div className="custom-border-element"></div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NotificationCard;
          
                    `;

  const styleString = `
    @import "../../CommonStyle//Variables.scss";
    .notification-card-wrappper {
      background-color: $light;
      border-radius: 15px;
      padding: 20px 0px;
    
      .notification-card-header {
        padding-bottom: 20px;
        border-bottom: 1px solid $darkf15;
        padding-left: 20px;
        padding-right: 20px;
    
        .custom-dropdown {
          background-color: $light !important;
          padding-bottom: 1px solid $darkf15;
          color: $dark4f !important;
          border: 1px solid $lightea;
          font-size: $font-size-14px;
          font-weight: $font-weight-regular;
        }
      }
    
      .notification-contaner {
        padding: 10px;
    
        .item-wraper {
          .notiification-item {
            padding: 15px;
          }
    
          .custom-border-element {
            border-top: 1px solid $darkf15;
            margin-top: 5px;
          }
        }
      }
    
      .font-14 {
        font-size: $font-size-14px;
        font-weight: $font-weight-regular;
        line-height: $line-height-16px;
      }
    
      .font-12 {
        font-size: $font-size-12px;
        line-height: $line-height-22px;
        font-weight: $font-weight-regular;
      }
    
      .font-sb {
        font-weight: $font-weight-semibold !important;
      }
    
      .cl-blue {
        color: $blue2ff;
      }
      .cl-dgray {
        color: $dark75;
      }
    
      .cl-gray {
        color: $gray974;
      }
    
      .cl-gray9d {
        color: $gray29d;
      }
      .col-white {
        color: $light;
      }
    
      .bg-dblue {
        background-color: $blue5ff !important;
        border-radius: 15px;
      }
    
      .bg-light {
        background-color: #0062ff8e;
        border-radius: 15px;
      }
    }
    
    
                    `;
  const [copy, setCopy] = useState(false);

  const [active, setActive] = useState(1);

  const setActiveId = () => {
    if (active === 1) {
      navigator.clipboard.writeText(codeString);
      setCopy(true);
    }

    if (active === 2) {
      navigator.clipboard.writeText(styleString);
      setCopy(true);
    }
  };

  useEffect(() => {
    setCopy(false);
  }, [active]);
  return (
    <div className="">
      <Row className="mx-0 gy-lg-0  gy-3 ">
        <Col lg={4} className="position-relative">
          <div className="custom-position-sticky">
            <NotificationCard />
          </div>
        </Col>

        <Col lg={8} className="d-flex flex-column  gap-4 ">
          <div>
            <div className="d-flex justify-content-between align-items-center  p-3  bg-secondary  br-top">
              <Nav variant="pills" className="d-flex  gap-2 ">
                <Nav.Item>
                  <Nav.Link
                    eventKey="first"
                    onClick={() => setActive(1)}
                    className={active === 1 ? "active" : ""}
                  >
                    JSX
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="second"
                    onClick={() => setActive(2)}
                    className={active === 2 ? "active" : ""}
                  >
                    CSS
                  </Nav.Link>
                </Nav.Item>
              </Nav>

              {copy ? (
                <div className="d-flex gap-2 align-items-center">
                  <CheckOutlined
                    style={{
                      fontSize: "20px",
                      color: "#ffffff",
                    }}
                  />
                  <div
                    className="text-light  cu-pointer"
                    onClick={() => {
                      navigator.clipboard.writeText("").then(() => {
                        setCopy(false);
                      });
                    }}
                  >
                    Copied
                  </div>
                </div>
              ) : (
                <div className="d-flex gap-2 align-items-center">
                  <CopyOutlined
                    style={{
                      fontSize: "20px",
                      color: "#ffffff",
                    }}
                  />

                  <div className="text-light  cu-pointer" onClick={setActiveId}>
                    Copy Code
                  </div>
                </div>
              )}
            </div>

            <div className={`${active === 1 ? "d-block" : "d-none"}`}>
              <SyntaxHighlighter
                language="javascript"
                style={atomOneDark}
                className="p-1   p-sm-2 br-bottom"
              >
                {codeString}
              </SyntaxHighlighter>
            </div>

            <div className={`${active === 2 ? "d-block" : "d-none"}`}>
              <SyntaxHighlighter
                language="javascript"
                style={atomOneDark}
                className="p-1   p-sm-2"
              >
                {styleString}
              </SyntaxHighlighter>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default NotificationCode;
