import { useEffect, useState, useMemo, useRef } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Badge from "react-bootstrap/Badge";
import { Avatar, Space } from "antd";
import img from "../../assets/images/PP.png";
import img1 from "../../assets/images/Bitmap.svg";
import img2 from "../../assets/images/attach2.svg";
import { Flex, Progress } from "antd";
import commentimg from "../../assets/images/Initial_PP_32px.png";
import JoditEditor from "jodit-react";

import "./DesignUiModal.scss";
import { Col, Row } from "react-bootstrap";

const initialdata = [
  {
    id: 1,
    projecttitle: "Design Dashboard and UI Kit",
    addedby: " john Son",
    addeddate: "Tue, 21 May 2019",
    teammembers: [img, img, commentimg],
    projectdesc: `Next Friday should be done. Next Monday we should deliver the first iteration. Make sure, we have a good result to be delivered by the day.

    I made some wireframes that we would like you to follow since we are building it in Google’s Material Design (Please learn more about this and see how to improve standard material design into something beautiful). But besides that, you can just do it how you like.`,
    attachments: [img1, img2],

    tasklist: [
      {
        projectid: 1,
        taskstatus: false,
        tasktitle: "Create Flow Chart",
        taskdate: "20 Apr, 2018",
        taskassigned: [
          {
            personimg: img,
            personname: "Adam Yogie",
          },
        ],
      },
      {
        id: 2,
        taskstatus: false,
        tasktitle: "Create Moodboard",
        taskdate: "21 Apr, 2018",
        taskassigned: [
          {
            personimg: img,
            personname: "Adam Yogie",
          },
          {
            personimg: img,
            personname: "Adam Yogie",
          },
        ],
      },

      {
        id: 3,
        taskstatus: false,
        tasktitle: "Wireframing",
        taskdate: "20 Apr, 2018",
        taskassigned: [
          {
            personimg: img,
            personname: "Adam Yogie",
          },
        ],
      },

      {
        id: 4,
        taskstatus: false,
        tasktitle: "Testing & Evaluation",
        taskdate: "25 Apr, 2018",
        taskassigned: [
          {
            personimg: img,
            personname: "Adam Yogie",
          },
          {
            personimg: img,
            personname: "Adam Yogie",
          },
          {
            personimg: img,
            personname: "Adam Yogie",
          },
        ],
      },

      {
        id: 5,
        taskstatus: false,
        tasktitle: "Create look & feel",
        taskdate: "25 Apr, 2018",
        taskassigned: [
          {
            personimg: img,
            personname: "Adam Yogie",
          },
          {
            personimg: img,
            personname: "Adam Yogie",
          },
          {
            personimg: img,
            personname: "Adam Yogie",
          },
        ],
      },
    ],

    comments: [
      {
        commentmainname: "Joel Hopkins, Project Manager",
        commentmainnamedesig: "Project Manager",

        commentbyobj: [
          {
            projectid: 1, // Replace 'projectid' with the actual project ID
            commentid: 1,
            commentdate: "Dec 14, 2018",
            commentby: "Yogie Ismanda ",
            commentbyprofile: img,
            commentbydesig: "  UI Kit + Minor revisi ini ya pak.. ",
            commentdesc: `- We would like to have Pageviews, click, conversion and total revenue up in the right corner of the graph. And maybe design them so the look more like buttons that you can turn on/off?
          - Latest clicks/conversions. Where you currently have the logo for merchant, we should instead have a logo that represent the referring traffic sources (ex. Google or Facebook). So we’re actually missing a column that should say “Source”. And there should be no icon for the merchants.`,
          },
        ],
      },
    ],
  },
];

const DesignUiModal = ({ placeholder }) => {
  const editor = useRef(null);
  const [lgShow, setLgShow] = useState(false);
  const [show, setShow] = useState(false);

  const [fullscreen, setFullscreen] = useState(true);
  const [showMore, setShowMore] = useState(false);
  const [percent, setPercent] = useState(0);
  // const [tasks, setTasks] = useState(initialdata[0]);
  const [data, setData] = useState(initialdata);
  const [newCommentInput, setNewCommentInput] = useState("");
  const [formData, setFormData] = useState([]);

  const [content, setContent] = useState("");

  const config = useMemo(
    () => ({
      readonly: false,
      placeholder: placeholder || "Start typing...",
    }),
    [placeholder]
  );

  const toggleTaskStatus = (projectId, taskId) => {
    setData((prevData) => {
      const projectIndex = prevData.findIndex((p) => p.id === projectId);
      if (projectIndex === -1) return prevData;

      const taskIndex = prevData[projectIndex].tasklist.findIndex(
        (t) => t.id === taskId
      );
      if (taskIndex === -1) return prevData;

      const updatedProject = {
        ...prevData[projectIndex],
        tasklist: prevData[projectIndex].tasklist.map((task, index) =>
          index === taskIndex ? { ...task, taskstatus: !task.taskstatus } : task
        ),
      };

      const updatedData = [
        ...prevData.slice(0, projectIndex),
        updatedProject,
        ...prevData.slice(projectIndex + 1),
      ];
      setInitialDataInLocalStorage();

      percentStatus();

      return updatedData;
    });
  };

  const setInitialDataInLocalStorage = () => {
    localStorage.setItem("data", JSON.stringify(data));
  };

  const percentStatus = () => {
    if (!data || !data[0] || !data[0].tasklist) {
      console.error("Data structure is invalid or missing");
      return;
    }

    const completedTasks = data[0].tasklist.filter(
      (task) => task.taskstatus === true
    ).length;

    console.log(completedTasks, "Completed Tasks");

    const totalTasks = data[0].tasklist.length;
    console.log(totalTasks, "Total Tasks");

    if (totalTasks === 0) {
      console.error("No tasks found");
      return;
    }

    const newPercent = (completedTasks / totalTasks) * 100;
    console.log(newPercent, "New Percent");

    setPercent(newPercent);
  };

  const handleInputChange = (e) => {
    setNewCommentInput(e.target.value);
  };

  const addNewComment = (projectId) => {
    console.log("new Comment Function called");
    const newComment = {
      commentmainname: "New Commenter", // Name of the commenter (change as needed)
      commentmainnamedesig: "Role of New Commenter", // Role/designation of the commenter (change as needed)
      commentbyobj: [
        {
          projectid: 1, // Assuming this is the correct project ID
          commentid: Date.now(), // Generate a unique comment ID (using timestamp)
          commentdate: new Date().toDateString(), // Set current date as comment date
          commentby: "John Doe", // Name of the commenter (change as needed)
          commentbyprofile: img, // Profile image (change as needed)
          commentbydesig: "Some description about the comment", // Description or role of the commenter (change as needed)
          commentdesc: newCommentInput, // Comment content extracted from input field
        },
      ],
    };

    setData((prevData) => {
      const projectIndex = prevData.findIndex((p) => p.id === projectId);
      if (projectIndex === -1) return prevData;

      // Create a new array with existing comments and the new comment
      const updatedComments = [...prevData[projectIndex].comments, newComment];

      // Create an updated project object with the new comments array
      const updatedProject = {
        ...prevData[projectIndex],
        comments: updatedComments,
      };

      // Create an updated data array with the updated project object
      const updatedData = [
        ...prevData.slice(0, projectIndex),
        updatedProject,
        ...prevData.slice(projectIndex + 1),
      ];

      setInitialDataInLocalStorage();
      percentStatus();
      setNewCommentInput("");
      return updatedData;
    });
  };

  const getInitialDataFromLocalStorage = () => {
    const formDataFromStorage = localStorage.getItem("data");
    console.log("my new Data,", formDataFromStorage?.tasklist);
    if (formDataFromStorage) {
      const parsedFormData = JSON.parse(formDataFromStorage);
      setData(parsedFormData);
      // percentStatus();
    }
  };

  const updatedescription = (projectId) => {
    setData((prevData) => {
      const projectIndex = prevData.findIndex((p) => p.id === projectId);
      if (projectIndex === -1) return prevData;

      const updatedProject = {
        ...prevData[projectIndex],
        projectdesc: content, // Update projectdesc with the content state
      };

      const newData = [
        ...prevData.slice(0, projectIndex),
        updatedProject,
        ...prevData.slice(projectIndex + 1),
      ];

      setInitialDataInLocalStorage();

      percentStatus();
      setShow(false);

      return newData;
    });
  };

  useEffect(() => {
    getInitialDataFromLocalStorage();
  }, []);

  useEffect(() => {
    percentStatus();
  }, [data]);

  console.log("Content,", content);
  return (
    <div className="design-ui-wrapper">
      <div className="">
        <h1 className="py-3 text-light">
          {" "}
          Click here to see Dashboard Ui Kit Model
        </h1>
        <Button onClick={() => setLgShow(true)}>
          Design Dashboard and UI Model
        </Button>
      </div>

      <Modal
        show={lgShow}
        fullscreen={fullscreen}
        onHide={() => setLgShow(false)}
        className="design-ui-model"
      >
        <Modal.Header className="justify-content-between  model-header">
          <div onClick={() => setLgShow(false)}>
            {" "}
            <Badge className="custom-mark" bg="transparent">
              <div className="d-flex  align-items-center gap-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M4 9.48513L8.57372 14L16 5"
                    stroke="#696974"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <div className="badge-title">Mark Complete</div>
              </div>
            </Badge>
          </div>

          <div className="d-flex gap-3 align-items-center">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7 12C7 10.8954 6.10457 10 5 10C3.89543 10 3 10.8954 3 12C3 13.1046 3.89543 14 5 14C6.10457 14 7 13.1046 7 12Z"
                  fill="#92929D"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14C13.1046 14 14 13.1046 14 12Z"
                  fill="#92929D"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M21 12C21 10.8954 20.1046 10 19 10C17.8954 10 17 10.8954 17 12C17 13.1046 17.8954 14 19 14C20.1046 14 21 13.1046 21 12Z"
                  fill="#92929D"
                />
              </svg>
            </div>

            <div className="cu-pointer" onClick={() => setLgShow(false)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M13.4115 12.0003L18.6886 17.2822C19.079 17.6729 19.0787 18.306 18.688 18.6964C18.2973 19.0867 17.6641 19.0864 17.2738 18.6957L11.9963 13.4135L6.70658 18.6953C6.31577 19.0856 5.6826 19.0851 5.29237 18.6943C4.90213 18.3035 4.9026 17.6703 5.29342 17.2801L10.5827 11.9986L5.29565 6.70679C4.9053 6.31609 4.90559 5.68292 5.29629 5.29257C5.68699 4.90223 6.32016 4.90251 6.7105 5.29321L11.998 10.5855L17.2739 5.31734C17.6648 4.92711 18.2979 4.92758 18.6881 5.31839C19.0784 5.70921 19.0779 6.34237 18.6871 6.73261L13.4115 12.0003Z"
                  fill="#92929D"
                />
              </svg>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className="custom-modal-body  d-flex flex-column gap-4">
          <Row className="mx-0 pb-4  model-header-data-row">
            <Col md={5}>
              <div className="modal-header-data d-flex flex-column gap-5">
                <div className=" d-flex flex-column  gap-2">
                  <div className="project-title">
                    Design Dashboard and UI Kits
                    {data?.projecttilte}
                  </div>
                  <div className="added-by-desc">
                    Added by
                    <span className="added-by mx-1">{data?.addedby},</span> 22
                    hours ago
                  </div>
                </div>

                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex gap-2">
                    {data[0].teammembers?.map((team, teamindex) => (
                      <Avatar src={team} size={32} key={teamindex}></Avatar>
                    ))}
                  </div>

                  <div className="modal-date-tag">{data[0].addeddate}</div>
                </div>
              </div>
            </Col>
          </Row>

          <div className="description-area  d-flex flex-column gap-5">
            <div className="d-flex align-items-start gap-3 ">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="17"
                  viewBox="0 0 18 17"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M1 2H17C17.5523 2 18 1.55228 18 1C18 0.447715 17.5523 0 17 0H1C0.447715 0 0 0.447715 0 1C0 1.55228 0.447715 2 1 2Z"
                    fill="#92929D"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M1 7H17C17.5523 7 18 6.55228 18 6C18 5.44772 17.5523 5 17 5H1C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7Z"
                    fill="#92929D"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M1 12H17C17.5523 12 18 11.5523 18 11C18 10.4477 17.5523 10 17 10H1C0.447715 10 0 10.4477 0 11C0 11.5523 0.447715 12 1 12Z"
                    fill="#92929D"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M1 17H11C11.5523 17 12 16.5523 12 16C12 15.4477 11.5523 15 11 15H1C0.447715 15 0 15.4477 0 16C0 16.5523 0.447715 17 1 17Z"
                    fill="#92929D"
                  />
                </svg>
              </div>

              <div className="d-flex flex-column gap-5 w-100">
                <div className="d-flex justify-content-between ">
                  <div className="section-title-tag">Description</div>
                  <Button
                    // variant="primary"
                    className="edit-desc-btn"
                    onClick={() => setShow(true)}
                  >
                    Edit Description
                  </Button>

                  <Modal
                    show={show}
                    onHide={() => setShow(false)}
                    centered
                    size="lg"
                    backdrop="static"
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Modal heading</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <JoditEditor
                        ref={editor}
                        config={config}
                        value={content}
                        tabIndex={1} // tabIndex of textarea
                        // onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                        onChange={(newContent) => setContent(newContent)}
                      />
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="primary"
                        onClick={() => updatedescription(1)}
                      >
                        Save Changes
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </div>

                <div
                  className="pb-3"
                  dangerouslySetInnerHTML={{
                    __html:
                      showMore && data[0]?.projectdesc
                        ? data[0].projectdesc
                        : data[0]?.projectdesc.substring(0, 250),
                  }}
                ></div>

                <div
                  className="show-full  cu-pointer"
                  onClick={() => setShowMore(!showMore)}
                >
                  {showMore
                    ? "Show Less Description "
                    : "Show Full Description"}
                </div>
              </div>
            </div>

            <div className="d-flex "></div>
          </div>

          <div className="description-area  d-flex flex-column gap-5">
            <div className="d-flex align-items-start gap-3 ">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="18"
                  viewBox="0 0 16 18"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M1.71391 7.79446L8.23917 1.2692C9.93144 -0.423068 12.6752 -0.423068 14.3674 1.2692C16.0597 2.96148 16.0597 5.70519 14.3674 7.39746L7.84217 13.9227C6.79726 14.9205 5.14126 14.9062 4.11438 13.8793C3.07298 12.8379 3.07298 11.1494 4.11438 10.108L10.5962 3.62623C10.9867 3.2357 11.6199 3.2357 12.0104 3.62623C12.4009 4.01675 12.4009 4.64992 12.0104 5.04044L5.5286 11.5223C5.26825 11.7826 5.26825 12.2047 5.5286 12.4651C5.78895 12.7254 6.21105 12.7254 6.4714 12.4651L12.9532 5.98325C13.8644 5.07203 13.8644 3.59464 12.9532 2.68342C12.042 1.77219 10.5646 1.77219 9.65339 2.68342L3.17157 9.16523C1.60948 10.7273 1.60948 13.26 3.17157 14.8221C4.73367 16.3842 7.26633 16.3842 8.82843 14.8221L14.1317 9.51878C14.5223 9.12826 15.1554 9.12826 15.5459 9.51878C15.9365 9.90931 15.9365 10.5425 15.5459 10.933L10.2426 16.2363C7.89949 18.5794 4.1005 18.5794 1.75736 16.2363C-0.571287 13.9077 -0.585697 10.1411 1.71413 7.79468L1.71391 7.79446Z"
                    fill="#92929D"
                  />
                </svg>
              </div>

              <div className="d-flex flex-column gap-5">
                <div className="section-title-tag">Attachment</div>

                <Row className="mx-0 gy-2">
                  {data[0].attachments?.map((attach, index) => (
                    <Col sm={6} className="d-flex flex-column gap-2">
                      <div className="img-otr" key={index}>
                        <img src={attach} className="img-inr"></img>
                      </div>

                      <div className="img-path">
                        Dashboard references - 01.png
                      </div>
                    </Col>
                  ))}
                </Row>
              </div>
            </div>
          </div>

          <div className="description-area  d-flex flex-column gap-5">
            <div className="d-flex align-items-start gap-3 ">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M10.2574 11.4741L18.2147 1.38089C18.5566 0.947176 19.1854 0.872772 19.6191 1.2147C20.0528 1.55663 20.1272 2.1854 19.7853 2.61911L11.1131 13.6191C10.736 14.0975 10.0225 14.1304 9.60293 13.6888L5.27509 9.13437C4.89465 8.73402 4.9108 8.10106 5.31116 7.72062C5.71152 7.34019 6.34448 7.35633 6.72491 7.75669L10.2574 11.4741ZM18 9.1C18 8.54772 18.4477 8.1 19 8.1C19.5523 8.1 20 8.54772 20 9.1V16.3C20 18.3435 18.3435 20 16.3 20H3.7C1.65655 20 0 18.3435 0 16.3V3.7C0 1.65655 1.65655 0 3.7 0H14.4C14.9523 0 15.4 0.447715 15.4 1C15.4 1.55228 14.9523 2 14.4 2H3.7C2.76112 2 2 2.76112 2 3.7V16.3C2 17.2389 2.76112 18 3.7 18H16.3C17.2389 18 18 17.2389 18 16.3V9.1Z"
                    fill={percent === 100 ? "#3DD598" : "#92929D"}
                  />
                </svg>
              </div>

              <div className="section-title-tag">Task List</div>
            </div>

            <div className="d-flex flex-column gap-4 w-100">
              <div className="d-flex  flex-column gap-3 ">
                <Progress percent={percent} type="line" />

                <div className="d-flex  flex-column gap-3">
                  {data[0].tasklist?.map((item, index) => (
                    <div
                      className="d-flex flex-wrap align-items-center gap-3 task-item"
                      key={index}
                    >
                      <div>
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value={item.taskstatus}
                          checked={item.taskstatus}
                          id="flexCheckChecked"
                          className="custom-check"
                          onChange={() => toggleTaskStatus(1, item.id)}
                        />
                      </div>

                      <div className="d-flex flex-wrap align-items-center gap-2">
                        <div className="task-title">{item.tasktitle} </div>
                        <div className="d-flex gap-2 align-items-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17"
                            height="16"
                            viewBox="0 0 17 16"
                            fill="none"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M1.3457 7.33325H14.8124V11.3333C14.8124 13.1742 13.3051 14.6666 11.4457 14.6666H4.71237C2.85301 14.6666 1.3457 13.1742 1.3457 11.3333V7.33325Z"
                              stroke="#92929D"
                              stroke-width="1.5"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M2.69237 3.33325H13.4657C14.2094 3.33325 14.8124 3.93021 14.8124 4.66659V7.33325H1.3457V4.66659C1.3457 3.93021 1.94863 3.33325 2.69237 3.33325Z"
                              stroke="#92929D"
                              stroke-width="1.5"
                            />
                            <path
                              d="M12.1186 2V4"
                              stroke="#92929D"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M4.7124 2V4"
                              stroke="#92929D"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>

                          <div className="task-date">{item.taskdate}</div>
                        </div>

                        <div className="d-flex gap-2">
                          {item.taskassigned.map((e, g) => (
                            <div
                              className="d-flex align-items-center gap-2"
                              key={g}
                            >
                              <Avatar src={e.personimg}></Avatar>
                              <div className="profile-name">{e.personname}</div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="description-area  d-flex flex-column gap-3">
            <div className="d-flex align-items-center gap-3 ">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M20.7831 21.9762C21.4976 22.135 22.135 21.4976 21.9762 20.7831L20.8786 15.8439C21.6123 14.3503 22 12.7022 22 11C22 4.92487 17.0751 0 11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22C12.7022 22 14.3503 21.6123 15.8439 20.8786L20.7831 21.9762ZM18.9424 15.2373C18.8296 15.4481 18.7961 15.6924 18.848 15.9258L19.6829 19.6829L15.9258 18.848C15.6924 18.7961 15.4481 18.8296 15.2373 18.9424C13.9449 19.6335 12.5 20 11 20C6.02944 20 2 15.9706 2 11C2 6.02944 6.02944 2 11 2C15.9706 2 20 6.02944 20 11C20 12.5 19.6335 13.9449 18.9424 15.2373Z"
                    fill="#92929D"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8 13C9.10457 13 10 12.1046 10 11C10 9.89543 9.10457 9 8 9C6.89543 9 6 9.89543 6 11C6 12.1046 6.89543 13 8 13Z"
                    fill="#92929D"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M14 13C15.1046 13 16 12.1046 16 11C16 9.89543 15.1046 9 14 9C12.8954 9 12 9.89543 12 11C12 12.1046 12.8954 13 14 13Z"
                    fill="#92929D"
                  />
                </svg>
              </div>

              <div className="section-title-tag">
                Comments <span>(1)</span>
              </div>
            </div>
            {data[0].comments.map((v, i) => (
              <div className="d-flex gap-3 comment-desc-wrapper">
                <div className="comment-icon">
                  <Avatar
                    src={commentimg}
                    size={45}
                    className="comment-icon-img"
                  ></Avatar>
                </div>
                <div className="d-flex flex-column gap-3 w-100">
                  <>
                    <div className="comment-card  d-flex flex-column gap-3 w-100">
                      <div className="d-flex justify-content-between  align-items-center px-4">
                        <div className="d-flex justify-content-between  gap-2  align-items-center w-100">
                          <div className="comment-name">
                            {v.commentmainname}
                            <span className="comment-designation">
                              {" "}
                              {v.commentmainnamedesig}
                            </span>
                          </div>

                          <div className="d-flex justify-content-between gap-2 align-items-center">
                            <div className="comment-date">
                              {v.commentbyobj?.commentdate}
                            </div>
                            <div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M7 12C7 10.8954 6.10457 10 5 10C3.89543 10 3 10.8954 3 12C3 13.1046 3.89543 14 5 14C6.10457 14 7 13.1046 7 12Z"
                                  fill="#92929D"
                                />
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14C13.1046 14 14 13.1046 14 12Z"
                                  fill="#92929D"
                                />
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M21 12C21 10.8954 20.1046 10 19 10C17.8954 10 17 10.8954 17 12C17 13.1046 17.8954 14 19 14C20.1046 14 21 13.1046 21 12Z"
                                  fill="#92929D"
                                />
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="d-flex gap-2">
                        <Avatar src={img} size={18}></Avatar>
                        <div className="new-comment-name">
                          {v.commentbyobj[0].commentby}
                          <span className="new-comment-desig">
                            {v.commentbyobj[0].commentbydesig}
                          </span>
                        </div>
                      </div>

                      <div className="new-cmment-desc">
                        {v.commentbyobj[0].commentdesc}
                      </div>
                    </div>
                  </>
                </div>
              </div>
            ))}

            <div className="d-flex  gap-3">
              <div className="new-comment-icon">
                <Avatar
                  src={commentimg}
                  size={45}
                  className="add-comment-icon-img"
                ></Avatar>
              </div>

              <div className="new-comment-wrapper w-100">
                <div className="d-flex justify-content-between align-items-center  new-comment-input-wrapper">
                  <input
                    placeholder="Add Comment and Upload File here "
                    className="new-comment-input w-100"
                    value={newCommentInput}
                    onChange={handleInputChange}
                  />

                  <div className="cu-pointer" onClick={() => addNewComment(1)}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="#92929D"
                      viewBox="0 0 256 256"
                    >
                      <path d="M224.49,136.49l-72,72a12,12,0,0,1-17-17L187,140H40a12,12,0,0,1,0-24H187L135.51,64.48a12,12,0,0,1,17-17l72,72A12,12,0,0,1,224.49,136.49Z"></path>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default DesignUiModal;
