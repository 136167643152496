import React from "react";
import "./LogoDesignPicCard.scss";
import img1 from "../../assets/images/logoimg1.png";
import img2 from "../../assets/images/logoimg2.png";

import { Avatar, Space } from "antd";

import { useState } from "react";
import { Col, Row } from "react-bootstrap";

const LogoDesignPicCard = () => {
  return (
    <div className="logo-pic-card-wrapper">
      <div className=" card-header   d-flex justify-content-between  align-items-center">
        <div className="title-class">Square - New Logo design</div>
        <div className="time-class">1:54pm</div>
      </div>

      <div className="d-flex flex-column gap-4">
        <div className="d-flex  align-items-center  gap-2 pic-desc-area">
          <Avatar style={{ backgroundColor: "#A461D8" }}>RR</Avatar>

          <div className="d-flex flex-column gap-1">
            <div className="status-title">Sam Tin uploaded a new photo </div>

            <div className="pic-headers">Header Illustration #Exploration</div>
          </div>
        </div>

        <Row className="mx-0">
          <Col md={6}>
            <div className="img-otr">
              <img src={img1} className="img-inr"></img>
            </div>
          </Col>
          <Col md={6}>
            <div className="img-otr">
              <img src={img2} className="img-inr"></img>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default LogoDesignPicCard;
