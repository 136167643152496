import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import SalesCard from "../../Components/SalesCard/SalesCard";
import SyntaxHighlighter from "react-syntax-highlighter";
import { atomOneDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import {
  StarOutlined,
  StarFilled,
  StarTwoTone,
  CopyOutlined,
  CheckOutlined,
} from "@ant-design/icons";

const SalesStash = () => {
  const codeString = `
import React from "react";
import "./SalesCard.scss";

const SalesCard = () => {
  return (
    <div className="Sales-Card-wrapper d-flex flex-column align-items-start">
      <div className="card-title">Sales</div>
      <div className="sales-ratio d-flex align-items-center">
        <div className="price-title">$27632</div>

        <div className="d-flex align-items-center gap-2">
          <div className="percentge-title">+2.5%</div>

          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M8.66602 13.3333V2.66667C8.66602 2.29848 8.36755 2 7.99936 2C7.63117 2 7.33269 2.29848 7.33269 2.66667V13.3333C7.33269 13.7015 7.63117 14 7.99936 14C8.36755 14 8.66602 13.7015 8.66602 13.3333Z"
                fill="#92929D"
              />
              <path
                d="M7.99343 3.67906L12.1646 8.43936C12.4073 8.71628 12.8285 8.74406 13.1054 8.50141C13.3823 8.25876 13.4101 7.83756 13.1674 7.56064L8.49412 2.22731C8.2283 1.92395 7.75608 1.92428 7.49068 2.22802L2.83066 7.56135C2.5884 7.83861 2.61678 8.25977 2.89404 8.50203C3.17131 8.74429 3.59246 8.71591 3.83472 8.43865L7.99343 3.67906Z"
                fill="#92929D"
              />
            </svg>
          </div>
        </div>
      </div>
      <div className="description">Compared to ($21340 last year)</div>
    </div>
  );
};

export default SalesCard;
    `;

  const styleString = `
  @import "../../CommonStyle/Variables.scss";
.Sales-Card-wrapper {
  background-color: $light;
  border-radius: 15px;
  max-width: 300px;
  padding: 20px 25px;

  .card-title {
    font-size: $font-size-16px;
    line-height: $line-height-24px;
    letter-spacing: 0.1px;
    padding-bottom: 17px;
    font-weight: $font-weight-medium;
  }

  .sales-ratio {
    gap: 12px;
    padding-bottom: 10px;
    .price-title {
      font-size: $font-size-28px;
      line-height: $line-height-42px;
      color: $dark75;
      font-weight: $font-weight-bold;
    }

    .percentge-title {
      font-size: $font-size-16px;
      line-height: $line-height-24px;
      color: $green98;
    }
  }

  .description {
    font-size: $font-size-14px;
    line-height: $line-height-16px;
    color: $gray74;
  }
}

    `;

  const [copy, setCopy] = useState(false);
  const [styleCopy, setStyleCopy] = useState(false);
  const [active, setActive] = useState(1);

  const setActiveId = () => {
    if (active === 1) {
      navigator.clipboard.writeText(codeString);
      setCopy(true);
    }

    if (active === 2) {
      navigator.clipboard.writeText(styleString);
      setCopy(true);
    }
  };

  useEffect(() => {
    setCopy(false);
  }, [active]);

  return (
    <div className="">
      <Row className="mx-0 gy-lg-0  gy-3 ">
        <Col lg={3} className="position-relative">
          <div className="custom-position-sticky">
            <SalesCard />
          </div>
        </Col>

        <Col lg={9} className="d-flex flex-column  gap-4 ">
          <div>
            <div className="d-flex justify-content-between align-items-center  p-3  bg-secondary  br-top">
              <Nav variant="pills" className="d-flex  gap-2 ">
                <Nav.Item>
                  <Nav.Link
                    eventKey="first"
                    onClick={() => setActive(1)}
                    className={active === 1 ? "active" : ""}
                  >
                    JSX
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="second"
                    onClick={() => setActive(2)}
                    className={active === 2 ? "active" : ""}
                  >
                    CSS
                  </Nav.Link>
                </Nav.Item>
              </Nav>

              {copy ? (
                <div className="d-flex gap-2 align-items-center">
                  <CheckOutlined
                    style={{
                      fontSize: "20px",
                      color: "#ffffff",
                    }}
                  />
                  <div
                    className="text-light  cu-pointer"
                    onClick={() => {
                      navigator.clipboard.writeText("").then(() => {
                        setCopy(false);
                      });
                    }}
                  >
                    Copied
                  </div>
                </div>
              ) : (
                <div className="d-flex gap-2 align-items-center">
                  <CopyOutlined
                    style={{
                      fontSize: "20px",
                      color: "#ffffff",
                    }}
                  />

                  <div className="text-light  cu-pointer" onClick={setActiveId}>
                    Copy Code
                  </div>
                </div>
              )}
            </div>

            <div className={`${active === 1 ? "d-block" : "d-none"}`}>
              <SyntaxHighlighter
                language="javascript"
                style={atomOneDark}
                className="p-1   p-sm-2 br-bottom"
              >
                {codeString}
              </SyntaxHighlighter>
            </div>

            <div className={`${active === 2 ? "d-block" : "d-none"}`}>
              <SyntaxHighlighter
                language="javascript"
                style={atomOneDark}
                className="p-1   p-sm-2"
              >
                {styleString}
              </SyntaxHighlighter>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default SalesStash;
