import React, { useState } from "react";
import "./LikePage.scss";
import { Avatar, Space } from "antd";
import logo from "../../assets/images/Logo.png";
import pic from "../../assets/images/piccard.png";

const initialData = [
  {
    id: 1,
    pagetitle: "Sebo Studio",
    pagesdesc: "Design Studio",
    pagessrc: pic,
    liked: false,
    likesCount: 0,
  },

  {
    id: 2,
    pagetitle: "Sebo Studio",
    pagesdesc: "Design Studio",
    pagessrc: pic,
    liked: false,
    likesCount: 0,
  },

  {
    id: 3,
    pagetitle: "Sebo Studio",
    pagesdesc: "Design Studio",
    pagessrc: pic,
    liked: false,
    likesCount: 0,
  },

  {
    id: 4,
    pagetitle: "Sebo Studio",
    pagesdesc: "Design Studio",
    pagessrc: pic,
    liked: false,
    likesCount: 0,
  },
];

const LikePage = () => {
  const [showAllCustomers, setShowAllCustomers] = useState(false);
  const [data, setData] = useState(initialData);

  const toggleShowAllCustomers = () => {
    setShowAllCustomers(!showAllCustomers);
  };

  const handleLikeToggle = (id) => {
    const updatedData = data.map((item) => {
      if (item.id === id) {
        const newLiked = !item.liked;
        const newLikesCount = newLiked
          ? item.likesCount + 1
          : item.likesCount - 1;
        return { ...item, liked: newLiked, likesCount: newLikesCount };
      }
      return item;
    });
    setData(updatedData);
  };

  return (
    <div className="like-card-wrapper">
      <div className="d-flex justify-content-between  card-header">
        <div className="title-class">Photos and Videos</div>
        <div
          className="title-badge cu-pointer"
          onClick={toggleShowAllCustomers}
        >
          {showAllCustomers ? "See Less" : "See All"}
        </div>
      </div>

      <div className="pages-container d-flex flex-column gap-2">
        {data
          .slice(0, showAllCustomers ? data.length : 1)
          .map((item, index) => (
            <div className="page-item d-flex  flex-column  gap-2" key={index}>
              <div className="d-flex align-items-center gap-2">
                <div>
                  <Avatar shape="square" src={logo} size={32} />
                </div>

                <div className="d-flex flex-column gap-2">
                  <div className="page-title">{item.pagetitle}</div>
                  <div className="page-desc">{item.pagesdesc}</div>
                </div>
              </div>

              <div className="pic-card d-flex flex-column">
                <div className="card-img-otr">
                  <img src={item.pagessrc} className="card-img"></img>
                </div>

                <div
                  className="like-area d-flex align-items-center  gap-2 justify-content-center"
                  onClick={() => handleLikeToggle(item.id)}
                >
                  {item.liked ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      fill="#2d17d9"
                      viewBox="0 0 256 256"
                    >
                      <path d="M234,80.12A24,24,0,0,0,216,72H160V56a40,40,0,0,0-40-40,8,8,0,0,0-7.16,4.42L75.06,96H32a16,16,0,0,0-16,16v88a16,16,0,0,0,16,16H204a24,24,0,0,0,23.82-21l12-96A24,24,0,0,0,234,80.12ZM32,112H72v88H32Z"></path>
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      viewBox="0 0 24 25"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M6.70174 23.0512C7.60968 23.6383 8.68376 23.9495 9.90316 23.9781C13.2939 24.0071 15.7937 24.0071 17.4212 23.9782C20.1041 23.9306 21.3011 23.0867 21.9534 20.9622C22.5573 18.995 23.1543 13.7284 22.9594 11.0831C22.8379 9.43493 22.0768 8.50327 20.6847 8.41714C20.2042 8.38741 19.6773 8.4551 18.9523 8.61301C18.6924 8.66962 17.9094 8.86016 17.4005 8.98403C17.1857 9.0363 17.0197 9.0767 16.9623 9.09015C16.5195 9.19406 16.1204 9.28044 15.7232 9.35652C15.0576 9.484 14.5884 9.51193 14.3011 9.46194C14.1637 9.43804 14.144 9.42569 14.1323 9.40635C14.0202 9.22085 14.0116 8.80533 14.1153 7.3576C14.1658 6.65273 14.1896 6.18993 14.1939 5.66706C14.2179 2.78314 12.6585 1.42248 10.0635 2.23261C9.69248 2.34843 9.42219 2.66872 9.3704 3.05391C9.02051 5.65593 8.12238 7.93309 6.67089 9.90034C6.31319 9.35786 5.6984 8.99988 5 8.99988H3C1.89543 8.99988 1 9.89531 1 10.9999V21.9999C1 23.1045 1.89543 23.9999 3 23.9999H5C5.71891 23.9999 6.34923 23.6206 6.70174 23.0512ZM12.1204 7.21468C12.1681 6.54857 12.1901 6.12147 12.194 5.65045C12.2048 4.34485 11.9884 3.91256 11.2483 4.00317C10.6766 7.34169 9.25786 10.2217 7 12.6199V20.6794C7.72835 21.5232 8.68772 21.949 9.93513 21.9784C13.2948 22.007 15.7796 22.007 17.3858 21.9785C19.2274 21.9459 19.6509 21.6473 20.0415 20.3752C20.5727 18.645 21.1414 13.6273 20.9648 11.2301C20.9112 10.5021 20.8534 10.4314 20.5612 10.4133C20.3087 10.3977 19.9367 10.4455 19.378 10.5672C19.1472 10.6175 18.4202 10.7943 17.9128 10.9178C17.6766 10.9752 17.488 11.0211 17.4192 11.0373C16.9514 11.147 16.5261 11.2391 16.0994 11.3208C14.2243 11.68 13.0457 11.4749 12.4207 10.4409C11.9842 9.71874 11.9745 9.2515 12.1204 7.21468ZM5 21.0192C4.99992 21.0266 4.99992 21.034 5 21.0414V21.9999H3V10.9999H5V12.2162C5 12.2161 5 12.2163 5 12.2162V21.0192Z"
                        fill="#92929D"
                      />
                    </svg>
                  )}

                  <div className={`like-text  ${item.liked ? "cl-blue" : ""}`}>
                    {item.liked ? "Dislike Page" : "Like Page"}
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default LikePage;
