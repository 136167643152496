import React from "react";
import "./PendingCard.scss";
import Badge from "react-bootstrap/Badge";
import { Flex, Progress } from "antd";
import { Avatar, Divider, Tooltip } from "antd";
import { AntDesignOutlined, UserOutlined } from "@ant-design/icons";
import img from "../../assets/images/PP.png";
import ProgressBar from "react-bootstrap/ProgressBar";
import { Col, Row } from "react-bootstrap";

const PendingCard = () => {
  return (
    <div className="pending-wrapper  d-flex flex-column gap-2">
      <div className=" card-header  d-flex  justify-content-between align-items-center">
        <div className="pending-title">Pending</div>
        <div className="cu-pointer  add-icon " type="button">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M7 12C7 10.8954 6.10457 10 5 10C3.89543 10 3 10.8954 3 12C3 13.1046 3.89543 14 5 14C6.10457 14 7 13.1046 7 12Z"
              fill="#92929D"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14C13.1046 14 14 13.1046 14 12Z"
              fill="#92929D"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M21 12C21 10.8954 20.1046 10 19 10C17.8954 10 17 10.8954 17 12C17 13.1046 17.8954 14 19 14C20.1046 14 21 13.1046 21 12Z"
              fill="#92929D"
            />
          </svg>
        </div>
      </div>

      <div className="d-flex flex-column gap-2">
        <div className="d-flex flex-column  flex-wrap flex-md-row justify-content-between gy-4 align-items-md-center pending-card-otr">
          <div className="heading-section  d-flex flex-column gap-2">
            <div className="task-title">Finance App Exploration</div>
            <div className="task-desc">Iconspace Team</div>
          </div>

          <div className="progress-section d-flex   flex-column  flex-wrap flex-md-row align-items-start align-items-md-center  gx-4  gap-4">
            <div className="d-flex  flex-wrap  align-items-center gap-2">
              <div className="d-flex   align-items-center gap-1">
                <div className="d-flex  align-items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M5.95567 11.0438C6.28085 11.369 6.80792 11.3693 7.13342 11.0446L7.13364 11.0444L7.13418 11.0438L10.9054 7.27261L12.3196 5.8584C13.1658 5.01226 13.1658 3.64041 12.3196 2.79427C11.4735 1.94813 10.1016 1.94813 9.2555 2.79427L4.07005 7.97972C4.06979 7.97998 4.06953 7.98024 4.06927 7.98049C2.70321 9.34739 2.70347 11.5629 4.07005 12.9295C5.43688 14.2963 7.65296 14.2963 9.0198 12.9295L13.2624 8.68683C13.4577 8.49156 13.7743 8.49156 13.9695 8.68683C14.1648 8.88209 14.1648 9.19867 13.9695 9.39393L9.7269 13.6366C7.96954 15.3939 5.1203 15.3939 3.36294 13.6366C1.61718 11.8908 1.60566 9.06754 3.32837 7.30756L3.32818 7.30737L3.36294 7.27261L8.54839 2.08716C9.78505 0.850503 11.7901 0.850503 13.0267 2.08716C14.2634 3.32382 14.2634 5.32885 13.0267 6.56551L11.6125 7.97972L7.84128 11.751L7.80653 11.7857L7.80607 11.7853C7.08775 12.4667 5.9529 12.4553 5.24856 11.751C4.5326 11.035 4.5326 9.87419 5.24856 9.15823L10.434 3.97278C10.6293 3.77752 10.9459 3.77752 11.1411 3.97278C11.3364 4.16804 11.3364 4.48463 11.1411 4.67989L5.95567 9.86534C5.63023 10.1908 5.63023 10.7184 5.95567 11.0438Z"
                      fill="#92929D"
                    />
                  </svg>
                </div>

                <div className="fs-14">13</div>
              </div>

              <div className="d-flex align-items-center gap-1">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_284_1017)">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M7.32118 9.26017L5.67743 7.63757C5.48091 7.44358 5.16433 7.44563 4.97034 7.64215C4.77634 7.83867 4.77839 8.15525 4.97492 8.34924L7.00768 10.3558C7.21669 10.5622 7.55768 10.5448 7.7446 10.3182L11.0452 6.31824C11.2209 6.10524 11.1907 5.7901 10.9777 5.61435C10.7647 5.4386 10.4496 5.4688 10.2738 5.68179L7.32118 9.26017Z"
                        fill="#92929D"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M6.33398 0.833496H9.66732C12.7049 0.833496 15.1673 3.29593 15.1673 6.3335V9.66683C15.1673 12.7044 12.7049 15.1668 9.66732 15.1668H6.33398C3.29642 15.1668 0.833984 12.7044 0.833984 9.66683V6.3335C0.833984 3.29593 3.29642 0.833496 6.33398 0.833496ZM6.33398 1.8335C3.8487 1.8335 1.83398 3.84821 1.83398 6.3335V9.66683C1.83398 12.1521 3.8487 14.1668 6.33398 14.1668H9.66732C12.1526 14.1668 14.1673 12.1521 14.1673 9.66683V6.3335C14.1673 3.84821 12.1526 1.8335 9.66732 1.8335H6.33398Z"
                        fill="#92929D"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_284_1017">
                        <rect width="16" height="16" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>

                <div className="fs-14">4/5</div>
              </div>

              <Badge
                bg="warning"
                text="dark"
                className="d-flex align-items-center  custom-badge  gap-1"
              >
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M8 14.5C11.5899 14.5 14.5 11.5899 14.5 8C14.5 4.41015 11.5899 1.5 8 1.5C4.41015 1.5 1.5 4.41015 1.5 8C1.5 11.5899 4.41015 14.5 8 14.5Z"
                      stroke="#92929D"
                    />
                    <path
                      d="M7.99925 3.85303V7.99996L6.01562 9.76885"
                      stroke="#92929D"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>

                <div className="badge-title">7 days left</div>
              </Badge>
            </div>

            <div className="custom-width">
              <Progress percent={85} strokeColor="#3DD598" />
            </div>

            <div>
              <Avatar.Group
                maxCount={2}
                size="large"
                maxStyle={{
                  color: "#f56a00",
                  backgroundColor: "#fde3cf",
                }}
                className=""
              >
                <Avatar src={img} />
                <Avatar
                  style={{
                    backgroundColor: "#f56a00",
                  }}
                >
                  K
                </Avatar>
                <Avatar
                  style={{
                    backgroundColor: "#A461D8",
                  }}
                >
                  RR
                </Avatar>

                <Avatar
                  style={{
                    backgroundColor: "#A461D8",
                  }}
                >
                  K
                </Avatar>

                <Avatar
                  style={{
                    backgroundColor: "#A461D8",
                  }}
                >
                  K
                </Avatar>
                <Tooltip title="Ant User" placement="top">
                  <Avatar
                    style={{
                      backgroundColor: "#87d068",
                    }}
                    icon={<UserOutlined />}
                  />
                </Tooltip>
                <Avatar
                  style={{
                    backgroundColor: "#1890ff",
                  }}
                />
              </Avatar.Group>
            </div>

            <div className="cu-pointer  add-icon " type="button">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7 12C7 10.8954 6.10457 10 5 10C3.89543 10 3 10.8954 3 12C3 13.1046 3.89543 14 5 14C6.10457 14 7 13.1046 7 12Z"
                  fill="#92929D"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14C13.1046 14 14 13.1046 14 12Z"
                  fill="#92929D"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M21 12C21 10.8954 20.1046 10 19 10C17.8954 10 17 10.8954 17 12C17 13.1046 17.8954 14 19 14C20.1046 14 21 13.1046 21 12Z"
                  fill="#92929D"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PendingCard;
