import React from "react";
import "./EmailFilterForm.scss";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const EmailFilterForm = () => {
  return (
    <div className="email-filter-wrapper">
      <Form className="filter-form-wrapper">
        <fieldset>
          <Form.Group className="mb-3">
            <Form.Label className="custom-form-label">Search</Form.Label>
            <Form.Select id="select" className="custom-form-select">
              <option>Weekly Mails</option>
              <option>Today</option>
              <option>All Mails</option>
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label className="custom-form-label">From</Form.Label>
            <Form.Select id="select" className="custom-form-select">
              <option>ozi@sebostudio.com</option>
              <option>test@zeepalm.com</option>
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label className="custom-form-label">To</Form.Label>
            <Form.Select id="select" className="custom-form-select">
              <option>ozi@sebostudio.com</option>
              <option>test@zeepalm.com</option>
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label className="custom-form-label">Keyword</Form.Label>
            <Form.Select id="select" className="custom-form-select">
              <option>Project</option>
              <option>Docs</option>
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3" as={Row}>
            <Form.Group
              as={Col}
              sm="6"
              className="mb-3"
              controlId="formPlaintextPassword"
            >
              <Form.Label className="custom-form-label">Password</Form.Label>
              <Col>
                <Form.Control
                  type="date"
                  placeholder=""
                  className="custom-form-input"
                />
              </Col>
            </Form.Group>

            <Form.Group
              as={Col}
              sm="6"
              className="mb-3"
              controlId="formPlaintextPassword"
            >
              <Form.Label className="custom-form-label">Password</Form.Label>
              <Col>
                <Form.Control
                  type="date"
                  placeholder=""
                  className="custom-form-input"
                />
              </Col>
            </Form.Group>
          </Form.Group>

          <div className="d-flex gap-3 align-items-center">
            <Form.Group className="mb-3">
              <Form.Check
                type="checkbox"
                id="disabledFieldsetCheck"
                label="Has Attachment"
                className="custom-check-label  d-flex justify-content-center align-items-center gap-2"
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Check
                type="checkbox"
                id="disabledFieldsetCheck"
                label="Chat only"
                className="custom-check-label d-flex justify-content-center align-items-center gap-2"
              />
            </Form.Group>
          </div>

          <div className="d-flex  flex-wrap justify-content-end gap-3 align-items-center">
            <Button
              type="submit"
              variant="outline-light"
              className="custom-outline-btn"
            >
              Cancel
            </Button>
            <Button type="submit" className="custom-secondary-btn">
              Submit
            </Button>
          </div>
        </fieldset>
      </Form>
    </div>
  );
};

export default EmailFilterForm;
