import React, { useState } from "react";
import "./earninghistory.scss";
import { Segmented, Tabs } from "antd";
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

const EarningHistory = () => {
  const [alignValue, setAlignValue] = useState("Monthly");

  const data = [
    { monthly: "JAN", sales: 14000, weekly: "Sat", yearly: "2021" },
    { monthly: "Feb", sales: 9000, weekly: "Sun", yearly: "2022" },
    { monthly: "Mar", sales: 22000, weekly: "Monday", yearly: "2023" },
    { monthly: "Apr", sales: 19000, weekly: "Tuesday", yearly: "2022" },
    { monthly: "May", sales: 6500, weekly: "Wednesday", yearly: "2020" },
    { monthly: "Jun", sales: 27632, weekly: "Thursday", yearly: "2018" },
    { monthly: "July", sales: 1200, yearly: "2023", weekly: "Friday" },
    { monthly: "Aug", sales: 0, yearly: "2023", weekly: "Saturday" },
    { monthly: "Sept", sales: 1400, yearly: "2023", weekly: "Sunday" },
    { monthly: "Oct", sales: 6000, yearly: "2023", weekly: "Monday" },
    { monthly: "Nov", sales: 7000, yearly: "2023", weekly: "Tuesday" },
    { monthly: "Decmb", sales: 9500, yearly: "2023", weekly: "Wednesday" },
  ];

  const getDataKey = () => {
    switch (alignValue) {
      case "Weekly":
        return "weekly";
      case "Monthly":
        return "monthly";
      case "Yearly":
        return "yearly"; // Assuming you have a "yearly" property in your data
      default:
        return "monthly";
    }
  };

  return (
    <div className="earning-card-wrapper">
      <div className="d-flex flex-column gap-3">
        <div className="card-header d-flex justify-content-between  gap-2 flex-wrap align-items-center">
          <div className="card-title">Earnings History</div>

          <div>
            <Segmented
              defaultValue={alignValue}
              style={{ marginBottom: 8 }}
              onChange={(value) => setAlignValue(value)}
              options={["Weekly", "Monthly", "Yearly"]}
            />
          </div>
        </div>

        <ResponsiveContainer width="100%" aspect={3}>
          <LineChart width={600} height={300} data={data}>
            <CartesianGrid
              stroke="#ccc"
              strokeDasharray="1 "
              vertical="true"
              horizontal=""
              // strokeLinecap="dash"
            />
            <XAxis dataKey={getDataKey()} tickMargin={20} fontSize={12} />
            <YAxis tickMargin={20} fontSize={12} />
            <Tooltip />

            <Line
              type="monotone"
              dataKey="sales"
              stroke="#0062FF"
              strokeWidth={2}
            />

            {/* Add more <Line> components with different dataKey props for each line */}
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default EarningHistory;
