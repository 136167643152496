import React, { useState, useEffect } from "react";

import { Col, Row } from "react-bootstrap";
import SalesCard from "../Components/SalesCard/SalesCard";
import SyntaxHighlighter from "react-syntax-highlighter";
import { atomOneDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import MultipleLineGraph from "../Components/MultipleLineGraph/MultipleLineGraph";
import SalesLocation from "../Components/SalesLocation/SalesLocation";
import { CopyOutlined, CheckOutlined } from "@ant-design/icons";
import PhotoVideo from "../Components/PhotoVideo/PhotoVideo";
import LikePage from "../Components/LikePage/LikePage";
import PubgCard from "../Components/PubgCard/PubgCard";

const PubgCode = () => {
  const codeString = `
  import React from "react";
  import "./PubgCard.scss";
  import img from "../../assets/images/Team photo.png";
  import img2 from "../../assets/images/PP.png";
  import { AntDesignOutlined, UserOutlined } from "@ant-design/icons";
  import "./PubgCard.scss";
  import cardimg from "../../assets/images/cardimg.png";
  import { Avatar, Divider, Tooltip } from "antd";
  
  const PubgCard = () => {
    return (
      <div className="d-flex flex-column  card-container">
        <div className="img-section">
          <img src={cardimg} className="card-img"></img>
        </div>
        <div className="card-data-section">
          <div className="d-flex align-items-center gap-3">
            <div className="date-tag">27</div>
            <div className="d-flex flex-column gap-3">
              <div className="card-title-tag">PUBG Competition</div>
              <div className="card-desc-tag">Thu 10.00 . Hartono Mall, YK</div>
  
              <div>
                <Avatar.Group
                  maxCount={3}
                  size="large"
                  maxStyle={{
                    color: "#696974",
                    backgroundColor: "#ffff",
                    border: "1px solid #E2E2EA",
                  }}
                  className="avataor-group"
                >
                  <Avatar src={img2} />
                  <Avatar
                    style={{
                      backgroundColor: "#f56a00",
                    }}
                  >
                    K
                  </Avatar>
                  <Avatar
                    style={{
                      backgroundColor: "#A461D8",
                    }}
                  >
                    RR
                  </Avatar>
  
                  <Avatar
                    style={{
                      backgroundColor: "#A461D8",
                    }}
                  >
                    K
                  </Avatar>
  
                  <Avatar
                    style={{
                      backgroundColor: "#A461D8",
                    }}
                  >
                    K
                  </Avatar>
                  <Tooltip title="Ant User" placement="top">
                    <Avatar
                      style={{
                        backgroundColor: "#87d068",
                      }}
                      icon={<UserOutlined />}
                    />
                  </Tooltip>
                  <Avatar
                    style={{
                      backgroundColor: "#1890ff",
                    }}
                    icon={<UserOutlined />}
                  />
                </Avatar.Group>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  export default PubgCard;          
                  `;

  const styleString = `
  @import "../../CommonStyle/Variables.scss";
  .card-container {
    .img-section {
      height: 160px;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      .card-img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
      }
    }
  
    .card-data-section {
      padding: 20px 15px;
      background-color: $light;
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
  
      .date-tag {
        font-size: 24px;
        line-height: $line-height-36px;
        letter-spacing: 0.1px;
        color: $dark75;
        font-weight: $font-weight-bold;
      }
  
      .card-title-tag {
        font-size: $font-size-16px;
        line-height: $line-height-24px;
        color: $gray44f;
        font-weight: $font-weight-bold;
      }
  
      .card-desc-tag {
        font-size: $font-size-14px;
        line-height: $line-height-16px;
        color: $gray29d;
        font-weight: $font-weight-regular;
      }
  
      .ant-avatar-group {
        gap: 20px;
      }
    }
  }
  
                  `;
  const [copy, setCopy] = useState(false);

  const [active, setActive] = useState(1);

  const setActiveId = () => {
    if (active === 1) {
      navigator.clipboard.writeText(codeString);
      setCopy(true);
    }

    if (active === 2) {
      navigator.clipboard.writeText(styleString);
      setCopy(true);
    }
  };

  useEffect(() => {
    setCopy(false);
  }, [active]);
  return (
    <div className="">
      <Row className="mx-0 gy-lg-0  gy-3 ">
        <Col lg={4} className="position-relative">
          <div className="custom-position-sticky">
            <PubgCard />
          </div>
        </Col>

        <Col lg={8} className="d-flex flex-column  gap-4 ">
          <div>
            <div className="d-flex justify-content-between align-items-center  p-3  bg-secondary  br-top">
              <Nav variant="pills" className="d-flex  gap-2 ">
                <Nav.Item>
                  <Nav.Link
                    eventKey="first"
                    onClick={() => setActive(1)}
                    className={active === 1 ? "active" : ""}
                  >
                    JSX
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="second"
                    onClick={() => setActive(2)}
                    className={active === 2 ? "active" : ""}
                  >
                    CSS
                  </Nav.Link>
                </Nav.Item>
              </Nav>

              {copy ? (
                <div className="d-flex gap-2 align-items-center">
                  <CheckOutlined
                    style={{
                      fontSize: "20px",
                      color: "#ffffff",
                    }}
                  />
                  <div
                    className="text-light  cu-pointer"
                    onClick={() => {
                      navigator.clipboard.writeText("").then(() => {
                        setCopy(false);
                      });
                    }}
                  >
                    Copied
                  </div>
                </div>
              ) : (
                <div className="d-flex gap-2 align-items-center">
                  <CopyOutlined
                    style={{
                      fontSize: "20px",
                      color: "#ffffff",
                    }}
                  />

                  <div className="text-light  cu-pointer" onClick={setActiveId}>
                    Copy Code
                  </div>
                </div>
              )}
            </div>

            <div className={`${active === 1 ? "d-block" : "d-none"}`}>
              <SyntaxHighlighter
                language="javascript"
                style={atomOneDark}
                className="p-1   p-sm-2 br-bottom"
              >
                {codeString}
              </SyntaxHighlighter>
            </div>

            <div className={`${active === 2 ? "d-block" : "d-none"}`}>
              <SyntaxHighlighter
                language="javascript"
                style={atomOneDark}
                className="p-1   p-sm-2"
              >
                {styleString}
              </SyntaxHighlighter>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default PubgCode;
