import React from "react";
import {
  createBrowserRouter,
  BrowserRouter as Router,
  Routes,
  Route,
  RouterProvider,
} from "react-router-dom";
import Home from "../Views/Home";
import HomeChild from "../Pages/HomeChild";
import AllComponents from "../Pages/ALLComponents/AllComponents";
import SalesStash from "../Pages/SalesStashPage/SalesStash";
import DealsCodePage from "../Pages/DealsCodePage/DealsCodePage";
import LogoDesignCode from "../Pages/LogoDesignCodePage/LogoDesignCode";
import MultipleLineGraph from "../Components/MultipleLineGraph/MultipleLineGraph";
import MultilineGraphCodePage from "../Pages/MultilineGraphCodePage";
import SinglLineCodePage from "../Pages/SinglLineCodePage";
import OrderCardCodePage from "../Pages/OrderCardCodePage";
import VisitorSlider from "../Components/VisitorSlider/VisitorSlider";
import VisitorSliderCodePage from "../Pages/VisitorSliderCodePage";
import NewCustomerCodePage from "../Pages/NewCustomerCodePage";
import AverageTotalSaleCode from "../Pages/AverageTotalSaleCode";
import BarGraphCodePage from "../Pages/BarGraphCodePage";
import SeboStudioCode from "../Pages/SeboStudioCode";
import StoriesCardCode from "../Pages/StoriesCardCode";
import CompleteProfileCode from "../Pages/CompleteProfileCode";
import ProjectPendingCode from "../Pages/ProjectPendingCode";
import ProjectSchduleCode from "../Pages/ProjectSchduleCode";
import LodoDesignPicCode from "../Pages/LodoDesignPicCode";
import SalesLocationCode from "../Pages/SalesLocationCode";
import PhotoVideoCode from "../Pages/PhotoVideoCode";
import LikePageCode from "../Pages/LikePageCode";
import PubgCode from "../Pages/PubgCode";
import TransactionTableCode from "../Pages/TransactionTableCode";
import RefferCode from "../Pages/RefferCode";
import NotificationCode from "../Pages/NotificationCode";
import EmailFilterCode from "../Pages/EmailFilterCode";
import RunProjectCode from "../Pages/RunProjectCode";
import RecommendedTabCode from "../Pages/RecommendedTabCode";
import CoverPhotoCode from "../Pages/CoverPhotoCode";
import CustomCalenderCode from "../Pages/CustomCalenderCode";
import AccordianDropCode from "../Pages/AccordianDropCode";
import DesignUIKitCode from "../Pages/DesignUIKitCode";

const ComponentRoutes = () => {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,

      children: [
        {
          path: "allcomponents",
          element: <AllComponents />,
          title: "Home",

          children: [
            {
              path: "salesstash",

              element: <SalesStash />,
              title: "Home",
            },
          ],
        },

        {
          path: "salesstash",

          element: <SalesStash />,
          title: "Home",
        },

        {
          path: "dealscomponent",

          element: <DealsCodePage />,
          title: "Home",
        },

        {
          path: "logodeigncard",

          element: <LogoDesignCode />,
          title: "Home",
        },

        {
          path: "multilinegraph",

          element: <MultilineGraphCodePage />,
          title: "Home",
        },

        {
          path: "singlelinegraph",

          element: <SinglLineCodePage />,
          title: "Home",
        },

        {
          path: "ordercard",

          element: <OrderCardCodePage />,
          title: "Home",
        },

        {
          path: "visitorslider",

          element: <VisitorSliderCodePage />,
          title: "Home",
        },
        {
          path: "customercard",

          element: <NewCustomerCodePage />,
          title: "Home",
        },

        {
          path: "averagesalecard",

          element: <AverageTotalSaleCode />,
          title: "Home",
        },

        {
          path: "barchartgraph",

          element: <BarGraphCodePage />,
          title: "Home",
        },

        {
          path: "studiocard",

          element: <SeboStudioCode />,
          title: "Home",
        },

        {
          path: "storiescard",

          element: <StoriesCardCode />,
          title: "Home",
        },

        {
          path: "completeprofile",

          element: <CompleteProfileCode />,
          title: "Home",
        },

        {
          path: "projectpending",

          element: <ProjectPendingCode />,
          title: "Home",
        },
        {
          path: "projectschedules",

          element: <ProjectSchduleCode />,
          title: "Home",
        },

        {
          path: "projectoverview",

          element: <LodoDesignPicCode />,
          title: "Home",
        },

        {
          path: "saleslocation",

          element: <SalesLocationCode />,
          title: "Home",
        },
        {
          path: "photogallery",

          element: <PhotoVideoCode />,
          title: "Home",
        },
        {
          path: "likefeed",

          element: <LikePageCode />,
          title: "Home",
        },
        {
          path: "companyevent",

          element: <PubgCode />,
          title: "Home",
        },

        {
          path: "transactiontable",

          element: <TransactionTableCode />,
          title: "Home",
        },
        {
          path: "reffertable",

          element: <RefferCode />,
          title: "Home",
        },

        {
          path: "notificationcard",

          element: <NotificationCode />,
          title: "Home",
        },
        {
          path: "filterform",

          element: <EmailFilterCode />,
          title: "Home",
        },

        {
          path: "runproject",

          element: <RunProjectCode />,
          title: "Home",
        },

        {
          path: "recommendedtab",

          element: <RecommendedTabCode />,
          title: "Home",
        },

        {
          path: "profilecover",

          element: <CoverPhotoCode />,
          title: "Home",
        },
        {
          path: "customcalender",

          element: <CustomCalenderCode />,
          title: "Home",
        },
        {
          path: "accordianearch",

          element: <AccordianDropCode />,
          title: "Home",
        },
        {
          path: "designuimodel",

          element: <DesignUIKitCode />,
          title: "Home",
        },
      ],
    },
  ]);

  return (
    <div>
      <RouterProvider router={router} />
    </div>
  );
};

export default ComponentRoutes;
