import React from "react";
import { Avatar, Space } from "antd";
import img from "../../assets/images/coverprofile.png";
import Badge from "react-bootstrap/Badge";
import coverphoto from "../../assets/images/coverphoto.png";
import "./CoverPhotoCard.scss";
import { Col, Row } from "react-bootstrap";

const CoverPhotoCard = () => {
  return (
    <div className="cover-card-wrapper">
      <div className="cover-photo-area">
        <img src={coverphoto} className="cover-photo-inr"></img>
      </div>

      <div className="   profile-desc-area">
        <Row>
          <Col sm={3} className="profile-pic-col">
            <div className="profile-pic">
              <Avatar src={img} size={86} className="Profile-pic-inr"></Avatar>
            </div>
          </Col>
          <Col sm={9}>
            <div className="d-flex  flex-column gap-2 w-100">
              <div className="d-flex justify-content-between align-items-center">
                <div className="profile-title">Mayke Schuurs</div>

                <div>
                  {" "}
                  <Badge bg="primary">Following</Badge>
                </div>
              </div>
              <div className="mail-tag">@maymayke</div>
              <div className="profile-desc">Not a Man but a Ghost 👻</div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default CoverPhotoCard;
