import React from "react";
import "./VisitorSlider.scss";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation } from "swiper/modules";
import { Flex, Progress } from "antd";
import { Row, Col } from "react-bootstrap";

const VisitorSlider = () => {
  const conicColors = {
    "0%": "#0062FF",
    "50%": "#FFC542",
    "65%": "#FF974A",
    "100%": "#FFC542",
  };

  const visitordata = [
    {
      visityear: "2017",
      am: "2.1",
      al: "1",
      Eb: "1.9",
      sp: "15.7",
      totalvisit: "22,870",
    },
    {
      visityear: "2018",
      am: "1",
      al: "1",
      Eb: "2.9",
      sp: "15.7",
      totalvisit: "19,000",
    },

    {
      visityear: "2019",
      am: "1",
      al: "1",
      Eb: "1.9",
      sp: "7.7",
      totalvisit: "15,000",
    },
  ];
  return (
    <div className="visitorSlider-Wrapper">
      <div className="d-flex flex-column align-items-center  gap-4 ">
        <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
          {visitordata.map((item, index) => (
            <SwiperSlide key={index}>
              <div className="d-flex flex-column align-items-center justify-content-center gy-4  gap-4">
                <span className="fw-bold">{item.visityear}</span>

                <div>
                  {" "}
                  <Progress
                    type="circle"
                    percent={100}
                    strokeColor={conicColors}
                    size={(180, 180)}
                    format={() => (
                      <div className="total-sales  d-flex flex-column gap-2">
                        <div className="sale-title">{item.totalvisit}</div>
                        <div className="sale-desc fs-14">
                          Visitors this year
                        </div>
                      </div>
                    )}
                  ></Progress>
                </div>

                <Row className="mx-0  gy-3">
                  <Col sm={6}>
                    <div className="d-flex align-items-center gap-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="12"
                        viewBox="0 0 10 12"
                        fill="none"
                      >
                        <rect width="10" height="12" rx="5" fill="#0062FF" />
                      </svg>

                      <div className="badge-title">
                        Amazone <span className="fw-bold">{item.am}k</span>
                      </div>
                    </div>
                  </Col>

                  <Col sm={6}>
                    <div className="d-flex align-items-center gap-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="12"
                        viewBox="0 0 10 12"
                        fill="none"
                      >
                        <rect width="10" height="12" rx="5" fill="#FF974A" />
                      </svg>

                      <div className="badge-title">
                        ALibaba <span className="fw-bold">{item.al}k</span>
                      </div>
                    </div>
                  </Col>

                  <Col sm={6}>
                    <div className="d-flex align-items-center gap-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="12"
                        viewBox="0 0 10 12"
                        fill="none"
                      >
                        <rect width="10" height="12" rx="5" fill="#3DD598" />
                      </svg>

                      <div className="badge-title">
                        Ebay <span className="fw-bold">{item.Eb}k</span>
                      </div>
                    </div>
                  </Col>

                  <Col sm={6}>
                    <div className="d-flex align-items-center gap-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="12"
                        viewBox="0 0 10 12"
                        fill="none"
                      >
                        <rect width="10" height="12" rx="5" fill="#FFC542" />
                      </svg>

                      <div className="badge-title">
                        Shopify <span className="fw-bold">{item.sp}k</span>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default VisitorSlider;
