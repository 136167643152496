import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import SyntaxHighlighter from "react-syntax-highlighter";
import { atomOneDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import Nav from "react-bootstrap/Nav";

import { CopyOutlined, CheckOutlined } from "@ant-design/icons";

import RecommendedTab from "../Components/RecommendedTab/RecommendedTab";

const RecommendedTabCode = () => {
  const codeString = `
  import React from "react";
  import { Avatar, Space } from "antd";
  import { DownOutlined, SmileOutlined } from "@ant-design/icons";
  import { Dropdown } from "antd";
  import "./RecommendedTab.scss";
  import Badge from "react-bootstrap/Badge";
  
  const RecommendedTab = () => {
    const ratiodata = [
      {
        ratiotitle: "1 year",
        ratiopercent: "-1.5%",
      },
  
      {
        ratiotitle: "3 year",
        ratiopercent: "+12.0%",
      },
  
      {
        ratiotitle: "5 years",
        ratiopercent: "+15.3%",
      },
      {
        ratiotitle: "Cost",
        ratiopercent: "2.1%",
      },
      {
        ratiotitle: "View",
        ratiopercent: "63",
      },
    ];
  
    const items = [
      {
        key: "1",
        label: (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.antgroup.com"
          >
            1st menu item
          </a>
        ),
      },
      {
        key: "2",
        label: (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.aliyun.com"
          >
            2nd menu item (disabled)
          </a>
        ),
        icon: <SmileOutlined />,
      },
      {
        key: "3",
        label: (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.luohanacademy.com"
          >
            3rd menu item (disabled)
          </a>
        ),
        disabled: true,
      },
      {
        key: "4",
        danger: true,
        label: "a danger item",
      },
    ];
    return (
      <div className="recomended-wrapper d-flex flex-column gap-3">
        <div className="recomended-title">Recommended</div>
        <div className="d-flex flex-column flex-lg-row justify-content-between align-items-center recomended-tab-wrapper gap-3">
          <div className="tab-name-area d-flex align-items-center gap-3">
            <div>
              <Avatar
                shape="square"
                size={38}
                style={{
                  backgroundColor: "#0062FF",
                }}
              >
                32
              </Avatar>
            </div>
            <div className="d-flex flex-column gap-2">
              <div className="rec-title">New World Fund 2019</div>
  
              <div>
                <Dropdown
                  menu={{
                    items,
                  }}
                >
                  <a onClick={(e) => e.preventDefault()} className="font-14">
                    <Space>
                      Industries
                      <DownOutlined />
                    </Space>
                  </a>
                </Dropdown>
              </div>
            </div>
          </div>
  
          <div className="ratio-area  d-flex  gap-1 flex-wrap">
            {ratiodata.map((item, index) => (
              <div className="ratio-item   d-flex flex-column gap-2 px-5 ">
                <div className="font-14 "> {item.ratiotitle}</div>
                <div
                  className={ {
                    item.ratiopercent.includes("-") ? "loss-percent" : "font-16"
                  } {
                    item.ratiopercent.includes("+") ? "profit-percent" : "font-16"
                  }}
                >
                  {item.ratiopercent}
                </div>
              </div>
            ))}
          </div>
  
          <div className=" holding-area  d-flex  align-items-center  gap-4">
            <div>
              <Dropdown
                menu={{
                  items,
                }}
              >
                <a onClick={(e) => e.preventDefault()} className="font-14">
                  <Space>
                    Holdings
                    <DownOutlined />
                  </Space>
                </a>
              </Dropdown>
            </div>
  
            <div>
              <Badge className="rec-badge">Mid Risk</Badge>
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  export default RecommendedTab;
          
                    `;

  const styleString = `
  @import "../../CommonStyle/Variables.scss";
  .recomended-wrapper {
    .recomended-tab-wrapper {
      padding: 21px 22px;
      background-color: $light;
      border-radius: 15px;
  
      .rec-title {
        font-size: $font-size-16px;
        line-height: $line-height-24px;
        color: $dark75;
        font-weight: $font-weight-bold;
      }
  
      .font-14 {
        font-size: $font-size-14px;
        line-height: $line-height-20px;
        color: $gray44f;
        font-weight: $font-weight-semibold;
      }
  
      .font-16 {
        font-size: $font-size-16px;
        line-height: $line-height-24px;
        color: $gray974;
        font-weight: $font-weight-bold;
      }
      .ratio-area {
        .ratio-item {
          border-left: 1px solid $darkf15;
          // border-right: 1px solid $darkf15;
          // padding: 0px 30px;
          .loss-percent {
            font-size: $font-size-16px;
            line-height: $line-height-24px;
            color: $red5fa;
            font-weight: $font-weight-bold;
          }
  
          .profit-percent {
            font-size: $font-size-16px;
            line-height: $line-height-24px;
            color: $green98;
            font-weight: $font-weight-bold;
          }
        }
      }
  
      .holding-area {
        .rec-badge {
          background-color: transparent !important;
          border: 1px solid $orange74a;
          color: $orange74a;
          font-size: $font-size-14px;
          line-height: $line-height-22px;
          font-weight: $font-weight-semibold;
        }
      }
    }
  }
  
                    `;
  const [copy, setCopy] = useState(false);

  const [active, setActive] = useState(1);

  const setActiveId = () => {
    if (active === 1) {
      navigator.clipboard.writeText(codeString);
      setCopy(true);
    }

    if (active === 2) {
      navigator.clipboard.writeText(styleString);
      setCopy(true);
    }
  };

  useEffect(() => {
    setCopy(false);
  }, [active]);
  return (
    <div className="">
      <Row className="mx-0 gy-lg-0  gy-3 ">
        <Col lg={5} className="position-relative">
          <div className="custom-position-sticky">
            <RecommendedTab />
          </div>
        </Col>

        <Col lg={7} className="d-flex flex-column  gap-4 ">
          <div>
            <div className="d-flex justify-content-between align-items-center  p-3  bg-secondary  br-top">
              <Nav variant="pills" className="d-flex  gap-2 ">
                <Nav.Item>
                  <Nav.Link
                    eventKey="first"
                    onClick={() => setActive(1)}
                    className={active === 1 ? "active" : ""}
                  >
                    JSX
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="second"
                    onClick={() => setActive(2)}
                    className={active === 2 ? "active" : ""}
                  >
                    CSS
                  </Nav.Link>
                </Nav.Item>
              </Nav>

              {copy ? (
                <div className="d-flex gap-2 align-items-center">
                  <CheckOutlined
                    style={{
                      fontSize: "20px",
                      color: "#ffffff",
                    }}
                  />
                  <div
                    className="text-light  cu-pointer"
                    onClick={() => {
                      navigator.clipboard.writeText("").then(() => {
                        setCopy(false);
                      });
                    }}
                  >
                    Copied
                  </div>
                </div>
              ) : (
                <div className="d-flex gap-2 align-items-center">
                  <CopyOutlined
                    style={{
                      fontSize: "20px",
                      color: "#ffffff",
                    }}
                  />

                  <div className="text-light  cu-pointer" onClick={setActiveId}>
                    Copy Code
                  </div>
                </div>
              )}
            </div>

            <div className={`${active === 1 ? "d-block" : "d-none"}`}>
              <SyntaxHighlighter
                language="javascript"
                style={atomOneDark}
                className="p-1   p-sm-2 br-bottom"
              >
                {codeString}
              </SyntaxHighlighter>
            </div>

            <div className={`${active === 2 ? "d-block" : "d-none"}`}>
              <SyntaxHighlighter
                language="javascript"
                style={atomOneDark}
                className="p-1   p-sm-2"
              >
                {styleString}
              </SyntaxHighlighter>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default RecommendedTabCode;
