import React, { useState } from "react";
import Card from "react-bootstrap/Card";
import { Pagination } from "antd";
import ListGroup from "react-bootstrap/ListGroup";
import Button from "react-bootstrap/Button";
import { useOutletContext } from "react-router-dom";
import { Badge, Space } from "antd";

import "./AllComponents.css";
import { Col, Row } from "react-bootstrap";
import SalesCard from "../../Components/SalesCard/SalesCard";
import { Link, Outlet } from "react-router-dom";
import DealsCard from "../../Components/DealsCard/DealsCard";
import NewLogoDesignCard from "../../Components/NewLogoDesign/NewLogoDesignCard";
import MultipleLineGraph from "../../Components/MultipleLineGraph/MultipleLineGraph";
import EarningHistory from "../../Components/EarningHistory/EarningHistory";
import Order from "../../Components/OrderCard/Order";
import VisitorSlider from "../../Components/VisitorSlider/VisitorSlider";
import CustomerCards from "../../Components/CustomersCard/CustomerCards";
import AverageSaleCard from "../../Components/AvergeSaleCard/AverageSaleCard";
import BarChartGraph from "../../Components/BarChartGraph/BarChart";
import StudioCard from "../../Components/StudioCard/StudioCard";
import StoriesComponent from "../../Components/StoriesComponents/StoriesComponent";
import CompleteProfile from "../../Components/CompleteProfile/CompleteProfile";
import PendingCard from "../../Components/PendingComponent/PendingCard";
import ProjectSchedule from "../../Components/ProjectSchedule/ProjectSchedule";
import LogoDesignPicCard from "../../Components/LogoDesignPicCard/LogoDesignPicCard";
import SalesLocation from "../../Components/SalesLocation/SalesLocation";
import PhotoVideo from "../../Components/PhotoVideo/PhotoVideo";
import LikePage from "../../Components/LikePage/LikePage";
import PubgCard from "../../Components/PubgCard/PubgCard";
import TransactionCard from "../../Components/TransactionCard/TransactionCard";
import RefferCard from "../../Components/RefferCard/RefferCard";
import NotificationCard from "../../Components/NotificationCard/NotificationCard";
import EmailFilterForm from "../../Components/EmailFilter/EmailFilterForm";
import RunProject from "../../Components/RunProjectCard/RunProject";
import RecommendedTab from "../../Components/RecommendedTab/RecommendedTab";
import CoverPhotoCard from "../../Components/CoverPhotoCard/CoverPhotoCard";
import CustomCalender from "../../Components/CustomCalender/CustomCalender";
import AccordionDrop from "../../Components/AccordianDropDown/AccordionDrop";
import DesignUiModal from "../../Components/DesignUikitModal/DesignUiModal";

const AllComponents = (props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  let { searchtext } = props;
  const context = useOutletContext();
  if (!searchtext && context) {
    searchtext = context.searchtext;
  }
  const componentData = [
    {
      component: SalesCard,
      title: "Banner Card",
      link: "/salesstash",
      framework: "React",
      colSize: 4,
    },
    {
      component: DealsCard,
      title: "Custom Progress Bar",
      link: "/dealscomponent",
      framework: "React",
      colSize: 4,
    },
    {
      component: NewLogoDesignCard,
      title: "Check box Cards",
      link: "/logodeigncard",
      framework: "React",
      colSize: 4,
    },
    {
      component: MultipleLineGraph,
      title: "Multiline Graph",
      link: "/multilinegraph",
      framework: "React",
      colSize: 6,
    },
    {
      component: EarningHistory,
      title: "Single Line Graph",
      link: "/singlelinegraph",
      framework: "React",
      colSize: 6,
    },
    {
      component: Order,
      title: "Order Card",
      link: "/ordercard",
      colSize: 4,
      framework: "React",
    },
    {
      component: VisitorSlider,
      title: "MultiColor Progress Slider",
      link: "/visitorslider",
      framework: "React",
      colSize: 4,
    },
    {
      component: CustomerCards,
      title: " User Lists",
      link: "/customercard",
      framework: "React",
      colSize: 4,
    },
    {
      component: BarChartGraph,
      title: "BarChart Graph",
      link: "/barchartgraph",
      framework: "React",
      colSize: 6,
    },
    {
      component: AverageSaleCard,
      title: "Sales with Progress Status",
      link: "/averagesalecard",
      framework: "React",
      colSize: 6,
    },
    {
      component: StudioCard,
      title: "Team Avatars Card",
      link: "/studiocard",
      framework: "React",
      colSize: 4,
    },
    {
      component: StoriesComponent,
      title: "User List Card",
      link: "/storiescard",
      framework: "React",
      colSize: 4,
    },
    {
      component: CompleteProfile,
      title: "Horizontal Progress Bar",
      link: "/completeprofile",
      framework: "React",
      colSize: 4,
    },
    {
      component: PendingCard,
      title: "Project Bar along Project Status",
      link: "/projectpending",
      framework: "React",
      colSize: 6,
    },
    {
      component: ProjectSchedule,
      title: "Dynamic Project List",
      link: "/projectschedules",
      framework: "React",
      colSize: 6,
    },

    {
      component: LogoDesignPicCard,
      title: "Project Overview",
      link: "/projectoverview",
      framework: "React",
      colSize: 6,
    },
    {
      component: SalesLocation,
      title: "Company Location",
      link: "/saleslocation",
      framework: "React",
      colSize: 6,
    },
    {
      component: PhotoVideo,
      title: "Photo Gallery",
      link: "/photogallery",
      framework: "React",
      colSize: 4,
    },
    {
      component: LikePage,
      title: "Like Feed",
      link: "/likefeed",
      framework: "React",
      colSize: 4,
    },

    {
      component: PubgCard,
      title: "Company Event",
      link: "/companyevent",
      framework: "React",
      colSize: 4,
    },
    {
      component: TransactionCard,
      title: "Custom Responsive Table ",
      link: "/transactiontable",
      framework: "React",
      colSize: 6,
    },
    {
      component: RefferCard,
      title: "Custom Responsive Table",
      link: "/reffertable",
      framework: "React",
      colSize: 6,
    },

    {
      component: NotificationCard,
      title: "Dynamic Notification List",
      link: "/notificationcard",
      framework: "React",
      colSize: 4,
    },
    {
      component: EmailFilterForm,
      title: "Filter Form",
      link: "/filterform",
      framework: "React",
      colSize: 4,
    },
    {
      component: RunProject,
      title: "Projects Flow",
      link: "/runproject",
      framework: "React",
      colSize: 4,
    },

    {
      component: CoverPhotoCard,
      title: "Profile Cover",
      link: "/profilecover",
      framework: "React",
      colSize: 6,
    },
    {
      component: CustomCalender,
      title: "Custom Calendar",
      link: "/customcalender",
      framework: "React",
      colSize: 6,
    },

    {
      component: AccordionDrop,
      title: "Dynamic Search Tab",
      link: "/accordianearch",
      framework: "React",
      colSize: 6,
    },

    {
      component: DesignUiModal,
      title: "UI Kit Model",
      link: "/designuimodel",
      framework: "React",
      colSize: 6,
    },
    {
      component: RecommendedTab,
      title: "Tab Percentages",
      link: "/recommendedtab",
      framework: "React",
      colSize: 12,
    },
  ];

  const filteredComponentData = componentData.filter((data) => {
    return (
      data.title.toLowerCase().includes(searchtext.toLowerCase()) ||
      data.framework.toLowerCase().includes(searchtext.toLowerCase())
    );
  });

  console.log(searchtext, "All Component Search");

  const indexOfLastItem = currentPage * pageSize;
  const indexOfFirstItem = indexOfLastItem - pageSize;
  const currentItems = filteredComponentData.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (current, size) => {
    setPageSize(size);
    setCurrentPage(1);
  };
  return (
    <div className="d-flex flex-column gap-4">
      <Row className="mx-0 gy-3">
        {currentItems.map((data, index) => (
          <Col lg={data.colSize} key={index}>
            <div className="Show-components-card-wrapper d-flex flex-column gap-5 h-100 justify-content-between">
              <div className="component-section">
                <data.component />
              </div>
              <div className="d-flex flex-column gap-3">
                <div className="d-flex flex-column gap-2 position-relative ">
                  <h4 className="text-light font-1rem ">{data.title}</h4>
                  <Badge.Ribbon
                    text={data.framework}
                    color="red"
                    placement="end"
                  ></Badge.Ribbon>
                </div>

                <Link
                  to={data.link}
                  className="button-section d-flex justify-content-center"
                >
                  <Button variant="primary" size="lg" className="w-100">
                    Show Code
                  </Button>
                </Link>
              </div>
            </div>
          </Col>
        ))}
      </Row>

      <div className="d-flex justify-content-center py-5">
        <Pagination
          total={filteredComponentData.length}
          showTotal={(total) => `Total ${total} items`}
          defaultPageSize={20}
          defaultCurrent={1}
          current={currentPage}
          pageSize={pageSize}
          onChange={handlePageChange}
          onShowSizeChange={handlePageSizeChange}
          showSizeChanger
          pageSizeOptions={["10", "20", "30", "40"]}
        />
      </div>
    </div>
  );
};

export default AllComponents;
