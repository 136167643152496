import React, { useState } from "react";
import { Avatar, Space } from "antd";
import "./CustomersCard.scss";
import img from "../../assets/images/cust.png";

const data = [
  {
    id: 1,
    customername: "Isabella Moran",
    customerid: "Customer ID#00222",
    customerimg: img,
  },

  {
    id: 2,
    customername: "Isabella Moran",
    customerid: "Customer ID#00222",
    customerimg: img,
  },

  {
    id: 3,
    customername: "Isabella Moran",
    customerid: "Customer ID#00222",
    customerimg: img,
  },

  {
    id: 4,
    customername: "Isabella Moran",
    customerid: "Customer ID#00222",
    customerimg: img,
  },

  {
    id: 5,
    customername: "Isabella Moran",
    customerid: "Customer ID#00222",
    customerimg: img,
  },

  {
    id: 6,
    customername: "Isabella Moran",
    customerid: "Customer ID#00222",
    customerimg: img,
  },

  {
    id: 7,
    customername: "Isabella Moran",
    customerid: "Customer ID#00222",
    customerimg: img,
  },
];
const CustomerCards = () => {
  const [showAllCustomers, setShowAllCustomers] = useState(false);

  const toggleShowAllCustomers = () => {
    setShowAllCustomers(!showAllCustomers);
  };

  return (
    <div className="card-wrapper">
      <div className=" card-header  d-flex justify-content-between align-items-center">
        <div className="card-title">New Customers</div>
        <div className="cu-pointer  add-icon " type="button">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M7 12C7 10.8954 6.10457 10 5 10C3.89543 10 3 10.8954 3 12C3 13.1046 3.89543 14 5 14C6.10457 14 7 13.1046 7 12Z"
              fill="#92929D"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14C13.1046 14 14 13.1046 14 12Z"
              fill="#92929D"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M21 12C21 10.8954 20.1046 10 19 10C17.8954 10 17 10.8954 17 12C17 13.1046 17.8954 14 19 14C20.1046 14 21 13.1046 21 12Z"
              fill="#92929D"
            />
          </svg>
        </div>
      </div>

      <div className="card-data-wrapper">
        <div className="d-flex flex-column gap-3">
          {data
            .slice(0, showAllCustomers ? data.length : 4)
            .map((item, index) => (
              <div
                className="card-data-list  d-flex justify-content-between"
                key={index}
              >
                <div className="profile-section d-flex align-items-center">
                  <Avatar style={{ backgroundColor: "#A461D8" }}>RR</Avatar>

                  <div className="profile-titles  d-flex flex-column align-items-start">
                    <div className="name-title">{item.customername}</div>
                    <div className="idtitle">{item.customerid}</div>
                  </div>
                </div>

                <div className="icons-area d-flex gap-2">
                  <div
                    className="cu-pointer  message-div "
                    // onMouseEnter={() => toggleactive(item.id)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      className="message-icon"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M3.33405 3.3335H16.6674C18.0481 3.3335 19.1674 4.45278 19.1674 5.8335V6.18969C19.1675 6.19697 19.1675 6.20424 19.1674 6.21151V15.0002C19.1674 16.3809 18.0481 17.5002 16.6674 17.5002H3.33405C1.95334 17.5002 0.834055 16.3809 0.834055 15.0002V6.21148C0.833961 6.20423 0.833961 6.19698 0.834055 6.18972V5.8335C0.834055 4.45278 1.95334 3.3335 3.33405 3.3335ZM2.50072 7.80187V15.0002C2.50072 15.4604 2.87382 15.8335 3.33405 15.8335H16.6674C17.1276 15.8335 17.5007 15.4604 17.5007 15.0002V7.80187L11.4344 12.0483C10.5736 12.6509 9.42787 12.6509 8.56706 12.0483L2.50072 7.80187ZM17.4983 5.76916L10.4786 10.6829C10.1917 10.8838 9.80977 10.8838 9.52284 10.6829L2.50317 5.76916C2.53601 5.33899 2.89547 5.00016 3.33405 5.00016H16.6674C17.106 5.00016 17.4654 5.33899 17.4983 5.76916Z"
                        fill="#92929D"
                        className="msj-path"
                      />
                    </svg>
                  </div>

                  <div className="cu-pointer">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      className="delete-icon"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M15.5367 13.7135C16.2499 12.6522 16.666 11.3746 16.666 9.99984C16.666 6.31794 13.6812 3.33317 9.99935 3.33317C8.62337 3.33317 7.34476 3.75003 6.28291 4.46434L15.5367 13.7135ZM14.4392 14.9731L5.02393 5.56243C3.97203 6.74104 3.33268 8.29578 3.33268 9.99984C3.33268 13.6817 6.31745 16.6665 9.99935 16.6665C11.7046 16.6665 13.2603 16.0263 14.4392 14.9731ZM9.99935 1.6665C14.6017 1.6665 18.3327 5.39746 18.3327 9.99984C18.3327 14.6022 14.6017 18.3332 9.99935 18.3332C5.39698 18.3332 1.66602 14.6022 1.66602 9.99984C1.66602 5.39746 5.39698 1.6665 9.99935 1.6665Z"
                        fill="#92929D"
                        className="d-icon"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>

      <div
        className="card-footer d-flex justify-content-center  cu-pointer"
        onClick={toggleShowAllCustomers}
      >
        {showAllCustomers ? "View less Customers" : "View more Customers"}
      </div>
    </div>
  );
};

export default CustomerCards;
