import React, { useState, useEffect } from "react";

import { Col, Row } from "react-bootstrap";
import SyntaxHighlighter from "react-syntax-highlighter";
import { atomOneDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import { CopyOutlined, CheckOutlined } from "@ant-design/icons";
import CustomCalender from "../Components/CustomCalender/CustomCalender";

const CustomCalenderCode = () => {
  const codeString = `
  import React from "react";
  import { useState } from "react";
  import { Calendar, NextIcon } from "react-calendar";
  import "./CustomCalender.scss";
  import "react-calendar/dist/Calendar.css";
  import { Divider } from "antd";
  
  const CustomCalender = () => {
    const [value, onChange] = useState(new Date());
  
    const events = [
      { date: "2024-03-18", title: "Event 1" },
      { date: "2024-03-20", title: "Event 2" },
      { date: "2024-04-01", title: "Event 2" },
    ];
  
    const tileContent = ({ date, view }) => {
      if (view === "month") {
        const formattedDate = date.toISOString().split("T")[0];
        const event = events.find((event) => event.date === formattedDate);
        if (event) {
          return <div className="event-marker  ">•••</div>;
        }
      }
      return null;
    };
  
    const goToNextMonth = () => {
      const nextMonth = new Date(value);
      nextMonth.setMonth(nextMonth.getMonth() + 1);
      onChange(nextMonth);
    };
  
    const goToPreviousMonth = () => {
      const previousMonth = new Date(value);
      previousMonth.setMonth(previousMonth.getMonth() - 1);
      onChange(previousMonth);
    };
  
    console.log(value, "Latest Date");
    return (
      <div className="calender-main-wrapper  d-flex flex-column gap-4">
        <div className="d-flex justify-content-between gap-1">
          <div class="react-calendar__navigation__label  active-month-btn d-flex justify-content-center">
            <div class="  active-month-tag d-flex gap-1 align-items-center  justify-content-center">
              {value.toLocaleString("default", { month: "long" })}{" "}
              <span className="active-year">{value.getFullYear()}</span>
            </div>
          </div>
  
          <div className="custom-calender-line w-100 mx-1">
            <Divider></Divider>
          </div>
  
          <div className="d-flex gap-2 align-items-center">
            <div
              className="react-calendar__navigation__arrow react-calendar__navigation__next-button"
              fdprocessedid="jjbgew"
              type="button"
              onClick={goToNextMonth}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
              >
                <rect
                  x="0.5"
                  y="-0.5"
                  width="31"
                  height="31"
                  rx="7.5"
                  transform="matrix(1 0 0 -1 0 31)"
                  stroke="#E2E2EA"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M16 14.4142L11.7071 18.7071C11.3166 19.0976 10.6834 19.0976 10.2929 18.7071C9.90237 18.3166 9.90237 17.6834 10.2929 17.2929L15.2929 12.2929C15.6834 11.9024 16.3166 11.9024 16.7071 12.2929L21.7071 17.2929C22.0976 17.6834 22.0976 18.3166 21.7071 18.7071C21.3166 19.0976 20.6834 19.0976 20.2929 18.7071L16 14.4142Z"
                  fill="#92929D"
                />
              </svg>
            </div>
  
            <div
              fdprocessedid="7ltzis"
              type="button"
              className="react-calendar__navigation__arrow react-calendar__navigation__prev2-button"
              onClick={goToPreviousMonth}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
              >
                <rect
                  x="0.5"
                  y="-0.5"
                  width="31"
                  height="31"
                  rx="7.5"
                  transform="matrix(1 0 0 -1 0 31)"
                  stroke="#E2E2EA"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M16 17.5858L20.2929 13.2929C20.6834 12.9024 21.3166 12.9024 21.7071 13.2929C22.0976 13.6834 22.0976 14.3166 21.7071 14.7071L16.7071 19.7071C16.3166 20.0976 15.6834 20.0976 15.2929 19.7071L10.2929 14.7071C9.90237 14.3166 9.90237 13.6834 10.2929 13.2929C10.6834 12.9024 11.3166 12.9024 11.7071 13.2929L16 17.5858Z"
                  fill="#92929D"
                />
              </svg>
            </div>
          </div>
        </div>
  
        <Calendar
          onChange={onChange}
          value={value}
          next2Label=""
          prevLabel=""
          prev2Label=""
          nextLabel=""
          showNavigation={false}
          className="custom-calender-card"
          tileContent={tileContent}
        />
      </div>
    );
  };
  
  export default CustomCalender;
  
    
              `;

  const styleString = `
  @import "../../CommonStyle/Variables.scss";

  .calender-main-wrapper {
    background-color: $light;
    padding: 50px 40px;
    border-radius: 15px;
  
    .active-month-btn {
      .active-month-tag {
        font-size: $font-size-18px;
        line-height: $line-height-27px;
        color: $dark75;
        font-weight: $font-weight-bold;
  
        .active-year {
          font-weight: $font-weight-semibold;
        }
      }
    }
  
    .custom-calender-line {
      position: relative;
      .custom-line {
        //   position: absolute;
        top: 50%;
        //   transform: translateY(50%, -50%);
        border-bottom: 1px solid $lightea;
      }
    }
  
    .custom-calender-card {
      border: none !important;
      width: 100%;
  
      .react-calendar__tile {
        border-radius: 15px;
        color: $gray725;
        font-size: 16px;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 0px;
        padding-right: 0px;
  
        //   line-height: $line-height-52px;
        margin: 5px;
        position: relative;
  
        .event-marker {
          position: absolute;
          bottom: 2px;
          left: 50%;
          color: #0062ff;
          font-size: 15px;
          font-weight: $font-weight-bold;
          transform: translateX(-50%);
        }
      }
  
      .react-calendar__tile:hover {
        background-color: none !important;
        color: #0062ff;
      }
      .react-calendar__tile:enabled:hover,
      .react-calendar__tile:enabled:focus {
        background-color: none !important;
      }
  
      .react-calendar__tile--now {
        background: transparent;
      }
  
      .react-calendar__tile--active {
        background: #0062ff;
        color: white !important;
        position: relative;
  
        .event-marker {
          position: absolute;
          bottom: 2px;
          left: 50%;
          color: $light;
          font-size: 15px;
          font-weight: $font-weight-bold;
          transform: translateX(-50%);
        }
      }
  
      .react-calendar__month-view__weekdays {
        text-align: center;
        text-transform: uppercase;
        font: inherit;
        font-size: $font-size-12px;
        color: $gray29d;
        font-weight: bold;
        text-decoration: none !important;
      }
  
      abbr[title] {
        -webkit-text-decoration: underline !important;
        text-decoration: none !important;
        cursor: help;
        text-decoration-skip-ink: none;
      }
  
      .react-calendar__month-view__days {
        display: flex;
        justify-content: space-between;
      }
    }
  }
  
              `;

  const [copy, setCopy] = useState(false);
  const [styleCopy, setStyleCopy] = useState(false);
  const [active, setActive] = useState(1);

  const setActiveId = () => {
    if (active === 1) {
      navigator.clipboard.writeText(codeString);
      setCopy(true);
    }

    if (active === 2) {
      navigator.clipboard.writeText(styleString);
      setCopy(true);
    }
  };

  useEffect(() => {
    setCopy(false);
  }, [active]);
  return (
    <div className="">
      <Row className="mx-0 gy-lg-0  gy-3 ">
        <Col lg={5} className="position-relative">
          <div className="custom-position-sticky">
            <CustomCalender />
          </div>
        </Col>

        <Col lg={7} className="d-flex flex-column  gap-4 ">
          <div>
            <div className="d-flex justify-content-between align-items-center  p-3  bg-secondary  br-top">
              <Nav variant="pills" className="d-flex  gap-2 ">
                <Nav.Item>
                  <Nav.Link
                    eventKey="first"
                    onClick={() => setActive(1)}
                    className={active === 1 ? "active" : ""}
                  >
                    JSX
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="second"
                    onClick={() => setActive(2)}
                    className={active === 2 ? "active" : ""}
                  >
                    CSS
                  </Nav.Link>
                </Nav.Item>
              </Nav>

              {copy ? (
                <div className="d-flex gap-2 align-items-center">
                  <CheckOutlined
                    style={{
                      fontSize: "20px",
                      color: "#ffffff",
                    }}
                  />
                  <div
                    className="text-light  cu-pointer"
                    onClick={() => {
                      navigator.clipboard.writeText("").then(() => {
                        setCopy(false);
                      });
                    }}
                  >
                    Copied
                  </div>
                </div>
              ) : (
                <div className="d-flex gap-2 align-items-center">
                  <CopyOutlined
                    style={{
                      fontSize: "20px",
                      color: "#ffffff",
                    }}
                  />

                  <div className="text-light  cu-pointer" onClick={setActiveId}>
                    Copy Code
                  </div>
                </div>
              )}
            </div>

            <div className={`${active === 1 ? "d-block" : "d-none"}`}>
              <SyntaxHighlighter
                language="javascript"
                style={atomOneDark}
                className="p-1   p-sm-2 br-bottom"
              >
                {codeString}
              </SyntaxHighlighter>
            </div>

            <div className={`${active === 2 ? "d-block" : "d-none"}`}>
              <SyntaxHighlighter
                language="javascript"
                style={atomOneDark}
                className="p-1   p-sm-2"
              >
                {styleString}
              </SyntaxHighlighter>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default CustomCalenderCode;
