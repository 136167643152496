import React, { useState, useEffect } from "react";

import { Col, Row } from "react-bootstrap";
import SalesCard from "../Components/SalesCard/SalesCard";
import SyntaxHighlighter from "react-syntax-highlighter";
import { atomOneDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import MultipleLineGraph from "../Components/MultipleLineGraph/MultipleLineGraph";

import {
  StarOutlined,
  StarFilled,
  StarTwoTone,
  CopyOutlined,
  CheckOutlined,
} from "@ant-design/icons";

const MultilineGraphCodePage = () => {
  const codeString = `
    import React from "react";
    import "./MultipleLineGraph.scss";
    import {
      ResponsiveContainer,
      LineChart,
      Line,
      XAxis,
      YAxis,
      CartesianGrid,
      Tooltip,
      Legend,
    } from "recharts";
    
    const MultipleLineGraph = () => {
      const data = [
        { name: "JAN", sales: 14000, prevsale: 12000 },
        { name: "Feb", sales: 9000, prevsale: 11000 },
        { name: "Mar", sales: 22000, prevsale: 18000 },
        { name: "Apr", sales: 19000, prevsale: 21000 },
        { name: "May", sales: 6500, prevsale: 12000 },
        { name: "Jun", sales: 27632, prevsale: 29000 },
        { name: "July", sales: 1200 },
        { name: "Aug", sales: 0 },
        { name: "Sept", sales: 1400 },
        { name: "Oct", sales: 6000 },
        { name: "Nov", sales: 7000 },
        { name: "Decmb", sales: 9500 },
        // { name: "Page B", uv: 300, pv: 1398, amt: 2210 },
        // Add more data for other lines if needed
      ];
    
      return (
        <div className="sales-figure-wrapper d-flex flex-column ">
          <div className="  sales-figuer-header  d-flex justify-content-between align-items-center">
            <div className="figure-title">Sales Figures</div>
    
            <div className="cases-tags-wrapper d-flex align-items-center gap-3">
              <div className=" cases-tags  d-flex align-items-center  gap-2">
                <div className="">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="12"
                    viewBox="0 0 10 12"
                    fill="none"
                  >
                    <rect width="10" height="12" rx="5" fill="#0062FF" />
                  </svg>
                </div>
                <div className="tag-title"> Marketing Sales</div>
              </div>
    
              <div className="cases-tags  d-flex align-items-center  gap-2">
                <div className="">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="12"
                    viewBox="0 0 10 12"
                    fill="none"
                  >
                    <rect width="10" height="12" rx="5" fill="#3DD598" />
                  </svg>
                </div>
                <div className="tag-title"> Cases Sales</div>
              </div>
            </div>
          </div>
          <ResponsiveContainer width="100%" aspect={3}>
            <LineChart width={600} height={300} data={data}>
              <CartesianGrid
                stroke="#ccc"
                strokeDasharray="1 "
                vertical="true"
                horizontal=""
                // strokeLinecap="dash"
              />
              <XAxis dataKey="name" tickMargin={20} fontSize={12} />
              <YAxis tickMargin={20} fontSize={12} />
              <Tooltip />
              {/* <Legend /> */}
              <Line
                type="monotone"
                dataKey="sales"
                stroke="#0062FF"
                strokeWidth={2}
              />
              <Line
                type="monotone"
                dataKey="prevsale"
                stroke="#3DD598"
                strokeWidth={2}
              />
    
              {/* Add more <Line> components with different dataKey props for each line */}
            </LineChart>
          </ResponsiveContainer>
        </div>
      );
    };
    
    export default MultipleLineGraph;
    
    
  
      
            `;

  const styleString = `
    @import "../../CommonStyle/Variables.scss";
    .sales-figure-wrapper {
      background-color: $light;
      padding: 25px;
      border-radius: 15px;
      gap: 30px;
      .sales-figuer-header {
        .figure-title {
          font-size: $font-size-16px;
          line-height: $line-height-24px;
          font-weight: $font-weight-bold;
        }
    
        .cases-tags-wrapper {
          .cases-tags {
            .tag-title {
              font-size: $font-size-14px;
              line-height: $line-height-24px;
              font-weight: $font-weight-regular;
              color: $dark4f;
            }
          }
        }
      }
    
      .recharts-cartesian-axis-line {
        display: none;
      }
      .recharts-cartesian-axis-tick-line {
        display: none;
      }
    
      .recharts-tooltip-wrapper {
        border: none !important;
        outline: none !important;
        background-image: url("../../assets/images/Shape.png") !important;
        background-repeat: no-repeat;
        background-size: contain;
        background-size: 100% 100%;
        padding: 10px;
        min-width: 115px !important;
        .recharts-default-tooltip {
          border: none !important;
          // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
          // border-radius: 25px;
          background: none !important;
    
          display: flex;
          flex-direction: column-reverse;
          justify-content: flex-start;
          align-items: flex-start;
    
          .recharts-tooltip-label {
            font-size: $font-size-14px;
            line-height: $line-height-16px;
            color: $gray974 !important;
            font-weight: $font-weight-regular;
          }
    
          .recharts-tooltip-item-list {
            .recharts-tooltip-item-name {
              display: none !important;
            }
            .recharts-tooltip-item-separator {
              display: none;
            }
    
            .recharts-tooltip-item-value {
              color: $dark;
              font-size: $font-size-16px;
              line-height: $line-height-24px;
              font-weight: $font-weight-bold;
            }
            .recharts-tooltip-item-value::before {
              content: "$";
            }
          }
        }
      }
    
      .recharts-line {
        background-image: url("../../assets/images/line.png") !important;
        background-repeat: no-repeat;
        background-size: 100%;
        height: 100%;
        width: 100%;
      }
    }
     
            `;
  const [copy, setCopy] = useState(false);
  const [styleCopy, setStyleCopy] = useState(false);
  const [active, setActive] = useState(1);

  const setActiveId = () => {
    if (active === 1) {
      navigator.clipboard.writeText(codeString);
      setCopy(true);
    }

    if (active === 2) {
      navigator.clipboard.writeText(styleString);
      setCopy(true);
    }
  };

  useEffect(() => {
    setCopy(false);
  }, [active]);

  return (
    <div>
      <div className="">
        <Row className="mx-0 gy-lg-0  gy-3 ">
          <Col lg={5} className="position-relative">
            <div className="custom-position-sticky">
              <MultipleLineGraph />
            </div>
          </Col>

          <Col lg={7} className="d-flex flex-column  gap-4 ">
            <div>
              <div className="d-flex justify-content-between align-items-center  p-3  bg-secondary  br-top">
                <Nav variant="pills" className="d-flex  gap-2 ">
                  <Nav.Item>
                    <Nav.Link
                      eventKey="first"
                      onClick={() => setActive(1)}
                      className={active === 1 ? "active" : ""}
                    >
                        JSX
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="second"
                      onClick={() => setActive(2)}
                      className={active === 2 ? "active" : ""}
                    >
                      CSS
                    </Nav.Link>
                  </Nav.Item>
                </Nav>

                {copy ? (
                  <div className="d-flex gap-2 align-items-center">
                    <CheckOutlined
                      style={{
                        fontSize: "20px",
                        color: "#ffffff",
                      }}
                    />
                    <div
                      className="text-light  cu-pointer"
                      onClick={() => {
                        navigator.clipboard.writeText("").then(() => {
                          setCopy(false);
                        });
                      }}
                    >
                      Copied
                    </div>
                  </div>
                ) : (
                  <div className="d-flex gap-2 align-items-center">
                    <CopyOutlined
                      style={{
                        fontSize: "20px",
                        color: "#ffffff",
                      }}
                    />

                    <div
                      className="text-light  cu-pointer"
                      onClick={setActiveId}
                    >
                      Copy Code
                    </div>
                  </div>
                )}
              </div>

              <div className={`${active === 1 ? "d-block" : "d-none"}`}>
                <SyntaxHighlighter
                  language="javascript"
                  style={atomOneDark}
                  className="p-1   p-sm-2 br-bottom"
                >
                  {codeString}
                </SyntaxHighlighter>
              </div>

              <div className={`${active === 2 ? "d-block" : "d-none"}`}>
                <SyntaxHighlighter
                  language="javascript"
                  style={atomOneDark}
                  className="p-1   p-sm-2"
                >
                  {styleString}
                </SyntaxHighlighter>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default MultilineGraphCodePage;
