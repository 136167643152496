import React, { useState,useEffect } from "react";
import { Col, Row } from "react-bootstrap";

import SyntaxHighlighter from "react-syntax-highlighter";
import { atomOneDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import Order from "../Components/OrderCard/Order";
import {
  StarOutlined,
  StarFilled,
  StarTwoTone,
  CopyOutlined,
  CheckOutlined,
} from "@ant-design/icons";

const OrderCardCodePage = () => {
  const codeString = `
  import React from "react";
  import "./Order.scss";
  
  const Order = () => {
    return (
      <div className="order-wrapper d-flex flex-column align-items-center">
        <div className="order-count">3,456</div>
        <div className="order-title">New Orders</div>
        <div className="d-flex">
          <div className="order-percent">+0.5%</div>
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M8.667 13.3333V2.66667C8.667 2.29848 8.36852 2 8.00033 2C7.63214 2 7.33367 2.29848 7.33367 2.66667V13.3333C7.33367 13.7015 7.63214 14 8.00033 14C8.36852 14 8.667 13.7015 8.667 13.3333Z"
                fill="#92929D"
              />
              <path
                d="M7.9944 3.67906L12.1656 8.43936C12.4082 8.71628 12.8294 8.74406 13.1064 8.50141C13.3833 8.25876 13.4111 7.83756 13.1684 7.56064L8.4951 2.22731C8.22927 1.92395 7.75705 1.92428 7.49166 2.22802L2.83164 7.56135C2.58938 7.83861 2.61776 8.25977 2.89502 8.50203C3.17228 8.74429 3.59344 8.71591 3.8357 8.43865L7.9944 3.67906Z"
                fill="#92929D"
              />
            </svg>
          </div>
        </div>
      </div>
    );
  };
  
  export default Order;
  
        `;

  const styleString = `
  @import "../../CommonStyle/Variables.scss";
  .order-wrapper {
    background-color: $light;
    padding: 30px 40px 30px 40px;
    border-radius: 15px;
    .order-count {
      font-size: $font-size-24px;
      line-height: $line-height-36px;
      color: $gray725;
      font-weight: $font-weight-bold;
      padding-bottom: 5px;
    }
  
    .order-title {
      font-size: $font-size-14px;
      line-height: $line-height-16px;
      color: $gray974;
      font-weight: $font-weight-regular;
      padding-bottom: 21px;
    }
  
    .order-percent {
      font-size: $font-size-16px;
      line-height: $line-height-24px;
      color: $green98;
      font-weight: $font-weight-medium;
    }
  }
  
    
        `;

  const [copy, setCopy] = useState(false);
  const [styleCopy, setStyleCopy] = useState(false);
  const [active, setActive] = useState(1);

  const setActiveId = () => {
    if (active === 1) {
      navigator.clipboard.writeText(codeString);
      setCopy(true);
    }

    if (active === 2) {
      navigator.clipboard.writeText(styleString);
      setCopy(true);
    }
  };

  useEffect(() => {
    setCopy(false);
  }, [active]);

  return (
    <div>
      <Row className="mx-0 gy-lg-0  gy-3 ">
        <Col lg={3} className="position-relative">
          <div className="custom-position-sticky">
            <Order />
          </div>
        </Col>

        <Col lg={9} className="d-flex flex-column  gap-4 ">
        <div>
              <div className="d-flex justify-content-between align-items-center  p-3  bg-secondary  br-top">
                <Nav variant="pills" className="d-flex  gap-2 ">
                  <Nav.Item>
                    <Nav.Link
                      eventKey="first"
                      onClick={() => setActive(1)}
                      className={active === 1 ? "active" : ""}
                    >
                       JSX
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="second"
                      onClick={() => setActive(2)}
                      className={active === 2 ? "active" : ""}
                    >
                      CSS
                    </Nav.Link>
                  </Nav.Item>
                </Nav>

                {copy ? (
                  <div className="d-flex gap-2 align-items-center">
                    <CheckOutlined
                      style={{
                        fontSize: "20px",
                        color: "#ffffff",
                      }}
                    />
                    <div
                      className="text-light  cu-pointer"
                      onClick={() => {
                        navigator.clipboard.writeText("").then(() => {
                          setCopy(false);
                        });
                      }}
                    >
                      Copied
                    </div>
                  </div>
                ) : (
                  <div className="d-flex gap-2 align-items-center">
                    <CopyOutlined
                      style={{
                        fontSize: "20px",
                        color: "#ffffff",
                      }}
                    />

                    <div
                      className="text-light  cu-pointer"
                      onClick={setActiveId}
                    >
                      Copy Code
                    </div>
                  </div>
                )}
              </div>

              <div className={`${active === 1 ? "d-block" : "d-none"}`}>
                <SyntaxHighlighter
                  language="javascript"
                  style={atomOneDark}
                  className="p-1   p-sm-2 br-bottom"
                >
                  {codeString}
                </SyntaxHighlighter>
              </div>

              <div className={`${active === 2 ? "d-block" : "d-none"}`}>
                <SyntaxHighlighter
                  language="javascript"
                  style={atomOneDark}
                  className="p-1   p-sm-2"
                >
                  {styleString}
                </SyntaxHighlighter>
              </div>
            </div>
        </Col>
      </Row>
    </div>
  );
};

export default OrderCardCodePage;
