import React, { useState, useEffect } from "react";

import { Col, Row } from "react-bootstrap";
import SalesCard from "../Components/SalesCard/SalesCard";
import SyntaxHighlighter from "react-syntax-highlighter";
import { atomOneDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import MultipleLineGraph from "../Components/MultipleLineGraph/MultipleLineGraph";
import EarningHistory from "../Components/EarningHistory/EarningHistory";

import {
  StarOutlined,
  StarFilled,
  StarTwoTone,
  CopyOutlined,
  CheckOutlined,
} from "@ant-design/icons";

const SinglLineCodePage = () => {
  const codeString = `
    import React, { useState } from "react";
    import "./earninghistory.scss";
    import { Segmented, Tabs } from "antd";
    import {
      ResponsiveContainer,
      LineChart,
      Line,
      XAxis,
      YAxis,
      CartesianGrid,
      Tooltip,
      Legend,
    } from "recharts";
    
    const EarningHistory = () => {
      const [alignValue, setAlignValue] = useState("Monthly");
    
      const data = [
        { monthly: "JAN", sales: 14000, weekly: "Sat", yearly: "2021" },
        { monthly: "Feb", sales: 9000, weekly: "Sun", yearly: "2022" },
        { monthly: "Mar", sales: 22000, weekly: "Monday", yearly: "2023" },
        { monthly: "Apr", sales: 19000, weekly: "Tuesday", yearly: "2022" },
        { monthly: "May", sales: 6500, weekly: "Wednesday", yearly: "2020" },
        { monthly: "Jun", sales: 27632, weekly: "Thursday", yearly: "2018" },
        { monthly: "July", sales: 1200, yearly: "2023", weekly: "Friday" },
        { monthly: "Aug", sales: 0, yearly: "2023", weekly: "Saturday" },
        { monthly: "Sept", sales: 1400, yearly: "2023", weekly: "Sunday" },
        { monthly: "Oct", sales: 6000, yearly: "2023", weekly: "Monday" },
        { monthly: "Nov", sales: 7000, yearly: "2023", weekly: "Tuesday" },
        { monthly: "Decmb", sales: 9500, yearly: "2023", weekly: "Wednesday" },
      ];
    
      const getDataKey = () => {
        switch (alignValue) {
          case "Weekly":
            return "weekly";
          case "Monthly":
            return "monthly";
          case "Yearly":
            return "yearly"; // Assuming you have a "yearly" property in your data
          default:
            return "monthly";
        }
      };
    
      return (
        <div className="earning-card-wrapper">
          <div className="d-flex flex-column gap-3">
            <div className="card-header d-flex justify-content-between align-items-center">
              <div className="card-title">Earnings History</div>
    
              <div>
                <Segmented
                  defaultValue={alignValue}
                  style={{ marginBottom: 8 }}
                  onChange={(value) => setAlignValue(value)}
                  options={["Weekly", "Monthly", "Yearly"]}
                />
              </div>
            </div>
    
            <ResponsiveContainer width="100%" aspect={3}>
              <LineChart width={600} height={300} data={data}>
                <CartesianGrid
                  stroke="#ccc"
                  strokeDasharray="1 "
                  vertical="true"
                  horizontal=""
                  // strokeLinecap="dash"
                />
                <XAxis dataKey={getDataKey()} tickMargin={20} fontSize={12} />
                <YAxis tickMargin={20} fontSize={12} />
                <Tooltip />
    
                <Line
                  type="monotone"
                  dataKey="sales"
                  stroke="#0062FF"
                  strokeWidth={2}
                />
    
                {/* Add more <Line> components with different dataKey props for each line */}
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
      );
    };
    
    export default EarningHistory;
    
    
    
  
      
            `;

  const styleString = `
  @import "../../CommonStyle/Variables.scss";
  .earning-card-wrapper {
    background-color: $light;
    border-radius: 15px;
    padding: 25px;
  
    .card-header {
      .card-title {
        font-size: $font-size-16px;
        line-height: $line-height-24px;
        font-weight: $font-weight-bold;
      }
  
      .ant-segmented {
        background-color: transparent;
        border: 1px solid $darkf15;
      }
  
      .ant-segmented-item-selected {
        background-color: $blue1FF;
        color: $light;
      }
    }
  
    .recharts-cartesian-axis-line {
      display: none;
    }
    .recharts-cartesian-axis-tick-line {
      display: none;
    }
  
    .recharts-tooltip-wrapper {
      border: none !important;
      outline: none !important;
      background-image: url("../../assets/images/Shape.png") !important;
      background-repeat: no-repeat;
      background-size: contain;
      background-size: 100% 100%;
      padding: 10px;
      min-width: 115px !important;
      .recharts-default-tooltip {
        border: none !important;
        // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        // border-radius: 25px;
        background: none !important;
  
        display: flex;
        flex-direction: column-reverse;
        justify-content: flex-start;
        align-items: flex-start;
  
        .recharts-tooltip-label {
          font-size: $font-size-14px;
          line-height: $line-height-16px;
          color: $gray974 !important;
          font-weight: $font-weight-regular;
        }
  
        .recharts-tooltip-item-list {
          .recharts-tooltip-item-name {
            display: none !important;
          }
          .recharts-tooltip-item-separator {
            display: none;
          }
  
          .recharts-tooltip-item-value {
            color: $dark;
            font-size: $font-size-16px;
            line-height: $line-height-24px;
            font-weight: $font-weight-bold;
          }
          .recharts-tooltip-item-value::before {
            content: "$";
          }
        }
      }
    }
  
    .recharts-line {
      background-image: url("../../assets/images/line.png") !important;
      background-repeat: no-repeat;
      background-size: 100%;
      height: 100%;
      width: 100%;
    }
  }
  
     
            `;
  const [copy, setCopy] = useState(false);
  const [styleCopy, setStyleCopy] = useState(false);
  const [active, setActive] = useState(1);

  const setActiveId = () => {
    if (active === 1) {
      navigator.clipboard.writeText(codeString);
      setCopy(true);
    }

    if (active === 2) {
      navigator.clipboard.writeText(styleString);
      setCopy(true);
    }
  };

  useEffect(() => {
    setCopy(false);
  }, [active]);
  return (
    <div>
      <Row className="mx-0 gy-lg-0  gy-3 ">
        <Col lg={5} className="position-relative">
          <div className="custom-position-sticky">
            <EarningHistory />
          </div>
        </Col>

        <Col lg={7} className="d-flex flex-column  gap-4 ">
          <div>
            <div className="d-flex justify-content-between align-items-center  p-3  bg-secondary  br-top">
              <Nav variant="pills" className="d-flex  gap-2 ">
                <Nav.Item>
                  <Nav.Link
                    eventKey="first"
                    onClick={() => setActive(1)}
                    className={active === 1 ? "active" : ""}
                  >
                    JSX
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="second"
                    onClick={() => setActive(2)}
                    className={active === 2 ? "active" : ""}
                  >
                    CSS
                  </Nav.Link>
                </Nav.Item>
              </Nav>

              {copy ? (
                <div className="d-flex gap-2 align-items-center">
                  <CheckOutlined
                    style={{
                      fontSize: "20px",
                      color: "#ffffff",
                    }}
                  />
                  <div
                    className="text-light  cu-pointer"
                    onClick={() => {
                      navigator.clipboard.writeText("").then(() => {
                        setCopy(false);
                      });
                    }}
                  >
                    Copied
                  </div>
                </div>
              ) : (
                <div className="d-flex gap-2 align-items-center">
                  <CopyOutlined
                    style={{
                      fontSize: "20px",
                      color: "#ffffff",
                    }}
                  />

                  <div className="text-light  cu-pointer" onClick={setActiveId}>
                    Copy Code
                  </div>
                </div>
              )}
            </div>

            <div className={`${active === 1 ? "d-block" : "d-none"}`}>
              <SyntaxHighlighter
                language="javascript"
                style={atomOneDark}
                className="p-1   p-sm-2 br-bottom"
              >
                {codeString}
              </SyntaxHighlighter>
            </div>

            <div className={`${active === 2 ? "d-block" : "d-none"}`}>
              <SyntaxHighlighter
                language="javascript"
                style={atomOneDark}
                className="p-1   p-sm-2"
              >
                {styleString}
              </SyntaxHighlighter>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default SinglLineCodePage;
