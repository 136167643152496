import React from "react";
import "./SalesCard.scss";

const SalesCard = () => {
  return (
    <div className="Sales-Card-wrapper d-flex flex-column align-items-start">
      <div className="card-title">Sales</div>
      <div className="sales-ratio d-flex align-items-center">
        <div className="price-title">$27632</div>

        <div className="d-flex align-items-center gap-2">
          <div className="percentge-title">+2.5%</div>

          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M8.66602 13.3333V2.66667C8.66602 2.29848 8.36755 2 7.99936 2C7.63117 2 7.33269 2.29848 7.33269 2.66667V13.3333C7.33269 13.7015 7.63117 14 7.99936 14C8.36755 14 8.66602 13.7015 8.66602 13.3333Z"
                fill="#92929D"
              />
              <path
                d="M7.99343 3.67906L12.1646 8.43936C12.4073 8.71628 12.8285 8.74406 13.1054 8.50141C13.3823 8.25876 13.4101 7.83756 13.1674 7.56064L8.49412 2.22731C8.2283 1.92395 7.75608 1.92428 7.49068 2.22802L2.83066 7.56135C2.5884 7.83861 2.61678 8.25977 2.89404 8.50203C3.17131 8.74429 3.59246 8.71591 3.83472 8.43865L7.99343 3.67906Z"
                fill="#92929D"
              />
            </svg>
          </div>
        </div>
      </div>
      <div className="description">Compared to ($21340 last year)</div>
    </div>
  );
};

export default SalesCard;
