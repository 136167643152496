import React from "react";
import "./BarChart.scss";
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const data = [
  { name: "JAN", onlinesale: 14000, offlinesale: 12000 },
  { name: "Feb", onlinesale: 9000, offlinesale: 11000 },
  { name: "Mar", onlinesale: 22000, offlinesale: 18000 },
  { name: "Apr", onlinesale: 19000, offlinesale: 21000 },
  { name: "May", onlinesale: 6500, offlinesale: 12000 },
  { name: "Jun", onlinesale: 27632, offlinesale: 29000 },
];
const BarChartGraph = () => {
  return (
    <div className="Bar-Chart-Wrapper d-flex flex-column">
      <div className="d-flex flex-column gap-2">
        <div className="  sales-figuer-header  d-flex justify-content-between align-items-center">
          <div className="figure-title">Sales Report</div>

          <div className="cases-tags-wrapper d-flex align-items-center gap-3">
            <div className=" cases-tags  d-flex align-items-center  gap-2">
              <div className="">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="12"
                  viewBox="0 0 10 12"
                  fill="none"
                >
                  <rect width="10" height="12" rx="5" fill="#0062FF" />
                </svg>
              </div>
              <div className="tag-title"> Online Sales</div>
            </div>

            <div className="cases-tags  d-flex align-items-center  gap-2">
              <div className="">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="12"
                  viewBox="0 0 10 12"
                  fill="none"
                >
                  <rect width="10" height="12" rx="5" fill="#3DD598" />
                </svg>
              </div>
              <div className="tag-title"> Offline Sales</div>
            </div>
          </div>
        </div>

        <div className="report-year">2017-2018</div>
      </div>

      <ResponsiveContainer width="100%" aspect={3}>
        <BarChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" vertical="" horizontal="true" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />

          <Bar
            dataKey="offlinesale"
            fill="#3DD598"
            // activeBar={<Rectangle fill="pink" stroke="blue" />}
            barSize={10}
            radius={[5, 5, 0, 0]}
            className="custom-bar"
          />
          <Bar
            dataKey="onlinesale"
            fill="#0062FF"
            // activeBar={<Rectangle fill="gold" stroke="purple" />}
            barSize={10}
            radius={[5, 5, 0, 0]}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default BarChartGraph;
