import React from "react";
import { Avatar, Space } from "antd";
import { DownOutlined, SmileOutlined } from "@ant-design/icons";
import { Dropdown } from "antd";
import "./RecommendedTab.scss";
import Badge from "react-bootstrap/Badge";

const RecommendedTab = () => {
  const ratiodata = [
    {
      ratiotitle: "1 year",
      ratiopercent: "-1.5%",
    },

    {
      ratiotitle: "3 year",
      ratiopercent: "+12.0%",
    },

    {
      ratiotitle: "5 years",
      ratiopercent: "+15.3%",
    },
    {
      ratiotitle: "Cost",
      ratiopercent: "2.1%",
    },
    {
      ratiotitle: "View",
      ratiopercent: "63",
    },
  ];

  const items = [
    {
      key: "1",
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.antgroup.com"
        >
          1st menu item
        </a>
      ),
    },
    {
      key: "2",
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.aliyun.com"
        >
          2nd menu item (disabled)
        </a>
      ),
      icon: <SmileOutlined />,
    },
    {
      key: "3",
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.luohanacademy.com"
        >
          3rd menu item (disabled)
        </a>
      ),
      disabled: true,
    },
    {
      key: "4",
      danger: true,
      label: "a danger item",
    },
  ];
  return (
    <div className="recomended-wrapper d-flex flex-column gap-3">
      <div className="recomended-title">Recommended</div>
      <div className="d-flex flex-column flex-lg-row flex-wrap justify-content-between align-items-center recomended-tab-wrapper gap-3">
        <div className="tab-name-area d-flex align-items-center gap-3">
          <div>
            <Avatar
              shape="square"
              size={38}
              style={{
                backgroundColor: "#0062FF",
              }}
            >
              32
            </Avatar>
          </div>
          <div className="d-flex flex-column gap-2">
            <div className="rec-title">New World Fund 2019</div>

            <div>
              <Dropdown
                menu={{
                  items,
                }}
              >
                <a onClick={(e) => e.preventDefault()} className="font-14">
                  <Space>
                    Industries
                    <DownOutlined />
                  </Space>
                </a>
              </Dropdown>
            </div>
          </div>
        </div>

        <div className="ratio-area  d-flex  gap-1 flex-wrap">
          {ratiodata.map((item, index) => (
            <div className="ratio-item   d-flex flex-column gap-2 px-5 ">
              <div className="font-14 "> {item.ratiotitle}</div>
              <div
                className={` ${
                  item.ratiopercent.includes("-") ? "loss-percent" : "font-16"
                } ${
                  item.ratiopercent.includes("+") ? "profit-percent" : "font-16"
                }`}
              >
                {item.ratiopercent}
              </div>
            </div>
          ))}
        </div>

        <div className=" holding-area  d-flex  align-items-center  gap-4">
          <div>
            <Dropdown
              menu={{
                items,
              }}
            >
              <a onClick={(e) => e.preventDefault()} className="font-14">
                <Space>
                  Holdings
                  <DownOutlined />
                </Space>
              </a>
            </Dropdown>
          </div>

          <div>
            <Badge className="rec-badge">Mid Risk</Badge>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecommendedTab;
