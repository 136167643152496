import React, { useState } from "react";
import "./PhotoVideo.scss";
import { Avatar, Space } from "antd";
import img2 from "../../assets/images/Media 1.svg";

const PhotoVideo = () => {
  const [showAllCustomers, setShowAllCustomers] = useState(false);

  const toggleShowAllCustomers = () => {
    setShowAllCustomers(!showAllCustomers);
  };
  const data = [
    {
      imgsrc: img2,
    },
    {
      imgsrc: img2,
    },
    {
      imgsrc: img2,
    },
    {
      imgsrc: img2,
    },
    {
      imgsrc: img2,
    },
    {
      imgsrc: img2,
    },
    {
      imgsrc: img2,
    },
    {
      imgsrc: img2,
    },

    {
      imgsrc: img2,
    },
    {
      imgsrc: img2,
    },
    {
      imgsrc: img2,
    },
  ];

  return (
    <div className="photo-video-wrapper   d-flex flex-column gap-4 ">
      <div className="d-flex justify-content-between  card-header">
        <div className="title-class">Photos and Videos</div>
        <div
          className="title-badge cu-pointer"
          onClick={toggleShowAllCustomers}
        >
          {showAllCustomers ? "See Less" : "See All"}
        </div>
      </div>

      <div className="photo-gallery  d-flex gap-2 flex-wrap justify-content-center">
        {data
          .slice(0, showAllCustomers ? data.length : 6)
          .map((item, index) => (
            <Avatar shape="square" src={item.imgsrc} size={90} />
          ))}
      </div>
    </div>
  );
};

export default PhotoVideo;
