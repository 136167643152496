import React, { useState, useEffect } from "react";

import { Col, Row } from "react-bootstrap";
import SalesCard from "../Components/SalesCard/SalesCard";
import SyntaxHighlighter from "react-syntax-highlighter";
import { atomOneDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import MultipleLineGraph from "../Components/MultipleLineGraph/MultipleLineGraph";
import SalesLocation from "../Components/SalesLocation/SalesLocation";
import { CopyOutlined, CheckOutlined } from "@ant-design/icons";
import PhotoVideo from "../Components/PhotoVideo/PhotoVideo";

const PhotoVideoCode = () => {
    const codeString = `
    import React, { useState } from "react";
    import "./PhotoVideo.scss";
    import { Avatar, Space } from "antd";
    import img2 from "../../assets/images/Media 1.svg";
    
    const PhotoVideo = () => {
      const [showAllCustomers, setShowAllCustomers] = useState(false);
    
      const toggleShowAllCustomers = () => {
        setShowAllCustomers(!showAllCustomers);
      };
      const data = [
        {
          imgsrc: img2,
        },
        {
          imgsrc: img2,
        },
        {
          imgsrc: img2,
        },
        {
          imgsrc: img2,
        },
        {
          imgsrc: img2,
        },
        {
          imgsrc: img2,
        },
        {
          imgsrc: img2,
        },
        {
          imgsrc: img2,
        },
    
        {
          imgsrc: img2,
        },
        {
          imgsrc: img2,
        },
        {
          imgsrc: img2,
        },
      ];
    
      return (
        <div className="photo-video-wrapper   d-flex flex-column gap-4 ">
          <div className="d-flex justify-content-between  card-header">
            <div className="title-class">Photos and Videos</div>
            <div
              className="title-badge cu-pointer"
              onClick={toggleShowAllCustomers}
            >
              {showAllCustomers ? "See Less" : "See All"}
            </div>
          </div>
    
          <div className="photo-gallery  d-flex gap-2 flex-wrap">
            {data
              .slice(0, showAllCustomers ? data.length : 6)
              .map((item, index) => (
                <Avatar shape="square" src={item.imgsrc} size={90} />
              ))}
          </div>
        </div>
      );
    };
    
    export default PhotoVideo;    
        
                `;

  const styleString = `
  @import "../../CommonStyle/Variables.scss";

  .photo-video-wrapper {
    background-color: $light;
    border-radius: 15px;
    padding: 15px 0px;
  
    .card-header {
      padding-bottom: 15px;
      border-bottom: 1px solid $darkf15;
  
      .title-class {
        font-size: $font-size-16px;
        line-height: $line-height-24px;
        font-weight: $font-weight-bold;
        color: $dark75;
        padding-left: 20px;
      }
  
      .title-badge {
        font-size: $font-size-16px;
        line-height: $line-height-24px;
        font-weight: $font-weight-bold;
        color: $blue1FF;
        padding-right: 20px;
        
      }
    }
  
    .photo-gallery {
      padding-left: 25px;
      padding-right: 25px;
    }
  }
  
                `;
  const [copy, setCopy] = useState(false);
  const [styleCopy, setStyleCopy] = useState(false);
  const [active, setActive] = useState(1);

  const setActiveId = () => {
    if (active === 1) {
      navigator.clipboard.writeText(codeString);
      setCopy(true);
    }

    if (active === 2) {
      navigator.clipboard.writeText(styleString);
      setCopy(true);
    }
  };

  useEffect(() => {
    setCopy(false);
  }, [active]);
  return (
    <div className="">
      <Row className="mx-0 gy-lg-0  gy-3 ">
        <Col lg={4} className="position-relative">
          <div className="custom-position-sticky">
            <PhotoVideo />
          </div>
        </Col>

        <Col lg={8} className="d-flex flex-column  gap-4 ">
          <div>
            <div className="d-flex justify-content-between align-items-center  p-3  bg-secondary  br-top">
              <Nav variant="pills" className="d-flex  gap-2 ">
                <Nav.Item>
                  <Nav.Link
                    eventKey="first"
                    onClick={() => setActive(1)}
                    className={active === 1 ? "active" : ""}
                  >
                    JSX
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="second"
                    onClick={() => setActive(2)}
                    className={active === 2 ? "active" : ""}
                  >
                    CSS
                  </Nav.Link>
                </Nav.Item>
              </Nav>

              {copy ? (
                <div className="d-flex gap-2 align-items-center">
                  <CheckOutlined
                    style={{
                      fontSize: "20px",
                      color: "#ffffff",
                    }}
                  />
                  <div
                    className="text-light  cu-pointer"
                    onClick={() => {
                      navigator.clipboard.writeText("").then(() => {
                        setCopy(false);
                      });
                    }}
                  >
                    Copied
                  </div>
                </div>
              ) : (
                <div className="d-flex gap-2 align-items-center">
                  <CopyOutlined
                    style={{
                      fontSize: "20px",
                      color: "#ffffff",
                    }}
                  />

                  <div className="text-light  cu-pointer" onClick={setActiveId}>
                    Copy Code
                  </div>
                </div>
              )}
            </div>

            <div className={`${active === 1 ? "d-block" : "d-none"}`}>
              <SyntaxHighlighter
                language="javascript"
                style={atomOneDark}
                className="p-1   p-sm-2 br-bottom"
              >
                {codeString}
              </SyntaxHighlighter>
            </div>

            <div className={`${active === 2 ? "d-block" : "d-none"}`}>
              <SyntaxHighlighter
                language="javascript"
                style={atomOneDark}
                className="p-1   p-sm-2"
              >
                {styleString}
              </SyntaxHighlighter>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default PhotoVideoCode
