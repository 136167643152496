import React, { useState, useEffect } from "react";

import { Col, Row } from "react-bootstrap";
import SalesCard from "../Components/SalesCard/SalesCard";
import SyntaxHighlighter from "react-syntax-highlighter";
import { atomOneDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import MultipleLineGraph from "../Components/MultipleLineGraph/MultipleLineGraph";

import {
  StarOutlined,
  StarFilled,
  StarTwoTone,
  CopyOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import PendingCard from "../Components/PendingComponent/PendingCard";
import LogoDesignPicCard from "../Components/LogoDesignPicCard/LogoDesignPicCard";

const LodoDesignPicCode = () => {
  const codeString = `
    import React from "react";
    import "./LogoDesignPicCard.scss";
    import img1 from "../../assets/images/logoimg1.png";
    import img2 from "../../assets/images/logoimg2.png";
    
    import { Avatar, Space } from "antd";
    
    import { useState } from "react";
    import { Col, Row } from "react-bootstrap";
    
    const LogoDesignPicCard = () => {
      return (
        <div className="logo-pic-card-wrapper">
          <div className=" card-header   d-flex justify-content-between  align-items-center">
            <div className="title-class">Square - New Logo design</div>
            <div className="time-class">1:54pm</div>
          </div>
    
          <div className="d-flex flex-column gap-4">
            <div className="d-flex  align-items-center  gap-2 pic-desc-area">
              <Avatar style={{ backgroundColor: "#A461D8" }}>RR</Avatar>
    
              <div className="d-flex flex-column gap-1">
                <div className="status-title">Sam Tin uploaded a new photo </div>
    
                <div className="pic-headers">Header Illustration #Exploration</div>
              </div>
            </div>
    
            <Row className="mx-0">
              <Col md={6}>
                <div className="img-otr">
                  <img src={img1} className="img-inr"></img>
                </div>
              </Col>
              <Col md={6}>
                <div className="img-otr">
                  <img src={img2} className="img-inr"></img>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      );
    };
    
    export default LogoDesignPicCard;
    
      
    
        
              `;

  const styleString = `
    @import "../../CommonStyle/Variables.scss";
    .logo-pic-card-wrapper {
      background-color: $light;
      padding: 20px 40px 25px 20px;
      border-radius: 15px;
      gap: 20px;
    
      .pic-desc-area {
        padding-top: 20px;
      }
    
      .status-title {
        font-size: $font-size-16px;
        line-height: $line-height-26px;
        color: $gray44f;
        font-weight: $font-weight-bold;
      }
    
      .pic-headers {
        font-size: $font-size-16px;
        line-height: $line-height-26px;
        color: $blue2ff;
        font-weight: $font-weight-bold;
      }
      .img-otr {
        height: 146px;
    
        .img-inr {
          width: 100%;
          height: 100%;
          object-fit: inherit;
        }
      }
    }
    
    
       
              `;
  const [copy, setCopy] = useState(false);
  const [styleCopy, setStyleCopy] = useState(false);
  const [active, setActive] = useState(1);

  const setActiveId = () => {
    if (active === 1) {
      navigator.clipboard.writeText(codeString);
      setCopy(true);
    }

    if (active === 2) {
      navigator.clipboard.writeText(styleString);
      setCopy(true);
    }
  };

  useEffect(() => {
    setCopy(false);
  }, [active]);
  return (
    <div>
      <div className="">
        <Row className="mx-0 gy-lg-0  gy-3 ">
          <Col lg={5} className="position-relative">
            <div className="custom-position-sticky">
              <LogoDesignPicCard />
            </div>
          </Col>

          <Col lg={7} className="d-flex flex-column  gap-4 ">
            <div>
              <div className="d-flex justify-content-between align-items-center  p-3  bg-secondary  br-top">
                <Nav variant="pills" className="d-flex  gap-2 ">
                  <Nav.Item>
                    <Nav.Link
                      eventKey="first"
                      onClick={() => setActive(1)}
                      className={active === 1 ? "active" : ""}
                    >
                      JSX
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="second"
                      onClick={() => setActive(2)}
                      className={active === 2 ? "active" : ""}
                    >
                      CSS
                    </Nav.Link>
                  </Nav.Item>
                </Nav>

                {copy ? (
                  <div className="d-flex gap-2 align-items-center">
                    <CheckOutlined
                      style={{
                        fontSize: "20px",
                        color: "#ffffff",
                      }}
                    />
                    <div
                      className="text-light  cu-pointer"
                      onClick={() => {
                        navigator.clipboard.writeText("").then(() => {
                          setCopy(false);
                        });
                      }}
                    >
                      Copied
                    </div>
                  </div>
                ) : (
                  <div className="d-flex gap-2 align-items-center">
                    <CopyOutlined
                      style={{
                        fontSize: "20px",
                        color: "#ffffff",
                      }}
                    />

                    <div
                      className="text-light  cu-pointer"
                      onClick={setActiveId}
                    >
                      Copy Code
                    </div>
                  </div>
                )}
              </div>

              <div className={`${active === 1 ? "d-block" : "d-none"}`}>
                <SyntaxHighlighter
                  language="javascript"
                  style={atomOneDark}
                  className="p-1   p-sm-2 br-bottom"
                >
                  {codeString}
                </SyntaxHighlighter>
              </div>

              <div className={`${active === 2 ? "d-block" : "d-none"}`}>
                <SyntaxHighlighter
                  language="javascript"
                  style={atomOneDark}
                  className="p-1   p-sm-2"
                >
                  {styleString}
                </SyntaxHighlighter>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default LodoDesignPicCode;
