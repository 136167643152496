import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";

import SyntaxHighlighter from "react-syntax-highlighter";
import { atomOneDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import Order from "../Components/OrderCard/Order";
import VisitorSlider from "../Components/VisitorSlider/VisitorSlider";
import {
  StarOutlined,
  StarFilled,
  StarTwoTone,
  CopyOutlined,
  CheckOutlined,
} from "@ant-design/icons";

const VisitorSliderCodePage = () => {
  const codeString = `
  import React from "react";
  import "./VisitorSlider.scss";
  import { Swiper, SwiperSlide } from "swiper/react";
  
  import "swiper/css";
  import "swiper/css/navigation";
  import "swiper/css/pagination";
  import { Navigation } from "swiper/modules";
  import { Flex, Progress } from "antd";
  import { Row, Col } from "react-bootstrap";
  
  const VisitorSlider = () => {
    const conicColors = {
      "0%": "#0062FF",
      "50%": "#FFC542",
      "65%": "#FF974A",
      "100%": "#FFC542",
    };
  
    const visitordata = [
      {
        visityear: "2017",
        am: "2.1",
        al: "1",
        Eb: "1.9",
        sp: "15.7",
        totalvisit: "22,870",
      },
      {
        visityear: "2018",
        am: "1",
        al: "1",
        Eb: "2.9",
        sp: "15.7",
        totalvisit: "19,000",
      },
  
      {
        visityear: "2019",
        am: "1",
        al: "1",
        Eb: "1.9",
        sp: "7.7",
        totalvisit: "15,000",
      },
    ];
    return (
      <div className="visitorSlider-Wrapper">
        <div className="d-flex flex-column align-items-center  gap-4 ">
          <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
            {visitordata.map((item, index) => (
              <SwiperSlide key={index}>
                <div className="d-flex flex-column align-items-center justify-content-center gy-4  gap-4">
                  <span className="fw-bold">{item.visityear}</span>
  
                  <div>
                    {" "}
                    <Progress
                      type="circle"
                      percent={100}
                      strokeColor={conicColors}
                      size={(180, 180)}
                      format={() => (
                        <div className="total-sales  d-flex flex-column gap-2">
                          <div className="sale-title">{item.totalvisit}</div>
                          <div className="sale-desc fs-14">
                            Visitors this year
                          </div>
                        </div>
                      )}
                    ></Progress>
                  </div>
  
                  <Row className="mx-0  gy-3">
                    <Col sm={6}>
                      <div className="d-flex align-items-center gap-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          height="12"
                          viewBox="0 0 10 12"
                          fill="none"
                        >
                          <rect width="10" height="12" rx="5" fill="#0062FF" />
                        </svg>
  
                        <div className="badge-title">
                          Amazone <span className="fw-bold">{item.am}k</span>
                        </div>
                      </div>
                    </Col>
  
                    <Col sm={6}>
                      <div className="d-flex align-items-center gap-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          height="12"
                          viewBox="0 0 10 12"
                          fill="none"
                        >
                          <rect width="10" height="12" rx="5" fill="#FF974A" />
                        </svg>
  
                        <div className="badge-title">
                          ALibaba <span className="fw-bold">{item.al}k</span>
                        </div>
                      </div>
                    </Col>
  
                    <Col sm={6}>
                      <div className="d-flex align-items-center gap-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          height="12"
                          viewBox="0 0 10 12"
                          fill="none"
                        >
                          <rect width="10" height="12" rx="5" fill="#3DD598" />
                        </svg>
  
                        <div className="badge-title">
                          Ebay <span className="fw-bold">{item.Eb}k</span>
                        </div>
                      </div>
                    </Col>
  
                    <Col sm={6}>
                      <div className="d-flex align-items-center gap-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          height="12"
                          viewBox="0 0 10 12"
                          fill="none"
                        >
                          <rect width="10" height="12" rx="5" fill="#FFC542" />
                        </svg>
  
                        <div className="badge-title">
                          Shopify <span className="fw-bold">{item.sp}k</span>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    );
  };
  
  export default VisitorSlider;
  
    
          `;

  const styleString = `
  @import "../../CommonStyle/Variables.scss";
  .visitorSlider-Wrapper {
    background-color: $light;
    border-radius: 15px;
    padding: 24px 25px 30px 25px;
  
    .badge-title {
      font-size: $font-size-14px;
      line-height: $line-height-24px;
      font-weight: $font-weight-regular;
      color: $gray44f;
    }
  
    .total-sales {
      .sale-title {
        font-size: $font-size-28px;
        line-height: $line-height-42px;
        color: $dark75;
        font-weight: $font-weight-bold;
      }
    }
  
    .swiper {
      width: 100%;
      height: 100%;
  
      .swiper-slide {
        text-align: center;
        font-size: 18px;
        background: #fff;
  
        /* Center slide text vertically */
        display: flex;
        justify-content: center;
        align-items: center;
      }
  
      .swiper-button-next {
        top: 12px;
      }
  
      .swiper-button-prev {
        top: 12px;
      }
      .swiper-button-next:after {
        color: $gray29d;
        font-size: $font-size-14px;
        font-weight: $font-weight-bold;
      }
      .swiper-button-prev:after {
        color: $gray29d;
        font-size: $font-size-14px;
        font-weight: $font-weight-bold;
      }
  
      // .swiper-button-next:after:hover {
      //   color: $blue1FF !important;
      // }
    }
  }
  
    
      
          `;

  const [copy, setCopy] = useState(false);
  const [styleCopy, setStyleCopy] = useState(false);
  const [active, setActive] = useState(1);

  const setActiveId = () => {
    if (active === 1) {
      navigator.clipboard.writeText(codeString);
      setCopy(true);
    }

    if (active === 2) {
      navigator.clipboard.writeText(styleString);
      setCopy(true);
    }
  };

  useEffect(() => {
    setCopy(false);
  }, [active]);

  return (
    <div>
      <Row className="mx-0 gy-lg-0  gy-3 ">
        <Col lg={3} className="position-relative">
          <div className="custom-position-sticky">
            <VisitorSlider />
          </div>
        </Col>

        <Col lg={9} className="d-flex flex-column  gap-4 ">
          <div>
            <div className="d-flex justify-content-between align-items-center  p-3  bg-secondary  br-top">
              <Nav variant="pills" className="d-flex  gap-2 ">
                <Nav.Item>
                  <Nav.Link
                    eventKey="first"
                    onClick={() => setActive(1)}
                    className={active === 1 ? "active" : ""}
                  >
                    HTML
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="second"
                    onClick={() => setActive(2)}
                    className={active === 2 ? "active" : ""}
                  >
                    CSS
                  </Nav.Link>
                </Nav.Item>
              </Nav>

              {copy ? (
                <div className="d-flex gap-2 align-items-center">
                  <CheckOutlined
                    style={{
                      fontSize: "20px",
                      color: "#ffffff",
                    }}
                  />
                  <div
                    className="text-light  cu-pointer"
                    onClick={() => {
                      navigator.clipboard.writeText("").then(() => {
                        setCopy(false);
                      });
                    }}
                  >
                    Copied
                  </div>
                </div>
              ) : (
                <div className="d-flex gap-2 align-items-center">
                  <CopyOutlined
                    style={{
                      fontSize: "20px",
                      color: "#ffffff",
                    }}
                  />

                  <div className="text-light  cu-pointer" onClick={setActiveId}>
                    Copy Code
                  </div>
                </div>
              )}
            </div>

            <div className={`${active === 1 ? "d-block" : "d-none"}`}>
              <SyntaxHighlighter
                language="javascript"
                style={atomOneDark}
                className="p-1   p-sm-2 br-bottom"
              >
                {codeString}
              </SyntaxHighlighter>
            </div>

            <div className={`${active === 2 ? "d-block" : "d-none"}`}>
              <SyntaxHighlighter
                language="javascript"
                style={atomOneDark}
                className="p-1   p-sm-2"
              >
                {styleString}
              </SyntaxHighlighter>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default VisitorSliderCodePage;
