import React from "react";
import "./AverageSaleCard.scss";
import { Flex, Progress } from "antd";

const AverageSaleCard = () => {
  return (
    <div className="average-sale-card-wrapper">
      <div className=" card-header d-flex flex-column gap-2 flex-wrap">
        <div className="card-title">Average Total Sales</div>
        <div className="record-year">2017-2018</div>
      </div>

      <div className="progress-area">
        <div className="cards-lists  d-flex flex-column flex-md-row align-items-center align-items-md-center  flex-wrap">
          <div className="progress-card  d-flex align-items-center  gap-4">
            <div className="progress-circle-otr">
              <Progress
                type="circle"
                percent={46}
                strokeColor="
              #0062FF"
                className="progess-circle"
                size={[70, 70]}
              />
            </div>
            <div className=" card-title-section  d-flex flex-column align-items-start">
              <div className="card-percent">92,980</div>
              <div className="card-desc">Cases</div>
            </div>
          </div>

          <div className="progress-card  d-flex align-items-center  gap-4">
            <div className="progress-circle-otr">
              <Progress
                type="circle"
                percent={74}
                strokeColor="
              
#3DD598"
                className="progess-circle"
                size={[70, 70]}
              />
            </div>
            <div className=" card-title-section  d-flex flex-column align-items-start">
              <div className="card-percent">28,546</div>
              <div className="card-desc">Applications</div>
            </div>
          </div>

          <div className="progress-card  d-flex align-items-center  gap-4">
            <div className="progress-circle-otr">
              <Progress
                type="circle"
                percent={14}
                strokeColor="
                #FF974A"
                className="progess-circle"
                size={[70, 70]}
              />
            </div>
            <div className=" card-title-section  d-flex flex-column align-items-start">
              <div className="card-percent">14,008</div>
              <div className="card-desc">Products</div>
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex  gap-2 py-4">
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              d="M7.33398 5.33325V8.66659C7.33398 9.03478 7.63246 9.33325 8.00065 9.33325C8.36884 9.33325 8.66732 9.03478 8.66732 8.66659V5.33325C8.66732 4.96506 8.36884 4.66659 8.00065 4.66659C7.63246 4.66659 7.33398 4.96506 7.33398 5.33325Z"
              fill="#92929D"
            />
            <path
              d="M8.66732 10.6666C8.66732 11.0348 8.36884 11.3333 8.00065 11.3333C7.63246 11.3333 7.33398 11.0348 7.33398 10.6666C7.33398 10.2984 7.63246 9.99992 8.00065 9.99992C8.36884 9.99992 8.66732 10.2984 8.66732 10.6666Z"
              fill="#92929D"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M14.6673 7.99992C14.6673 11.6818 11.6826 14.6666 8.00065 14.6666C4.31875 14.6666 1.33398 11.6818 1.33398 7.99992C1.33398 4.31802 4.31875 1.33325 8.00065 1.33325C11.6826 1.33325 14.6673 4.31802 14.6673 7.99992ZM2.66732 7.99992C2.66732 10.9454 5.05513 13.3333 8.00065 13.3333C10.9462 13.3333 13.334 10.9454 13.334 7.99992C13.334 5.0544 10.9462 2.66659 8.00065 2.66659C5.05513 2.66659 2.66732 5.0544 2.66732 7.99992Z"
              fill="#92929D"
            />
          </svg>
        </div>

        <div className="card-desc">
          Net sales, found on the income statement are used to calculate this
          ration returns and refunds must be backed out of total sales to
          measure the truly measure the firm’s assets abillity to generate
          sales.
        </div>
      </div>
    </div>
  );
};

export default AverageSaleCard;
