import React from "react";
import "./Order.scss";

const Order = () => {
  return (
    <div className="order-wrapper d-flex flex-column align-items-center">
      <div className="order-count">3,456</div>
      <div className="order-title">New Orders</div>
      <div className="d-flex">
        <div className="order-percent">+0.5%</div>
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              d="M8.667 13.3333V2.66667C8.667 2.29848 8.36852 2 8.00033 2C7.63214 2 7.33367 2.29848 7.33367 2.66667V13.3333C7.33367 13.7015 7.63214 14 8.00033 14C8.36852 14 8.667 13.7015 8.667 13.3333Z"
              fill="#92929D"
            />
            <path
              d="M7.9944 3.67906L12.1656 8.43936C12.4082 8.71628 12.8294 8.74406 13.1064 8.50141C13.3833 8.25876 13.4111 7.83756 13.1684 7.56064L8.4951 2.22731C8.22927 1.92395 7.75705 1.92428 7.49166 2.22802L2.83164 7.56135C2.58938 7.83861 2.61776 8.25977 2.89502 8.50203C3.17228 8.74429 3.59344 8.71591 3.8357 8.43865L7.9944 3.67906Z"
              fill="#92929D"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default Order;
