import React, { useState } from "react";
import "./RunProject.scss";
import ProjectPreview from "../ProjectPreview/ProjectPreview";
import img from "../../assets/images/runproject.png";
import { Avatar, Divider, Tooltip } from "antd";
import { AntDesignOutlined, UserOutlined } from "@ant-design/icons";
import img2 from "../../assets/images/PP.png";
import Button from "react-bootstrap/Button";

const projectsdata = [
  {
    projecttitle: "Product Preview & Mock up for Marke…",
    projectteam: "Iconspace Team",
    fileattach: 13,
    phaseratio: "4/5",
    daysleft: "2",
    completestatus: 85,
    teammembers: [img2, img2, img2, img2, img2],
    projectimg: img,
  },

  {
    projecttitle: "Product Preview & Mock up for Marke…",
    projectteam: "Iconspace Team",
    fileattach: 13,
    phaseratio: "4/5",
    daysleft: "2",
    completestatus: 85,
    teammembers: [img2, img2, img2, img2, img2],
    projectimg: img,
  },

  // {
  //   projecttitle: "Product Preview & Mock up for Marke…",
  //   projectteam: "Iconspace Team",
  //   fileattach: 13,
  //   phaseratio: "4/5",
  //   daysleft: "2",
  //   completestatus: 85,
  //   teammembers: [img2, img2, img2, img2, img2],
  //   projectimg: img,
  // },

  // {
  //   projecttitle: "Product Preview & Mock up for Marke…",
  //   projectteam: "Iconspace Team",
  //   fileattach: 13,
  //   phaseratio: "4/5",
  //   daysleft: "2",
  //   completestatus: 85,
  //   teammembers: [img2, img2, img2, img2, img2],
  //   projectimg: null,
  // },
];

const RunProject = () => {
  const [showAllProjects, setShowAllProjects] = useState(false);
  const toggleShowAllProjects = () => {
    setShowAllProjects(!showAllProjects);
  };
  return (
    <div className="Run-Card-Wrapper  d-flex flex-column gap-3">
      <div className=" run-card-header   d-flex justify-content-between align-items-center">
        <div className="run-card-title">Run</div>
        <div className="cu-pointer">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M7 12C7 10.8954 6.10457 10 5 10C3.89543 10 3 10.8954 3 12C3 13.1046 3.89543 14 5 14C6.10457 14 7 13.1046 7 12Z"
              fill="#92929D"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14C13.1046 14 14 13.1046 14 12Z"
              fill="#92929D"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M21 12C21 10.8954 20.1046 10 19 10C17.8954 10 17 10.8954 17 12C17 13.1046 17.8954 14 19 14C20.1046 14 21 13.1046 21 12Z"
              fill="#92929D"
            />
          </svg>
        </div>
      </div>

      <div className="d-flex flex-column gap-3 run-project-inr-card">
        <div className="d-flex flex-column gap-2">
          <ProjectPreview
            projectsdata={
              showAllProjects
                ? projectsdata
                : projectsdata.length > 1
                ? projectsdata.slice(0, 1)
                : projectsdata
            }
          />
        </div>
      </div>

      <div className="toggle-icon-btn-otr">
        <Button
          variant="outline-secondary"
          className="toggle-icon-btn"
          onClick={toggleShowAllProjects}
        >
          {showAllProjects ? "-" : "+"}
        </Button>{" "}
      </div>
    </div>
  );
};

export default RunProject;
