import React, { useState, useEffect } from "react";

import { Col, Row } from "react-bootstrap";
import SalesCard from "../Components/SalesCard/SalesCard";
import SyntaxHighlighter from "react-syntax-highlighter";
import { atomOneDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import MultipleLineGraph from "../Components/MultipleLineGraph/MultipleLineGraph";
import SalesLocation from "../Components/SalesLocation/SalesLocation";
import { CopyOutlined, CheckOutlined } from "@ant-design/icons";
import PhotoVideo from "../Components/PhotoVideo/PhotoVideo";
import LikePage from "../Components/LikePage/LikePage";
import PubgCard from "../Components/PubgCard/PubgCard";
import NotificationCard from "../Components/NotificationCard/NotificationCard";
import EmailFilterForm from "../Components/EmailFilter/EmailFilterForm";

const EmailFilterCode = () => {
  const codeString = `
  import React from "react";
  import "./EmailFilterForm.scss";
  import Button from "react-bootstrap/Button";
  import Form from "react-bootstrap/Form";
  import Row from "react-bootstrap/Row";
  import Col from "react-bootstrap/Col";
  
  const EmailFilterForm = () => {
    return (
      <div className="email-filter-wrapper">
        <Form className="filter-form-wrapper">
          <fieldset>
            <Form.Group className="mb-3">
              <Form.Label className="custom-form-label">Search</Form.Label>
              <Form.Select id="select" className="custom-form-select">
                <option>Weekly Mails</option>
                <option>Today</option>
                <option>All Mails</option>
              </Form.Select>
            </Form.Group>
  
            <Form.Group className="mb-3">
              <Form.Label className="custom-form-label">From</Form.Label>
              <Form.Select id="select" className="custom-form-select">
                <option>ozi@sebostudio.com</option>
                <option>test@zeepalm.com</option>
              </Form.Select>
            </Form.Group>
  
            <Form.Group className="mb-3">
              <Form.Label className="custom-form-label">To</Form.Label>
              <Form.Select id="select" className="custom-form-select">
                <option>ozi@sebostudio.com</option>
                <option>test@zeepalm.com</option>
              </Form.Select>
            </Form.Group>
  
            <Form.Group className="mb-3">
              <Form.Label className="custom-form-label">Keyword</Form.Label>
              <Form.Select id="select" className="custom-form-select">
                <option>Project</option>
                <option>Docs</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" as={Row}>
              <Form.Group
                as={Col}
                sm="6"
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label className="custom-form-label">Password</Form.Label>
                <Col>
                  <Form.Control
                    type="date"
                    placeholder=""
                    className="custom-form-input"
                  />
                </Col>
              </Form.Group>
  
              <Form.Group
                as={Col}
                sm="6"
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label className="custom-form-label">Password</Form.Label>
                <Col>
                  <Form.Control
                    type="date"
                    placeholder=""
                    className="custom-form-input"
                  />
                </Col>
              </Form.Group>
            </Form.Group>
  
            <div className="d-flex gap-3 align-items-center">
              <Form.Group className="mb-3">
                <Form.Check
                  type="checkbox"
                  id="disabledFieldsetCheck"
                  label="Has Attachment"
                  className="custom-check-label  d-flex justify-content-center align-items-center gap-2"
                />
              </Form.Group>
  
              <Form.Group className="mb-3">
                <Form.Check
                  type="checkbox"
                  id="disabledFieldsetCheck"
                  label="Chat only"
                  className="custom-check-label d-flex justify-content-center align-items-center gap-2"
                />
              </Form.Group>
            </div>
  
            <div className="d-flex justify-content-end gap-3 align-items-center">
              <Button
                type="submit"
                variant="outline-light"
                className="custom-outline-btn"
              >
                Cancel
              </Button>
              <Button type="submit" className="custom-secondary-btn">
                Submit
              </Button>
            </div>
          </fieldset>
        </Form>
      </div>
    );
  };
  
  export default EmailFilterForm;
  
          
                    `;

  const styleString = `
  @import "../../CommonStyle/Variables.scss";
  .email-filter-wrapper {
    background-color: $light;
    border-radius: 15px;
    padding: 63px 20px 20px 20px;
  
    .filter-form-wrapper {
      .custom-form-label {
        font-size: $font-size-12px;
        line-height: $line-height-12px;
        font-weight: $font-weight-regular;
        color: $gray29d;
      }
  
      .custom-form-select {
        outline: none !important;
        border: none;
        border-bottom: 1px solid $lightea !important;
        font-size: $font-size-14px;
        line-height: $line-height-16px;
        color: $gray44f;
        font-weight: $font-weight-semibold;
      }
  
      .custom-form-input {
        outline: none !important;
        border: none;
        border-bottom: 1px solid $lightea !important;
        font-size: $font-size-14px;
        line-height: $line-height-16px;
        color: $gray44f;
        font-weight: $font-weight-semibold;
        border-radius: 0px !important;
      }
  
      .custom-check-label {
        font-size: $font-size-12px;
        line-height: $line-height-12px;
        font-weight: $font-weight-semibold;
        color: $gray44f;
      }
  
      .custom-outline-btn {
        border: 1px solid $lightea !important;
        font-size: $font-size-12px;
        line-height: $line-height-16px;
        font-weight: $font-weight-semibold;
        padding: 10px 30px;
        border-radius: 15px;
        color: $gray974;
      }
  
      .custom-secondary-btn {
        font-size: $font-size-12px;
        line-height: $line-height-16px;
        font-weight: $font-weight-semibold;
        color: $light;
        padding: 10px 30px;
        border-radius: 15px;
      }
    }
  }
  
                    `;
  const [copy, setCopy] = useState(false);

  const [active, setActive] = useState(1);

  const setActiveId = () => {
    if (active === 1) {
      navigator.clipboard.writeText(codeString);
      setCopy(true);
    }

    if (active === 2) {
      navigator.clipboard.writeText(styleString);
      setCopy(true);
    }
  };

  useEffect(() => {
    setCopy(false);
  }, [active]);
  return (
    <div className="">
      <Row className="mx-0 gy-lg-0  gy-3 ">
        <Col lg={4} className="position-relative">
          <div className="custom-position-sticky">
            <EmailFilterForm />
          </div>
        </Col>

        <Col lg={8} className="d-flex flex-column  gap-4 ">
          <div>
            <div className="d-flex justify-content-between align-items-center  p-3  bg-secondary  br-top">
              <Nav variant="pills" className="d-flex  gap-2 ">
                <Nav.Item>
                  <Nav.Link
                    eventKey="first"
                    onClick={() => setActive(1)}
                    className={active === 1 ? "active" : ""}
                  >
                    JSX
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="second"
                    onClick={() => setActive(2)}
                    className={active === 2 ? "active" : ""}
                  >
                    CSS
                  </Nav.Link>
                </Nav.Item>
              </Nav>

              {copy ? (
                <div className="d-flex gap-2 align-items-center">
                  <CheckOutlined
                    style={{
                      fontSize: "20px",
                      color: "#ffffff",
                    }}
                  />
                  <div
                    className="text-light  cu-pointer"
                    onClick={() => {
                      navigator.clipboard.writeText("").then(() => {
                        setCopy(false);
                      });
                    }}
                  >
                    Copied
                  </div>
                </div>
              ) : (
                <div className="d-flex gap-2 align-items-center">
                  <CopyOutlined
                    style={{
                      fontSize: "20px",
                      color: "#ffffff",
                    }}
                  />

                  <div className="text-light  cu-pointer" onClick={setActiveId}>
                    Copy Code
                  </div>
                </div>
              )}
            </div>

            <div className={`${active === 1 ? "d-block" : "d-none"}`}>
              <SyntaxHighlighter
                language="javascript"
                style={atomOneDark}
                className="p-1   p-sm-2 br-bottom"
              >
                {codeString}
              </SyntaxHighlighter>
            </div>

            <div className={`${active === 2 ? "d-block" : "d-none"}`}>
              <SyntaxHighlighter
                language="javascript"
                style={atomOneDark}
                className="p-1   p-sm-2"
              >
                {styleString}
              </SyntaxHighlighter>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default EmailFilterCode;
