import React from "react";
import "./Dealscard.scss";
import { Progress } from "antd";

const DealsCard = () => {
  return (
    <div className="deals-card-wrapper d-flex  flex-column  gap-5">
      <div className="card-wrapper  d-flex align-items-center ">
        <div className="progress-bar-section">
          <div>
            <div className="inner-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M19.7273 5.65232C21.1474 7.37899 22 9.58998 22 12.0001C22 17.523 17.5228 22.0001 12 22.0001C6.47715 22.0001 2 17.523 2 12.0001C2 6.47727 6.47715 2.00011 12 2.00011C14.3924 2.00011 16.5886 2.84024 18.3096 4.24157L18.8604 3.69075L19.1222 2.29638C19.2241 1.75358 19.7468 1.39616 20.2896 1.49806C20.8324 1.59997 21.1898 2.1226 21.0879 2.66541L21.0308 2.96934L21.3348 2.91228C21.8776 2.81037 22.4002 3.16779 22.5021 3.71059C22.604 4.25339 22.2466 4.77603 21.7038 4.87794L20.2239 5.15577L19.7273 5.65232ZM16.8861 5.66511C15.5346 4.62118 13.8398 4.00011 12 4.00011C7.58172 4.00011 4 7.58183 4 12.0001C4 16.4184 7.58172 20.0001 12 20.0001C16.4183 20.0001 20 16.4184 20 12.0001C20 10.1425 19.3669 8.4328 18.3047 7.07497L16.8763 8.50335C17.5835 9.48788 18 10.6954 18 12.0001C18 15.3138 15.3137 18.0001 12 18.0001C8.68629 18.0001 6 15.3138 6 12.0001C6 8.6864 8.68629 6.00011 12 6.00011C13.2869 6.00011 14.4792 6.40526 15.4562 7.09497L16.8861 5.66511ZM14.0101 8.5411C13.4195 8.19714 12.7327 8.00011 12 8.00011C9.79086 8.00011 8 9.79097 8 12.0001C8 14.2093 9.79086 16.0001 12 16.0001C14.2091 16.0001 16 14.2093 16 12.0001C16 11.2493 15.7931 10.5467 15.4332 9.94639L13.9248 11.4549C13.9738 11.6282 14 11.8111 14 12.0001C14 13.1047 13.1046 14.0001 12 14.0001C10.8954 14.0001 10 13.1047 10 12.0001C10 10.8955 10.8954 10.0001 12 10.0001C12.1692 10.0001 12.3336 10.0211 12.4905 10.0607L14.0101 8.5411Z"
                  fill="#92929D"
                />
              </svg>
            </div>

            <Progress
              type="circle"
              percent={68}
              strokeColor="#0062FF"
              showInfo=""
              className="progess-circle"
              size={[70, 70]}
            />
          </div>
        </div>

        <div className=" card-title-section  d-flex flex-column align-items-start">
          <div className="card-percent">68%</div>
          <div className="card-desc">Hit Rate this year</div>
        </div>
      </div>

      <div className="card-wrapper  d-flex align-items-center ">
        <div className="progress-bar-section">
          <div>
            <div className="inner-icon text-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="19"
                viewBox="0 0 22 19"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M20 5H2V8C2 8.55229 2.44772 9 3 9H19C19.5523 9 20 8.55229 20 8V5ZM6 3C6 1.34315 7.34315 0 9 0H13C14.6569 0 16 1.34315 16 3H20C21.1046 3 22 3.89543 22 5V15C22 17.2091 20.2091 19 18 19H4C1.79086 19 0 17.2091 0 15V5C0 3.89543 0.89543 3 2 3H6ZM8 3H14C14 2.44772 13.5523 2 13 2H9C8.44771 2 8 2.44772 8 3ZM8 11H3C2.64936 11 2.31278 10.9398 2 10.8293V15C2 16.1046 2.89543 17 4 17H18C19.1046 17 20 16.1046 20 15V10.8293C19.6872 10.9398 19.3506 11 19 11H14V12.6C14 13.3732 13.3732 14 12.6 14H9.4C8.6268 14 8 13.3732 8 12.6V11ZM10 12H12V11H10V12Z"
                  fill="#92929D"
                />
              </svg>
            </div>

            <Progress
              type="circle"
              percent={76}
              strokeColor="#3DD598"
              showInfo=""
              className="progess-circle"
              size={[70, 70]}
            />
          </div>
        </div>

        <div className=" card-title-section  d-flex flex-column align-items-start">
          <div className="card-percent">76%</div>
          <div className="card-desc">Deals this year</div>
        </div>
      </div>
    </div>
  );
};

export default DealsCard;
