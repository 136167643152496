import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";

import SyntaxHighlighter from "react-syntax-highlighter";
import { atomOneDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import Order from "../Components/OrderCard/Order";
import VisitorSlider from "../Components/VisitorSlider/VisitorSlider";
import AverageSaleCard from "../Components/AvergeSaleCard/AverageSaleCard";
import {
  StarOutlined,
  StarFilled,
  StarTwoTone,
  CopyOutlined,
  CheckOutlined,
} from "@ant-design/icons";

const AverageTotalSaleCode = () => {
  const codeString = `
  import React from "react";
  import "./AverageSaleCard.scss";
  import { Flex, Progress } from "antd";
  
  const AverageSaleCard = () => {
    return (
      <div className="average-sale-card-wrapper">
        <div className=" card-header d-flex flex-column gap-2 flex-wrap">
          <div className="card-title">Average Total Sales</div>
          <div className="record-year">2017-2018</div>
        </div>
  
        <div className="progress-area">
          <div className="cards-lists  d-flex flex-column flex-md-row align-items-center align-items-md-center ">
            <div className="progress-card  d-flex align-items-center  gap-4">
              <div className="progress-circle-otr">
                <Progress
                  type="circle"
                  percent={46}
                  strokeColor="
                #0062FF"
                  className="progess-circle"
                  size={[70, 70]}
                />
              </div>
              <div className=" card-title-section  d-flex flex-column align-items-start">
                <div className="card-percent">92,980</div>
                <div className="card-desc">Cases</div>
              </div>
            </div>
  
            <div className="progress-card  d-flex align-items-center  gap-4">
              <div className="progress-circle-otr">
                <Progress
                  type="circle"
                  percent={74}
                  strokeColor="
                
  #3DD598"
                  className="progess-circle"
                  size={[70, 70]}
                />
              </div>
              <div className=" card-title-section  d-flex flex-column align-items-start">
                <div className="card-percent">28,546</div>
                <div className="card-desc">Applications</div>
              </div>
            </div>
  
            <div className="progress-card  d-flex align-items-center  gap-4">
              <div className="progress-circle-otr">
                <Progress
                  type="circle"
                  percent={14}
                  strokeColor="
                  #FF974A"
                  className="progess-circle"
                  size={[70, 70]}
                />
              </div>
              <div className=" card-title-section  d-flex flex-column align-items-start">
                <div className="card-percent">14,008</div>
                <div className="card-desc">Products</div>
              </div>
            </div>
          </div>
        </div>
  
        <div className="d-flex  gap-2 py-4">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M7.33398 5.33325V8.66659C7.33398 9.03478 7.63246 9.33325 8.00065 9.33325C8.36884 9.33325 8.66732 9.03478 8.66732 8.66659V5.33325C8.66732 4.96506 8.36884 4.66659 8.00065 4.66659C7.63246 4.66659 7.33398 4.96506 7.33398 5.33325Z"
                fill="#92929D"
              />
              <path
                d="M8.66732 10.6666C8.66732 11.0348 8.36884 11.3333 8.00065 11.3333C7.63246 11.3333 7.33398 11.0348 7.33398 10.6666C7.33398 10.2984 7.63246 9.99992 8.00065 9.99992C8.36884 9.99992 8.66732 10.2984 8.66732 10.6666Z"
                fill="#92929D"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M14.6673 7.99992C14.6673 11.6818 11.6826 14.6666 8.00065 14.6666C4.31875 14.6666 1.33398 11.6818 1.33398 7.99992C1.33398 4.31802 4.31875 1.33325 8.00065 1.33325C11.6826 1.33325 14.6673 4.31802 14.6673 7.99992ZM2.66732 7.99992C2.66732 10.9454 5.05513 13.3333 8.00065 13.3333C10.9462 13.3333 13.334 10.9454 13.334 7.99992C13.334 5.0544 10.9462 2.66659 8.00065 2.66659C5.05513 2.66659 2.66732 5.0544 2.66732 7.99992Z"
                fill="#92929D"
              />
            </svg>
          </div>
  
          <div className="card-desc">
            Net sales, found on the income statement are used to calculate this
            ration returns and refunds must be backed out of total sales to
            measure the truly measure the firm’s assets abillity to generate
            sales.
          </div>
        </div>
      </div>
    );
  };
  
  export default AverageSaleCard;
  
            `;

  const styleString = `
  @import "../../CommonStyle/Variables.scss";
  .average-sale-card-wrapper {
    background-color: $light;
    padding: 25px;
    border-radius: 15px;
    .card-header {
      .card-title {
        font-size: $font-size-16px;
        line-height: $line-height-24px;
        font-weight: $font-weight-bold;
      }
  
      .record-year {
        font-size: $font-size-14px;
        line-height: $line-height-16px;
        font-weight: $font-weight-regular;
        color: $gray974;
      }
    }
  
    .progress-area {
      padding-top: 50px;
      padding-bottom: 50px;
  
      // .progress-circle-otr {
      //   width: 70px;
      //   height: 70px;
      // }
  
      border-bottom: 1px solid $darkf15;
  
      .cards-lists {
        gap: 46px;
      }
  
      .ant-progress-text {
        font-size: $font-size-24px;
        line-height: $line-height-36px;
        color: $dark75;
        font-weight: $font-weight-extra-bold;
      }
  
      .progress-card {
        .card-title-section {
          .card-percent {
            font-size: $font-size-24px;
            line-height: $line-height-36px;
            color: $dark75;
            font-weight: $font-weight-bold;
          }
  
          .card-desc {
            font-size: $font-size-14px;
            line-height: $line-height-16px;
            color: $gray974;
            font-weight: $font-weight-regular;
          }
        }
      }
    }
  
    .card-desc {
      font-size: $font-size-12px;
      line-height: $line-height-22px;
      color: $gray29d;
      font-weight: $font-weight-regular;
    }
  }
  
        
            `;

  const [copy, setCopy] = useState(false);
  const [styleCopy, setStyleCopy] = useState(false);
  const [active, setActive] = useState(1);

  const setActiveId = () => {
    if (active === 1) {
      navigator.clipboard.writeText(codeString);
      setCopy(true);
    }

    if (active === 2) {
      navigator.clipboard.writeText(styleString);
      setCopy(true);
    }
  };

  useEffect(() => {
    setCopy(false);
  }, [active]);
  return (
    <Row className="mx-0 gy-lg-0  gy-3 ">
      <Col lg={5} className="position-relative">
        <div className="custom-position-sticky">
          <AverageSaleCard />
        </div>
      </Col>

      <Col lg={7} className="d-flex flex-column  gap-4 ">
        <div>
          <div className="d-flex justify-content-between align-items-center  p-3  bg-secondary  br-top">
            <Nav variant="pills" className="d-flex  gap-2 ">
              <Nav.Item>
                <Nav.Link
                  eventKey="first"
                  onClick={() => setActive(1)}
                  className={active === 1 ? "active" : ""}
                >
                    JSX
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="second"
                  onClick={() => setActive(2)}
                  className={active === 2 ? "active" : ""}
                >
                  CSS
                </Nav.Link>
              </Nav.Item>
            </Nav>

            {copy ? (
              <div className="d-flex gap-2 align-items-center">
                <CheckOutlined
                  style={{
                    fontSize: "20px",
                    color: "#ffffff",
                  }}
                />
                <div
                  className="text-light  cu-pointer"
                  onClick={() => {
                    navigator.clipboard.writeText("").then(() => {
                      setCopy(false);
                    });
                  }}
                >
                  Copied
                </div>
              </div>
            ) : (
              <div className="d-flex gap-2 align-items-center">
                <CopyOutlined
                  style={{
                    fontSize: "20px",
                    color: "#ffffff",
                  }}
                />

                <div className="text-light  cu-pointer" onClick={setActiveId}>
                  Copy Code
                </div>
              </div>
            )}
          </div>

          <div className={`${active === 1 ? "d-block" : "d-none"}`}>
            <SyntaxHighlighter
              language="javascript"
              style={atomOneDark}
              className="p-1   p-sm-2 br-bottom"
            >
              {codeString}
            </SyntaxHighlighter>
          </div>

          <div className={`${active === 2 ? "d-block" : "d-none"}`}>
            <SyntaxHighlighter
              language="javascript"
              style={atomOneDark}
              className="p-1   p-sm-2"
            >
              {styleString}
            </SyntaxHighlighter>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default AverageTotalSaleCode;
