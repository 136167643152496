import React, { useState, useEffect } from "react";

import { Col, Row } from "react-bootstrap";
import SalesCard from "../Components/SalesCard/SalesCard";
import SyntaxHighlighter from "react-syntax-highlighter";
import { atomOneDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import MultipleLineGraph from "../Components/MultipleLineGraph/MultipleLineGraph";
import SalesLocation from "../Components/SalesLocation/SalesLocation";
import { CopyOutlined, CheckOutlined } from "@ant-design/icons";
import PhotoVideo from "../Components/PhotoVideo/PhotoVideo";
import LikePage from "../Components/LikePage/LikePage";
import PubgCard from "../Components/PubgCard/PubgCard";
import NotificationCard from "../Components/NotificationCard/NotificationCard";
import EmailFilterForm from "../Components/EmailFilter/EmailFilterForm";
import CoverPhotoCard from "../Components/CoverPhotoCard/CoverPhotoCard";

const CoverPhotoCode = () => {
  const codeString = `
  import React from "react";
  import { Avatar, Space } from "antd";
  import img from "../../assets/images/coverprofile.png";
  import Badge from "react-bootstrap/Badge";
  import coverphoto from "../../assets/images/coverphoto.png";
  import "./CoverPhotoCard.scss";
  import { Col, Row } from "react-bootstrap";
  
  const CoverPhotoCard = () => {
    return (
      <div className="cover-card-wrapper">
        <div className="cover-photo-area">
          <img src={coverphoto} className="cover-photo-inr"></img>
        </div>
  
        <div className="   profile-desc-area">
          <Row>
            <Col md={3} className="profile-pic-col">
              <div className="profile-pic">
                <Avatar src={img} size={86} className="Profile-pic-inr"></Avatar>
              </div>
            </Col>
            <Col md={9}>
              <div className="d-flex  flex-column gap-2 w-100">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="profile-title">Mayke Schuurs</div>
  
                  <div>
                    {" "}
                    <Badge bg="primary">Following</Badge>
                  </div>
                </div>
                <div className="mail-tag">@maymayke</div>
                <div className="profile-desc">Not a Man but a Ghost 👻</div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  };
  
  export default CoverPhotoCard;
  
            `;

  const styleString = `
  @import "../../CommonStyle/Variables.scss";
  .cover-card-wrapper {
    .cover-photo-area {
      height: 100px;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
  
      .cover-photo-inr {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
      }
    }
  
    .profile-desc-area {
      background-color: $light;
      padding: 20px;
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
  
      .profile-pic {
        position: relative;
        .Profile-pic-inr {
          position: absolute;
          top: -40px;
        }
      }
      .profile-title {
        font-size: $font-size-18px;
        line-height: $line-height-27px;
        font-weight: $font-weight-bold;
      }
  
      .mail-tag {
        font-size: $font-size-14px;
        font-weight: $font-weight-regular;
        line-height: $line-height-22px;
        color: $gray29d;
      }
  
      .profile-desc {
        font-size: $font-size-16px;
        line-height: $line-height-26px;
        color: $gray44f;
      }
    }
  }
  
            `;

  const [copy, setCopy] = useState(false);
  const [styleCopy, setStyleCopy] = useState(false);
  const [active, setActive] = useState(1);

  const setActiveId = () => {
    if (active === 1) {
      navigator.clipboard.writeText(codeString);
      setCopy(true);
    }

    if (active === 2) {
      navigator.clipboard.writeText(styleString);
      setCopy(true);
    }
  };

  useEffect(() => {
    setCopy(false);
  }, [active]);
  return (
    <div className="">
      <Row className="mx-0 gy-lg-0  gy-3 ">
        <Col lg={4} className="position-relative">
          <div className="custom-position-sticky">
            <CoverPhotoCard />
          </div>
        </Col>

        <Col lg={8} className="d-flex flex-column  gap-4 ">
          <div>
            <div className="d-flex justify-content-between align-items-center  p-3  bg-secondary  br-top">
              <Nav variant="pills" className="d-flex  gap-2 ">
                <Nav.Item>
                  <Nav.Link
                    eventKey="first"
                    onClick={() => setActive(1)}
                    className={active === 1 ? "active" : ""}
                  >
                    JSX
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="second"
                    onClick={() => setActive(2)}
                    className={active === 2 ? "active" : ""}
                  >
                    CSS
                  </Nav.Link>
                </Nav.Item>
              </Nav>

              {copy ? (
                <div className="d-flex gap-2 align-items-center">
                  <CheckOutlined
                    style={{
                      fontSize: "20px",
                      color: "#ffffff",
                    }}
                  />
                  <div
                    className="text-light  cu-pointer"
                    onClick={() => {
                      navigator.clipboard.writeText("").then(() => {
                        setCopy(false);
                      });
                    }}
                  >
                    Copied
                  </div>
                </div>
              ) : (
                <div className="d-flex gap-2 align-items-center">
                  <CopyOutlined
                    style={{
                      fontSize: "20px",
                      color: "#ffffff",
                    }}
                  />

                  <div className="text-light  cu-pointer" onClick={setActiveId}>
                    Copy Code
                  </div>
                </div>
              )}
            </div>

            <div className={`${active === 1 ? "d-block" : "d-none"}`}>
              <SyntaxHighlighter
                language="javascript"
                style={atomOneDark}
                className="p-1   p-sm-2 br-bottom"
              >
                {codeString}
              </SyntaxHighlighter>
            </div>

            <div className={`${active === 2 ? "d-block" : "d-none"}`}>
              <SyntaxHighlighter
                language="javascript"
                style={atomOneDark}
                className="p-1   p-sm-2"
              >
                {styleString}
              </SyntaxHighlighter>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default CoverPhotoCode;
