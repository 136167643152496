import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";

import SyntaxHighlighter from "react-syntax-highlighter";
import { atomOneDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import Order from "../Components/OrderCard/Order";
import VisitorSlider from "../Components/VisitorSlider/VisitorSlider";
import AverageSaleCard from "../Components/AvergeSaleCard/AverageSaleCard";
import { BarChart } from "recharts";
import BarChartGraph from "../Components/BarChartGraph/BarChart";
import {
  StarOutlined,
  StarFilled,
  StarTwoTone,
  CopyOutlined,
  CheckOutlined,
} from "@ant-design/icons";

const BarGraphCodePage = () => {
  const codeString = `
  import React from "react";
import "./BarChart.scss";
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const data = [
  { name: "JAN", onlinesale: 14000, offlinesale: 12000 },
  { name: "Feb", onlinesale: 9000, offlinesale: 11000 },
  { name: "Mar", onlinesale: 22000, offlinesale: 18000 },
  { name: "Apr", onlinesale: 19000, offlinesale: 21000 },
  { name: "May", onlinesale: 6500, offlinesale: 12000 },
  { name: "Jun", onlinesale: 27632, offlinesale: 29000 },
];
const BarChartGraph = () => {
  return (
    <div className="Bar-Chart-Wrapper d-flex flex-column">
      <div className="d-flex flex-column gap-2">
        <div className="  sales-figuer-header  d-flex justify-content-between align-items-center">
          <div className="figure-title">Sales Report</div>

          <div className="cases-tags-wrapper d-flex align-items-center gap-3">
            <div className=" cases-tags  d-flex align-items-center  gap-2">
              <div className="">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="12"
                  viewBox="0 0 10 12"
                  fill="none"
                >
                  <rect width="10" height="12" rx="5" fill="#0062FF" />
                </svg>
              </div>
              <div className="tag-title"> Online Sales</div>
            </div>

            <div className="cases-tags  d-flex align-items-center  gap-2">
              <div className="">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="12"
                  viewBox="0 0 10 12"
                  fill="none"
                >
                  <rect width="10" height="12" rx="5" fill="#3DD598" />
                </svg>
              </div>
              <div className="tag-title"> Offline Sales</div>
            </div>
          </div>
        </div>

        <div className="report-year">2017-2018</div>
      </div>

      <ResponsiveContainer width="100%" aspect={3}>
        <BarChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" vertical="" horizontal="true" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />

          <Bar
            dataKey="offlinesale"
            fill="#3DD598"
            // activeBar={<Rectangle fill="pink" stroke="blue" />}
            barSize={10}
            radius={[5, 5, 0, 0]}
            className="custom-bar"
          />
          <Bar
            dataKey="onlinesale"
            fill="#0062FF"
            // activeBar={<Rectangle fill="gold" stroke="purple" />}
            barSize={10}
            radius={[5, 5, 0, 0]}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default BarChartGraph;

  
            `;

  const styleString = `
  @import "../../CommonStyle/Variables.scss";
.Bar-Chart-Wrapper {
  background-color: $light;
  padding: 25px;
  border-radius: 15px;
  gap: 30px;
  .sales-figuer-header {
    .figure-title {
      font-size: $font-size-16px;
      line-height: $line-height-24px;
      font-weight: $font-weight-bold;
    }

    .cases-tags-wrapper {
      .cases-tags {
        .tag-title {
          font-size: $font-size-14px;
          line-height: $line-height-24px;
          font-weight: $font-weight-regular;
          color: $dark4f;
        }
      }
    }
  }

  .report-year {
    font-size: $font-size-14px;
    line-height: $line-height-16px;
    font-weight: $font-weight-regular;
    color: $gray974;
  }

  .recharts-tooltip-wrapper {
    border: none !important;
    outline: none !important;
    background-image: url("../../assets/images/Shape.png") !important;
    background-repeat: no-repeat;
    background-size: contain;
    background-size: 100% 100%;
    padding: 10px;
    min-width: 115px !important;
    .recharts-default-tooltip {
      border: none !important;
      // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
      // border-radius: 25px;
      background: none !important;

      display: flex;
      flex-direction: column-reverse;
      justify-content: flex-start;
      align-items: flex-start;

      .recharts-tooltip-label {
        font-size: $font-size-14px;
        line-height: $line-height-16px;
        color: $gray974 !important;
        font-weight: $font-weight-regular;
      }

      .recharts-tooltip-item-list {
        .recharts-tooltip-item-name {
          display: none !important;
        }
        .recharts-tooltip-item-separator {
          display: none;
        }

        .recharts-tooltip-item-value {
          color: $dark;
          font-size: $font-size-16px;
          line-height: $line-height-24px;
          font-weight: $font-weight-bold;
        }
        .recharts-tooltip-item-value::before {
          content: "$";
        }
      }
    }
  }

  .custom-bar {
    border-radius: 20px !important;
  }
}

  
        
            `;

  const [copy, setCopy] = useState(false);
  const [styleCopy, setStyleCopy] = useState(false);
  const [active, setActive] = useState(1);

  const setActiveId = () => {
    if (active === 1) {
      navigator.clipboard.writeText(codeString);
      setCopy(true);
    }

    if (active === 2) {
      navigator.clipboard.writeText(styleString);
      setCopy(true);
    }
  };

  useEffect(() => {
    setCopy(false);
  }, [active]);
  return (
    <div>
      <Row className="mx-0 gy-lg-0  gy-3 ">
        <Col lg={5} className="position-relative">
          <div className="custom-position-sticky">
            <BarChartGraph />
          </div>
        </Col>

        <Col lg={7} className="d-flex flex-column  gap-4 ">
          <div>
            <div className="d-flex justify-content-between align-items-center  p-3  bg-secondary  br-top">
              <Nav variant="pills" className="d-flex  gap-2 ">
                <Nav.Item>
                  <Nav.Link
                    eventKey="first"
                    onClick={() => setActive(1)}
                    className={active === 1 ? "active" : ""}
                  >
                      JSX
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="second"
                    onClick={() => setActive(2)}
                    className={active === 2 ? "active" : ""}
                  >
                    CSS
                  </Nav.Link>
                </Nav.Item>
              </Nav>

              {copy ? (
                <div className="d-flex gap-2 align-items-center">
                  <CheckOutlined
                    style={{
                      fontSize: "20px",
                      color: "#ffffff",
                    }}
                  />
                  <div
                    className="text-light  cu-pointer"
                    onClick={() => {
                      navigator.clipboard.writeText("").then(() => {
                        setCopy(false);
                      });
                    }}
                  >
                    Copied
                  </div>
                </div>
              ) : (
                <div className="d-flex gap-2 align-items-center">
                  <CopyOutlined
                    style={{
                      fontSize: "20px",
                      color: "#ffffff",
                    }}
                  />

                  <div className="text-light  cu-pointer" onClick={setActiveId}>
                    Copy Code
                  </div>
                </div>
              )}
            </div>

            <div className={`${active === 1 ? "d-block" : "d-none"}`}>
              <SyntaxHighlighter
                language="javascript"
                style={atomOneDark}
                className="p-1   p-sm-2 br-bottom"
              >
                {codeString}
              </SyntaxHighlighter>
            </div>

            <div className={`${active === 2 ? "d-block" : "d-none"}`}>
              <SyntaxHighlighter
                language="javascript"
                style={atomOneDark}
                className="p-1   p-sm-2"
              >
                {styleString}
              </SyntaxHighlighter>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default BarGraphCodePage;
