import React, { useState, useEffect } from "react";

import { Col, Row } from "react-bootstrap";
import SalesCard from "../Components/SalesCard/SalesCard";
import SyntaxHighlighter from "react-syntax-highlighter";
import { atomOneDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import MultipleLineGraph from "../Components/MultipleLineGraph/MultipleLineGraph";
import SalesLocation from "../Components/SalesLocation/SalesLocation";
import { CopyOutlined, CheckOutlined } from "@ant-design/icons";

const SalesLocationCode = () => {
  const codeString = `
    import React from "react";
    import "./SalesLocation.scss";
    import { Row, Col } from "react-bootstrap";
    import img from "../../assets/images/US.svg";
    import map from "../../assets/images/Map.png";
    import Badge from "react-bootstrap/Badge";
    
    const SalesLocation = () => {
      return (
        <div className="Location-wrapper">
          <Row className="mx-0">
            <Col md={4}>
              <div className="d-flex flex-column gap-4">
                <div className="location-title">Top Retail Sales Locations</div>
    
                <div className="d-flex flex-column gap-2">
                  <div className="d-flex align-items-center gap-2">
                    <div className="sale-count">15.870</div>
    
                    <div>
                      <img src={img}></img>
                    </div>
                  </div>
    
                  <div className="count-desc">Our most customers in US</div>
                </div>
    
                <div className="d-flex flex-column gap-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center gap-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="12"
                        viewBox="0 0 10 12"
                        fill="none"
                      >
                        <rect width="10" height="12" rx="5" fill="#0062FF" />
                      </svg>
    
                      <div className="fount-14  fount-regular">Massive</div>
                    </div>
    
                    <div className="fount-14 fount-bold ">15.7k</div>
                  </div>
    
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center gap-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="12"
                        viewBox="0 0 10 12"
                        fill="none"
                      >
                        <rect width="10" height="12" rx="5" fill="#FF974A" />
                      </svg>
    
                      <div className="fount-14  fount-regular">Large</div>
                    </div>
    
                    <div className="fount-14 fount-bold ">4.9k</div>
                  </div>
    
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center gap-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="12"
                        viewBox="0 0 10 12"
                        fill="none"
                      >
                        <rect width="10" height="12" rx="5" fill="#FFC542" />
                      </svg>
    
                      <div className="fount-14  fount-regular">Medium</div>
                    </div>
    
                    <div className="fount-14 fount-bold ">2.4k</div>
                  </div>
    
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center gap-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="12"
                        viewBox="0 0 10 12"
                        fill="none"
                      >
                        <rect width="10" height="12" rx="5" fill="#E2E2EA" />
                      </svg>
    
                      <div className="fount-14  fount-regular">Small</div>
                    </div>
    
                    <div className="fount-14 fount-bold ">980</div>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={8}>
              <div className="map-img-otr">
                <img src={map} className="map-img-inr"></img>
    
                <div className="map-badge">
                  {" "}
                  <Badge bg="dark">United State</Badge>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      );
    };
    export default SalesLocation;
        
                `;

  const styleString = `
  @import "../../CommonStyle/Variables.scss";
  .Location-wrapper {
    background-color: $light;
    border-radius: 15px;
    padding: 25px;
  
    .location-title {
      font-size: $font-size-16px;
      line-height: $line-height-24px;
      font-weight: $font-weight-bold;
      color: $gray725;
    }
  
    .sale-count {
      font-size: $font-size-28px;
      line-height: $line-height-42px;
      color: $gray725;
      font-weight: $font-weight-bold;
    }
  
    .count-desc {
      font-size: 14px;
      line-height: $line-height-42px;
      color: $gray74;
      font-weight: $font-weight-regular;
    }
  
    .fount-14 {
      font-size: $font-size-14px;
      line-height: $line-height-24px;
      color: $gray44f;
    }
    .fount-bold {
      font-weight: $font-weight-bold;
    }
    .fount-regular {
      font-weight: $font-weight-regular;
    }
  
    .map-img-otr {
      width: 100%;
      height: 100%;
      position: relative;
      .map-img-inr {
        object-fit: fill;
        width: 100%;
        height: 100%;
      }
  
      .map-badge {
        position: absolute;
        top: 28%;
        left: 10%;
      }
    }
  }
  
         
                `;
  const [copy, setCopy] = useState(false);
  const [styleCopy, setStyleCopy] = useState(false);
  const [active, setActive] = useState(1);

  const setActiveId = () => {
    if (active === 1) {
      navigator.clipboard.writeText(codeString);
      setCopy(true);
    }

    if (active === 2) {
      navigator.clipboard.writeText(styleString);
      setCopy(true);
    }
  };

  useEffect(() => {
    setCopy(false);
  }, [active]);
  return (
    <div className="">
      <Row className="mx-0 gy-lg-0  gy-3 ">
        <Col lg={5} className="position-relative">
          <div className="custom-position-sticky">
            <SalesLocation />
          </div>
        </Col>

        <Col lg={7} className="d-flex flex-column  gap-4 ">
          <div>
            <div className="d-flex justify-content-between align-items-center  p-3  bg-secondary  br-top">
              <Nav variant="pills" className="d-flex  gap-2 ">
                <Nav.Item>
                  <Nav.Link
                    eventKey="first"
                    onClick={() => setActive(1)}
                    className={active === 1 ? "active" : ""}
                  >
                    JSX
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="second"
                    onClick={() => setActive(2)}
                    className={active === 2 ? "active" : ""}
                  >
                    CSS
                  </Nav.Link>
                </Nav.Item>
              </Nav>

              {copy ? (
                <div className="d-flex gap-2 align-items-center">
                  <CheckOutlined
                    style={{
                      fontSize: "20px",
                      color: "#ffffff",
                    }}
                  />
                  <div
                    className="text-light  cu-pointer"
                    onClick={() => {
                      navigator.clipboard.writeText("").then(() => {
                        setCopy(false);
                      });
                    }}
                  >
                    Copied
                  </div>
                </div>
              ) : (
                <div className="d-flex gap-2 align-items-center">
                  <CopyOutlined
                    style={{
                      fontSize: "20px",
                      color: "#ffffff",
                    }}
                  />

                  <div className="text-light  cu-pointer" onClick={setActiveId}>
                    Copy Code
                  </div>
                </div>
              )}
            </div>

            <div className={`${active === 1 ? "d-block" : "d-none"}`}>
              <SyntaxHighlighter
                language="javascript"
                style={atomOneDark}
                className="p-1   p-sm-2 br-bottom"
              >
                {codeString}
              </SyntaxHighlighter>
            </div>

            <div className={`${active === 2 ? "d-block" : "d-none"}`}>
              <SyntaxHighlighter
                language="javascript"
                style={atomOneDark}
                className="p-1   p-sm-2"
              >
                {styleString}
              </SyntaxHighlighter>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default SalesLocationCode;
