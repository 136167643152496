import React, { useState, useEffect } from "react";

import { Col, Row } from "react-bootstrap";
import SalesCard from "../Components/SalesCard/SalesCard";
import SyntaxHighlighter from "react-syntax-highlighter";
import { atomOneDark } from "react-syntax-highlighter/dist/esm/styles/hljs";

import Nav from "react-bootstrap/Nav";

import {
  StarOutlined,
  StarFilled,
  StarTwoTone,
  CopyOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import TransactionCard from "../Components/TransactionCard/TransactionCard";
import RefferCard from "../Components/RefferCard/RefferCard";

const RefferCode = () => {
  const codeString = `
    import React, { useState } from "react";
import "./RefferCard.scss";

const RefferCard = () => {
  const [showAllTransaction, setShowAllTransaction] = useState(false);

  const toggleShowAllTransaction = () => {
    setShowAllTransaction(!showAllTransaction);
  };
  const data = [
    {
      id: 1,
      pmail: "Direct, email, IM",
      pview: "34",
      psales: "34",
      pconvertion: "10.5%",
      ptotal: "110",
    },

    {
      pmail: "dribbble.com",
      id: 2,
      pview: "23",
      psales: "75",
      pconvertion: "10.5%",
      ptotal: "110",
    },

    {
      id: 3,
      pmail: "Direct, email, IM",
      pview: "34",
      psales: "34",
      pconvertion: "10.5%",
      ptotal: "110",
    },

    {
      id: 4,
      pmail: "Direct, email, IM",
      pview: "34",
      psales: "34",
      pconvertion: "10.5%",
      ptotal: "110",
    },

    {
      pmail: "dribbble.com",
      id: 5,
      pview: "23",
      psales: "75",
      pconvertion: "10.5%",
      ptotal: "110",
    },

    {
      id: 6,
      pmail: "Direct, email, IM",
      pview: "34",
      psales: "34",
      pconvertion: "10.5%",
      ptotal: "110",
    },

    {
      id: 7,
      pmail: "Direct, email, IM",
      pview: "34",
      psales: "34",
      pconvertion: "10.5%",
      ptotal: "110",
    },
  ];
  return (
    <div className="custom-wrapper">
      <div className="card-header  d-flex justify-content-between">
        <div className="card-title">Referrer</div>
        <div className="cu-pointer  add-icon " type="button">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M7 12C7 10.8954 6.10457 10 5 10C3.89543 10 3 10.8954 3 12C3 13.1046 3.89543 14 5 14C6.10457 14 7 13.1046 7 12Z"
              fill="#92929D"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14C13.1046 14 14 13.1046 14 12Z"
              fill="#92929D"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M21 12C21 10.8954 20.1046 10 19 10C17.8954 10 17 10.8954 17 12C17 13.1046 17.8954 14 19 14C20.1046 14 21 13.1046 21 12Z"
              fill="#92929D"
            />
          </svg>
        </div>
      </div>
      <div className="Reffer-Card-wrapper   d-flex flex-column gap-4">
        <div className="card-content-otr">
          <div className="table-header d-flex ">
            <div className="header-title web-section">Referrer Sources</div>
            <div className="header-title view-section">View</div>
            <div className="header-title sale-section">Sales</div>
            <div className="header-title convertion-section  ">Convertion</div>
            <div className="header-title total-section d-flex justify-content-end">
              Total
            </div>
          </div>

          <div className="table-data  ">
            {data
              .slice(0, showAllTransaction ? data.length : 4)
              .map((item, index) => (
                <div className="record-row  d-flex py-2">
                  <div className="web-section d-flex align-items-center  gap-2 ">
                    <div className="table-data-desc">{item.pmail}</div>
                  </div>

                  <div className="view-section table-data-desc">
                    {item.pview}
                  </div>

                  <div className="sale-section table-data-desc">
                    {item.psales}
                  </div>

                  <div className="convertion-section table-data-desc ">
                    {item.pconvertion}
                  </div>

                  <div className="total-section table-data-desc  d-flex justify-content-end">
                   {item.ptotal}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>

      <div
        className="card-footer d-flex justify-content-center  cu-pointer"
        onClick={toggleShowAllTransaction}
      >
        {showAllTransaction ? "View less Transaction" : "View more Transacton"}
      </div>
    </div>
  );
};

export default RefferCard;

  
              `;

  const styleString = `
    @import "../../CommonStyle/Variables.scss";

.custom-wrapper {
  background-color: $light;
  padding: 25px 10px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  .card-header {
    .add-icon:hover svg path {
      fill: $green98;
    }
    .card-title {
      font-size: $font-size-16px;
      line-height: $line-height-24px;
      font-weight: $font-weight-bold;
    }
  }
  .Reffer-Card-wrapper {
    background-color: $light;

    padding-bottom: 20px;

    overflow-x: auto;

    .card-content-otr {
      width: 900px;
      overflow-x: auto;

      .table-header {
        padding: 11px 9px;
        border-radius: 15px;
        background-color: $lightfb;

        .header-title {
          font-size: 11px;
          line-height: $line-height-12px;
          color: $gray44f;
          font-weight: $font-weight-medium;
        }
      }

      .table-data {
        .record-row {
          padding: 15px 0px;

          .table-data-desc {
            font-size: 14px;
            line-height: $line-height-24px;
            color: $gray44f;
            font-weight: $font-weight-medium;
          }
        }
      }
    }

    .card-footer {
      padding-top: 20px;
      color: $blue6ff;
      font-size: $font-size-12px;
      line-height: $line-height-18px;
      font-weight: $font-weight-bold;
    }

    .web-section {
      width: 50%;
    }
    .view-section {
      width: 10%;
    }

    .sale-section {
      width: 10%;
    }
    .convertion-section {
      width: 20%;
    }

    .total-section {
      width: 10%;
    }
  }

  .card-footer {
    padding-top: 20px;
    color: $blue6ff;
    font-size: $font-size-12px;
    line-height: $line-height-18px;
    font-weight: $font-weight-bold;
  }

  .Reffer-Card-wrapper::-webkit-scrollbar {
    height: 5px;
    padding-top: 10px;
  }

  .Reffer-Card-wrapper::-webkit-scrollbar-thumb {
    background-color: #ff974a;
    border-radius: 15px;
  }
}

              `;

  const [copy, setCopy] = useState(false);
  const [styleCopy, setStyleCopy] = useState(false);
  const [active, setActive] = useState(1);

  const setActiveId = () => {
    if (active === 1) {
      navigator.clipboard.writeText(codeString);
      setCopy(true);
    }

    if (active === 2) {
      navigator.clipboard.writeText(styleString);
      setCopy(true);
    }
  };

  useEffect(() => {
    setCopy(false);
  }, [active]);
  return (
    <div className="">
      <Row className="mx-0 gy-lg-0  gy-3 ">
        <Col lg={5} className="position-relative">
          <div className="custom-position-sticky">
            <RefferCard />
          </div>
        </Col>

        <Col lg={7} className="d-flex flex-column  gap-4 ">
          <div>
            <div className="d-flex justify-content-between align-items-center  p-3  bg-secondary  br-top">
              <Nav variant="pills" className="d-flex  gap-2 ">
                <Nav.Item>
                  <Nav.Link
                    eventKey="first"
                    onClick={() => setActive(1)}
                    className={active === 1 ? "active" : ""}
                  >
                    JSX
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="second"
                    onClick={() => setActive(2)}
                    className={active === 2 ? "active" : ""}
                  >
                    CSS
                  </Nav.Link>
                </Nav.Item>
              </Nav>

              {copy ? (
                <div className="d-flex gap-2 align-items-center">
                  <CheckOutlined
                    style={{
                      fontSize: "20px",
                      color: "#ffffff",
                    }}
                  />
                  <div
                    className="text-light  cu-pointer"
                    onClick={() => {
                      navigator.clipboard.writeText("").then(() => {
                        setCopy(false);
                      });
                    }}
                  >
                    Copied
                  </div>
                </div>
              ) : (
                <div className="d-flex gap-2 align-items-center">
                  <CopyOutlined
                    style={{
                      fontSize: "20px",
                      color: "#ffffff",
                    }}
                  />

                  <div className="text-light  cu-pointer" onClick={setActiveId}>
                    Copy Code
                  </div>
                </div>
              )}
            </div>

            <div className={`${active === 1 ? "d-block" : "d-none"}`}>
              <SyntaxHighlighter
                language="javascript"
                style={atomOneDark}
                className="p-1   p-sm-2 br-bottom"
              >
                {codeString}
              </SyntaxHighlighter>
            </div>

            <div className={`${active === 2 ? "d-block" : "d-none"}`}>
              <SyntaxHighlighter
                language="javascript"
                style={atomOneDark}
                className="p-1   p-sm-2"
              >
                {styleString}
              </SyntaxHighlighter>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default RefferCode;
