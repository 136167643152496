import React, { useState,useEffect } from "react";
import { Col, Row } from "react-bootstrap";

import SyntaxHighlighter from "react-syntax-highlighter";
import { atomOneDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";

import CompleteProfile from "../Components/CompleteProfile/CompleteProfile";
import {
    StarOutlined,
    StarFilled,
    StarTwoTone,
    CopyOutlined,
    CheckOutlined,
  } from "@ant-design/icons";

const CompleteProfileCode = () => {
  const codeString = `
    import React from "react";
import "./CompleteProfile.scss";
import { Progress } from "antd";

const CompleteProfile = () => {
  return (
    <div className="d-flex flex-column  complete-Profile-Card-wraper gap-4">
      <div className="head-title">Complete Your Profile</div>
      <Progress percent={85} strokeColor="#0062FF" />
    </div>
  );
};

export default CompleteProfile;

    
      
            `;

  const styleString = `
  @import "../../CommonStyle/Variables.scss";
.complete-Profile-Card-wraper {
  background-color: $light;
  border-radius: 15px;
  padding: 25px;

  .head-title {
    font-size: $font-size-14px;
    line-height: $line-height-22px;
    color: $dark75;
    font-weight: $font-weight-bold;
  }
}

      
        
            `;

  const [copy, setCopy] = useState(false);
  const [styleCopy, setStyleCopy] = useState(false);
  const [active, setActive] = useState(1);

  const setActiveId = () => {
    if (active === 1) {
      navigator.clipboard.writeText(codeString);
      setCopy(true);
    }

    if (active === 2) {
      navigator.clipboard.writeText(styleString);
      setCopy(true);
    }
  };

  useEffect(() => {
    setCopy(false);
  }, [active]);
  return (
    <div>
      <Row className="mx-0 gy-lg-0  gy-3 ">
        <Col lg={4}>
          <CompleteProfile />
        </Col>

        <Col lg={8} className="d-flex flex-column  gap-4 ">
        <div>
              <div className="d-flex justify-content-between align-items-center  p-3  bg-secondary  br-top">
                <Nav variant="pills" className="d-flex  gap-2 ">
                  <Nav.Item>
                    <Nav.Link
                      eventKey="first"
                      onClick={() => setActive(1)}
                      className={active === 1 ? "active" : ""}
                    >
                        JSX
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="second"
                      onClick={() => setActive(2)}
                      className={active === 2 ? "active" : ""}
                    >
                      CSS
                    </Nav.Link>
                  </Nav.Item>
                </Nav>

                {copy ? (
                  <div className="d-flex gap-2 align-items-center">
                    <CheckOutlined
                      style={{
                        fontSize: "20px",
                        color: "#ffffff",
                      }}
                    />
                    <div
                      className="text-light  cu-pointer"
                      onClick={() => {
                        navigator.clipboard.writeText("").then(() => {
                          setCopy(false);
                        });
                      }}
                    >
                      Copied
                    </div>
                  </div>
                ) : (
                  <div className="d-flex gap-2 align-items-center">
                    <CopyOutlined
                      style={{
                        fontSize: "20px",
                        color: "#ffffff",
                      }}
                    />

                    <div
                      className="text-light  cu-pointer"
                      onClick={setActiveId}
                    >
                      Copy Code
                    </div>
                  </div>
                )}
              </div>

              <div className={`${active === 1 ? "d-block" : "d-none"}`}>
                <SyntaxHighlighter
                  language="javascript"
                  style={atomOneDark}
                  className="p-1   p-sm-2 br-bottom"
                >
                  {codeString}
                </SyntaxHighlighter>
              </div>

              <div className={`${active === 2 ? "d-block" : "d-none"}`}>
                <SyntaxHighlighter
                  language="javascript"
                  style={atomOneDark}
                  className="p-1   p-sm-2"
                >
                  {styleString}
                </SyntaxHighlighter>
              </div>
            </div>
        </Col>
      </Row>
    </div>
  );
};

export default CompleteProfileCode;
