import React from "react";
import "./NewLogoDesign.scss";

import { Avatar, Space } from "antd";

import { useState } from "react";

const data = [
  {
    id: 1,
    updatetitle: "Collect & Research Data",
  },
  { id: 2, updatetitle: "Brainstorming" },

  {
    id: 3,
    updatetitle: "Create Moodboard",
  },
];

const NewLogoDesignCard = () => {
  const [checkedItems, setCheckedItems] = useState([]);

  function toggleActive(id) {
    if (checkedItems.includes(id)) {
      setCheckedItems(checkedItems.filter((item) => item !== id));
    } else {
      setCheckedItems([...checkedItems, id]);
    }
  }
  return (
    <div className="card-wrapper  d-flex flex-column">
      <div className=" card-header   d-flex justify-content-between  align-items-center">
        <div className="title-class">Square - New Logo design</div>
        <div className="time-class">1:54pm</div>
      </div>

      <div className="card-bottom-data d-flex ">
        <div className="profile-icon">
          <Avatar style={{ backgroundColor: "#A461D8" }}>RR</Avatar>
        </div>
        <div className="card-title-section d-flex  flex-column">
          <div className="card-title ">
            On :
            <span className="highlight-text">
              Iconspace - Senja Update & Modification,
              <br />
            </span>
            Reva Riveva checked off
          </div>

          <div className="d-flex flex-column gap-2">
            {data.map((item, index) => (
              <div
                className="check-box-area d-flex align-items-center  "
                key={index}
              >
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckChecked"
                  className="custom-check"
                  onChange={() => toggleActive(item.id)}
                  checked={checkedItems.includes(item.id)}
                />

                <div
                  className={`text-title ${
                    checkedItems.includes(item.id) ? "text-line-active" : ""
                  }`}
                >
                  {item.updatetitle}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewLogoDesignCard;
