import React from "react";
import "./MultipleLineGraph.scss";
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

const MultipleLineGraph = () => {
  const data = [
    { name: "JAN", sales: 14000, prevsale: 12000 },
    { name: "Feb", sales: 9000, prevsale: 11000 },
    { name: "Mar", sales: 22000, prevsale: 18000 },
    { name: "Apr", sales: 19000, prevsale: 21000 },
    { name: "May", sales: 6500, prevsale: 12000 },
    { name: "Jun", sales: 27632, prevsale: 29000 },
    { name: "July", sales: 1200 },
    { name: "Aug", sales: 0 },
    { name: "Sept", sales: 1400 },
    { name: "Oct", sales: 6000 },
    { name: "Nov", sales: 7000 },
    { name: "Decmb", sales: 9500 },
    // { name: "Page B", uv: 300, pv: 1398, amt: 2210 },
    // Add more data for other lines if needed
  ];

  return (
    <div className="sales-figure-wrapper d-flex flex-column ">
      <div className="  sales-figuer-header  d-flex justify-content-between gap-2 flex-wrap align-items-center">
        <div className="figure-title">Sales Figures</div>

        <div className="cases-tags-wrapper d-flex align-items-center gap-3">
          <div className=" cases-tags  d-flex align-items-center  gap-2">
            <div className="">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="12"
                viewBox="0 0 10 12"
                fill="none"
              >
                <rect width="10" height="12" rx="5" fill="#0062FF" />
              </svg>
            </div>
            <div className="tag-title"> Marketing Sales</div>
          </div>

          <div className="cases-tags  d-flex align-items-center  gap-2">
            <div className="">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="12"
                viewBox="0 0 10 12"
                fill="none"
              >
                <rect width="10" height="12" rx="5" fill="#3DD598" />
              </svg>
            </div>
            <div className="tag-title"> Cases Sales</div>
          </div>
        </div>
      </div>
      <ResponsiveContainer width="100%" aspect={3}>
        <LineChart width={600} height={300} data={data}>
          <CartesianGrid
            stroke="#ccc"
            strokeDasharray="1 "
            vertical="true"
            horizontal=""
            // strokeLinecap="dash"
          />
          <XAxis dataKey="name" tickMargin={20} fontSize={12} />
          <YAxis tickMargin={20} fontSize={12} />
          <Tooltip />
          {/* <Legend /> */}
          <Line
            type="monotone"
            dataKey="sales"
            stroke="#0062FF"
            strokeWidth={2}
          />
          <Line
            type="monotone"
            dataKey="prevsale"
            stroke="#3DD598"
            strokeWidth={2}
          />

          {/* Add more <Line> components with different dataKey props for each line */}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default MultipleLineGraph;
