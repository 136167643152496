import React from "react";
import "./SalesLocation.scss";
import { Row, Col } from "react-bootstrap";
import img from "../../assets/images/US.svg";
import map from "../../assets/images/Map.png";
import Badge from "react-bootstrap/Badge";

const SalesLocation = () => {
  return (
    <div className="Location-wrapper">
      <Row className="mx-0">
        <Col md={4}>
          <div className="d-flex flex-column gap-4">
            <div className="location-title">Top Retail Sales Locations</div>

            <div className="d-flex flex-column gap-2">
              <div className="d-flex align-items-center gap-2">
                <div className="sale-count">15.870</div>

                <div>
                  <img src={img}></img>
                </div>
              </div>

              <div className="count-desc">Our most customers in US</div>
            </div>

            <div className="d-flex flex-column gap-3">
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center gap-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="12"
                    viewBox="0 0 10 12"
                    fill="none"
                  >
                    <rect width="10" height="12" rx="5" fill="#0062FF" />
                  </svg>

                  <div className="fount-14  fount-regular">Massive</div>
                </div>

                <div className="fount-14 fount-bold ">15.7k</div>
              </div>

              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center gap-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="12"
                    viewBox="0 0 10 12"
                    fill="none"
                  >
                    <rect width="10" height="12" rx="5" fill="#FF974A" />
                  </svg>

                  <div className="fount-14  fount-regular">Large</div>
                </div>

                <div className="fount-14 fount-bold ">4.9k</div>
              </div>

              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center gap-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="12"
                    viewBox="0 0 10 12"
                    fill="none"
                  >
                    <rect width="10" height="12" rx="5" fill="#FFC542" />
                  </svg>

                  <div className="fount-14  fount-regular">Medium</div>
                </div>

                <div className="fount-14 fount-bold ">2.4k</div>
              </div>

              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center gap-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="12"
                    viewBox="0 0 10 12"
                    fill="none"
                  >
                    <rect width="10" height="12" rx="5" fill="#E2E2EA" />
                  </svg>

                  <div className="fount-14  fount-regular">Small</div>
                </div>

                <div className="fount-14 fount-bold ">980</div>
              </div>
            </div>
          </div>
        </Col>
        <Col md={8}>
          <div className="map-img-otr">
            <img src={map} className="map-img-inr"></img>

            <div className="map-badge">
              {" "}
              <Badge bg="dark">United State</Badge>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default SalesLocation;
