import React, { useEffect, useState } from "react";

import { Col, Row } from "react-bootstrap";
import SalesCard from "../../Components/SalesCard/SalesCard";
import SyntaxHighlighter from "react-syntax-highlighter";
import { atomOneDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import NewLogoDesignCard from "../../Components/NewLogoDesign/NewLogoDesignCard";
import {
  StarOutlined,
  StarFilled,
  StarTwoTone,
  CopyOutlined,
  CheckOutlined,
} from "@ant-design/icons";

const LogoDesignCode = () => {
  const codeString = `
  import React from "react";
  import "./NewLogoDesign.scss";
  
  import { Avatar, Space } from "antd";
  
  import { useState } from "react";
  
  const data = [
    {
      id: 1,
      updatetitle: "Collect & Research Data",
    },
    { id: 2, updatetitle: "Brainstorming" },
  
    {
      id: 3,
      updatetitle: "Create Moodboard",
    },
  ];
  
  const NewLogoDesignCard = () => {
    const [checkedItems, setCheckedItems] = useState([]);
  
    function toggleActive(id) {
      if (checkedItems.includes(id)) {
        setCheckedItems(checkedItems.filter((item) => item !== id));
      } else {
        setCheckedItems([...checkedItems, id]);
      }
    }
    return (
      <div className="card-wrapper  d-flex flex-column">
        <div className=" card-header   d-flex justify-content-between  align-items-center">
          <div className="title-class">Square - New Logo design</div>
          <div className="time-class">1:54pm</div>
        </div>
  
        <div className="card-bottom-data d-flex ">
          <div className="profile-icon">
            <Avatar style={{ backgroundColor: "#A461D8" }}>RR</Avatar>
          </div>
          <div className="card-title-section d-flex  flex-column">
            <div className="card-title ">
              On :
              <span className="highlight-text">
                Iconspace - Senja Update & Modification,
                <br />
              </span>
              Reva Riveva checked off
            </div>
  
            <div className="d-flex flex-column gap-2">
              {data.map((item, index) => (
                <div
                  className="check-box-area d-flex align-items-center  "
                  key={index}
                >
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckChecked"
                    className="custom-check"
                    onChange={() => toggleActive(item.id)}
                    checked={checkedItems.includes(item.id)}
                  />
  
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  export default NewLogoDesignCard;
  

    
          `;

  const styleString = `
  @import "../../CommonStyle/Variables.scss";
.card-wrapper {
  background-color: $light;
  padding: 20px 40px 25px 20px;
  border-radius: 15px;
  gap: 20px;

  .card-header {
    .title-class {
      font-size: $font-size-14px;
      line-height: $line-height-16px;
      letter-spacing: 0.09px;
      color: $gray29d;
      font-weight: $font-weight-regular;
    }

    .time-class {
      font-size: $font-size-12px;
      line-height: $line-height-16px;
      color: $gray44f;
      font-weight: $font-weight-regular;
    }
  }

  .card-bottom-data {
    gap: 20px;

    .profile-icon {
      padding-top: 10pxs;
    }
    .card-title-section {
      gap: 10px;
      .card-title {
        max-width: 365px;
        font-size: $font-size-16px;
        line-height: $line-height-26px;
        color: $gray44f;
        font-weight: $font-weight-medium;
        text-align: start;

        .highlight-text {
          color: $blue0FF;
        }
      }

      .check-box-area {
        gap: 10px;

        .custom-check {
          width: 20px;
          height: 20px;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          border: 1px solid #d0d5dd;
          border-radius: 6px;
          position: relative;
        }

        .custom-check:checked::before {
        content: "(2713)"; /* Unicode character for checkmark */
          font-size: 16px; /* Adjust the font size of the checkmark */
          color: $light; /* Set the color of the checkmark */
          position: absolute;
          background-color: $green98;
          border-radius: 6px;
          top: 50%;
          left: 50%;
          width: 20px;
          height: 20px;
          display: flex;
          justify-content: center;
          align-items: center;

          transform: translate(-50%, -50%);
        }

        .text-title {
          font-size: $font-size-14px;
          line-height: $line-height-16px;
          color: $gray44f;
          font-weight: $font-weight-medium;
        }

        .text-line-active {
          text-decoration: line-through;
          text-decoration-thickness: 2px;
        }
      }
    }

    .bottom-data-content {
      gap: 20px;
    }
  }   
          `;

  const [copy, setCopy] = useState(false);
  const [styleCopy, setStyleCopy] = useState(false);
  const [active, setActive] = useState(1);

  const setActiveId = () => {
    if (active === 1) {
      navigator.clipboard.writeText(codeString);
      setCopy(true);
    }

    if (active === 2) {
      navigator.clipboard.writeText(styleString);
      setCopy(true);
    }
  };

  useEffect(() => {
    setCopy(false);
  }, [active]);
  return (
    <div>
      <div className="">
        <Row className="mx-0 gy-3 gy-lg-0 mt-0">
          <Col lg={3} className="position-relative">
            <div className="custom-position-sticky">
              <NewLogoDesignCard />
            </div>
          </Col>

          <Col lg={9} className="d-flex flex-column  gap-4 ">
            <div>
              <div className="d-flex justify-content-between align-items-center  p-3  bg-secondary  br-top">
                <Nav variant="pills" className="d-flex  gap-2 ">
                  <Nav.Item>
                    <Nav.Link
                      eventKey="first"
                      onClick={() => setActive(1)}
                      className={`custom-nav-link  ${
                        active === 1 ? "active" : ""
                      }`}
                    >
                      JSX
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="second"
                      onClick={() => setActive(2)}
                      className={active === 2 ? "active" : ""}
                    >
                      CSS
                    </Nav.Link>
                  </Nav.Item>
                </Nav>

                {copy ? (
                  <div className="d-flex gap-2 align-items-center">
                    <CheckOutlined
                      style={{
                        fontSize: "20px",
                        color: "#ffffff",
                      }}
                    />
                    <div
                      className="text-light  cu-pointer"
                      onClick={() => {
                        navigator.clipboard.writeText("").then(() => {
                          setCopy(false);
                        });
                      }}
                    >
                      Copied
                    </div>
                  </div>
                ) : (
                  <div className="d-flex gap-2 align-items-center">
                    <CopyOutlined
                      style={{
                        fontSize: "20px",
                        color: "#ffffff",
                      }}
                    />

                    <div
                      className="text-light  cu-pointer"
                      onClick={setActiveId}
                    >
                      Copy Code
                    </div>
                  </div>
                )}
              </div>

              <div className={`${active === 1 ? "d-block" : "d-none"}`}>
                <SyntaxHighlighter
                  language="javascript"
                  style={atomOneDark}
                  className="p-1   p-sm-2 br-bottom"
                >
                  {codeString}
                </SyntaxHighlighter>
              </div>

              <div className={`${active === 2 ? "d-block" : "d-none"}`}>
                <SyntaxHighlighter
                  language="javascript"
                  style={atomOneDark}
                  className="p-1   p-sm-2"
                >
                  {styleString}
                </SyntaxHighlighter>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default LogoDesignCode;
