import React from "react";
import "./StudioCard.scss";
import img from "../../assets/images/Team photo.png";
import img2 from "../../assets/images/PP.png";
import { AntDesignOutlined, UserOutlined } from "@ant-design/icons";

import { Avatar, Divider, Tooltip } from "antd";

const StudioCard = () => {
  return (
    <div className="studio-card-wrapper">
      <div className="d-flex flex-column gap-3">
        <div className="d-flex justify-content-between  card-header align-items-center">
          <div className="d-flex gap-2 align-items-center">
            <div>
              <img src={img}></img>
            </div>

            <div className="card-title">Sebo Studio</div>
          </div>

          <div className="cu-pointer  add-icon " type="button">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7 12C7 10.8954 6.10457 10 5 10C3.89543 10 3 10.8954 3 12C3 13.1046 3.89543 14 5 14C6.10457 14 7 13.1046 7 12Z"
                fill="#92929D"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14C13.1046 14 14 13.1046 14 12Z"
                fill="#92929D"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M21 12C21 10.8954 20.1046 10 19 10C17.8954 10 17 10.8954 17 12C17 13.1046 17.8954 14 19 14C20.1046 14 21 13.1046 21 12Z"
                fill="#92929D"
              />
            </svg>
          </div>
        </div>

        <div>
          <Avatar.Group
            maxCount={3}
            size="large"
            maxStyle={{
              color: "#f56a00",
              backgroundColor: "#fde3cf",
            }}
            className=""
          >
            <Avatar src={img2} />
            <Avatar
              style={{
                backgroundColor: "#f56a00",
              }}
            >
              K
            </Avatar>
            <Avatar
              style={{
                backgroundColor: "#A461D8",
              }}
            >
              RR
            </Avatar>

            <Avatar
              style={{
                backgroundColor: "#A461D8",
              }}
            >
              K
            </Avatar>

            <Avatar
              style={{
                backgroundColor: "#A461D8",
              }}
            >
              K
            </Avatar>
            <Tooltip title="Ant User" placement="top">
              <Avatar
                style={{
                  backgroundColor: "#87d068",
                }}
                icon={<UserOutlined />}
              />
            </Tooltip>
            <Avatar
              style={{
                backgroundColor: "#1890ff",
              }}
              icon={<UserOutlined />}
            />

            <Avatar src={img2} />

            <Avatar src={img2} />
          </Avatar.Group>
        </div>
      </div>
    </div>
  );
};

export default StudioCard;
