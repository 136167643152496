import React from "react";
import "./CompleteProfile.scss";
import { Progress } from "antd";

const CompleteProfile = () => {
  return (
    <div className="d-flex flex-column  complete-Profile-Card-wraper gap-4">
      <div className="head-title">Complete Your Profile</div>
      <Progress percent={85} strokeColor="#0062FF" />
    </div>
  );
};

export default CompleteProfile;
