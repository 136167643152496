import React, { useState } from "react";
import "./AccordionDrop.scss";
import { Avatar, Space, message } from "antd";
import img from "../../assets/images/PP.png";
import img2 from "../../assets/images/Profile.png";
import msj from "../../assets/images/msj.png";
import report from "../../assets/images/reoprt.png";
import animation from "../../assets/images/animation.png";
import audio from "../../assets/images/audio.png";

const dropdowndata = [
  {
    id: 1,
    profiletitle: "Ibnu Mas’ud",
    profilemail: "ibnu@sebostudio.com",
    profileimg: img,
    type: "profile",
  },
  {
    id: 2,
    profiletitle: "Ibnu Sukoco",
    profilemail: "sukocoib@gmail.com",
    profileimg: img,
    type: "profile",
  },

  {
    id: 3,
    profiletitle: "Heylink Project",
    profilemail: "Ibnu, Andreas@mail.com",
    profileimg: msj,
    type: "message",
  },
  {
    id: 4,
    profiletitle: "Heylink Project",
    profilemail: "Ibnu, Andreas@mail.com",
    profileimg: msj,
    type: "message",
  },
  {
    id: 5,
    profiletitle: "Heylink Project",
    profilemail: "Ibnu, Andreas@mail.com",
    profileimg: msj,
    type: "message",
  },
  {
    id: 6,
    profiletitle: "Heylink Project",
    profilemail: "Ibnu, Andreas@mail.com",
    profileimg: msj,
    type: "message",
  },

  {
    id: 6,
    profiletitle: "Ibnu Monthly Report",
    profilemail: "Ibnu Mas’ud",
    profileimg: msj,
    type: "report",
  },

  {
    id: 7,
    profiletitle: "Animation Iconset",
    profilemail: "Ibnu Mas’ud",
    profileimg: animation,
    type: "animation",
  },
  {
    id: 8,
    profiletitle: "Testimonial for Ibnu’s Studio",
    profilemail: "Ibnu Mas’ud",
    profileimg: animation,
    type: "audio",
  },
];

const faqsdata = [
  {
    id: 1,
    faqsTitle: "Where is IPS-M located?",
    faqsDesc: `Our location is at the Cairo St., Al Mamzar,
          Dubai.Here’s the Google Map link:IPS-M LocationWe are
          located right next to Seniors’Happiness Centre.`,
  },
];
const AccordionDrop = () => {
  const [Classactive, setClassActive] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [filterData, setFilterData] = useState([]);

  function toggleactive(id) {
    setClassActive(id);
  }

  function handleSearchChange(event) {
    setSearchValue(event.target.value);
    filterDataBySearch(event.target.value);
  }

  function filterDataBySearch(value) {
    const filtered = dropdowndata.filter((item) => {
      return item.profiletitle.toLowerCase().includes(value.toLowerCase());
    });
    setFilterData(filtered);
  }

  console.log(filterData, "Filteres Data");
  return (
    <div>
      <div className="accordian-container">
        <div className="accordion" id="accordionExample">
          {faqsdata.map((e) => (
            <div
              className={`accordion-item   ${
                Classactive === e.id ? "accordion-itemActive" : ""
              }`}
              key={e.id}
              onClick={() => toggleactive(e.id)}
            >
              <h2 className="accordion-header" id={`heading-${e.id}`}>
                <button
                  className={`accordion-button px-0 py-0 ${
                    Classactive === e.id ? "active-link" : ""
                  }`}
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#collapse-${e.id}`}
                  aria-expanded="true"
                  aria-controls={`collapse-${e.id}`}
                >
                  <div className="d-flex  align-items-center gap-3 w-100">
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="21"
                        height="20"
                        viewBox="0 0 21 20"
                        fill="none"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M16.4802 14.6683L20.0943 18.2825C21.0371 19.2253 19.6229 20.6395 18.6801 19.6967L15.1092 16.1257C11.5103 19.2061 6.08905 19.0432 2.6829 15.6371C-0.894301 12.0599 -0.894301 6.2601 2.6829 2.6829C6.2601 -0.894301 12.0599 -0.894301 15.6371 2.6829C18.9069 5.95265 19.1879 11.0794 16.4802 14.6683ZM14.2229 4.09712C11.4267 1.30096 6.89327 1.30096 4.09712 4.09712C1.30096 6.89327 1.30096 11.4267 4.09712 14.2229C6.89327 17.019 11.4267 17.019 14.2229 14.2229C17.019 11.4267 17.019 6.89327 14.2229 4.09712Z"
                          fill="#92929D"
                        />
                      </svg>
                    </div>

                    <input
                      className="custom-search-bar"
                      onChange={handleSearchChange}
                      value={searchValue}
                      placeholder="Search Here"
                    ></input>
                  </div>
                </button>
              </h2>
              <div
                id={`collapse-${e.id}`}
                className={`accordion-collapse collapse   ${
                  Classactive === e.id ? " show" : ""
                }`}
                aria-labelledby={`heading-${e.id}`}
                data-bs-parent="#accordionExample"
              >
                <div className="d-flex flex-column gap-2">
                  {filterData.map((item, index) => (
                    <div className="accordion-body d-flex gap-2  " key={index}>
                      {item.type === "profile" ? (
                        <Avatar
                          shape="square"
                          src={item.profileimg}
                          size={24}
                        />
                      ) : (
                        " "
                      )}

                      {item.type === "message" ? (
                        <div className="message-icon">
                          <img src={msj}></img>
                        </div>
                      ) : (
                        " "
                      )}

                      {item.type === "report" ? (
                        <div className="report-icon">
                          <img src={report}></img>
                        </div>
                      ) : (
                        " "
                      )}

                      {item.type === "animation" ? (
                        <div className="animation-icon">
                          <img src={animation}></img>
                        </div>
                      ) : (
                        " "
                      )}

                      {item.type === "audio" ? (
                        <div className="audio-icon">
                          <img src={audio}></img>
                        </div>
                      ) : (
                        " "
                      )}

                      <div className="d-flex flex-column gap-2  ">
                        <div className="profle-title">{item.profiletitle}</div>
                        <div className="profile-mail">{item.profilemail}</div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AccordionDrop;
