import React from "react";
import "./PubgCard.scss";
import img from "../../assets/images/Team photo.png";
import img2 from "../../assets/images/PP.png";
import { AntDesignOutlined, UserOutlined } from "@ant-design/icons";
import "./PubgCard.scss";
import cardimg from "../../assets/images/cardimg.png";
import { Avatar, Divider, Tooltip } from "antd";

const PubgCard = () => {
  return (
    <div className="d-flex flex-column  card-container">
      <div className="img-section">
        <img src={cardimg} className="card-img"></img>
      </div>
      <div className="card-data-section">
        <div className="d-flex align-items-center gap-3">
          <div className="date-tag">27</div>
          <div className="d-flex flex-column gap-3">
            <div className="card-title-tag">PUBG Competition</div>
            <div className="card-desc-tag">Thu 10.00 . Hartono Mall, YK</div>

            <div>
              <Avatar.Group
                maxCount={3}
                size="large"
                maxStyle={{
                  color: "#696974",
                  backgroundColor: "#ffff",
                  border: "1px solid #E2E2EA",
                }}
                className="avataor-group flex-wrap"
              >
                <Avatar src={img2} />
                <Avatar
                  style={{
                    backgroundColor: "#f56a00",
                  }}
                >
                  K
                </Avatar>
                <Avatar
                  style={{
                    backgroundColor: "#A461D8",
                  }}
                >
                  RR
                </Avatar>

                <Avatar
                  style={{
                    backgroundColor: "#A461D8",
                  }}
                >
                  K
                </Avatar>

                <Avatar
                  style={{
                    backgroundColor: "#A461D8",
                  }}
                >
                  K
                </Avatar>
                <Tooltip title="Ant User" placement="top">
                  <Avatar
                    style={{
                      backgroundColor: "#87d068",
                    }}
                    icon={<UserOutlined />}
                  />
                </Tooltip>
                <Avatar
                  style={{
                    backgroundColor: "#1890ff",
                  }}
                  icon={<UserOutlined />}
                />
              </Avatar.Group>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PubgCard;
