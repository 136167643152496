import React, { useState } from "react";
import "./ProjectSchedule.scss";
import { Avatar, Divider, Tooltip } from "antd";
import img1 from "../../assets/images/PP.png";

const data = [
  {
    date: "1 Apr, 2018",
    projects: [
      {
        projecttitle: "Kick off Project",
        completeddate: "Completed Apr 20 ",
        projectdesc: `
        Circle - Dashboard, Stats, and UI Kit`,

        assignedpersons: [img1, img1],
      },
    ],
  },

  {
    date: "18 Apr, 2018",
    projects: [
      {
        id: 1,
        projecttitle: "Create Flow Chart",
        completeddate: "Completed Apr 20 ",
        projectdesc: `
        Circle - Dashboard, Stats, and UI Kit`,

        assignedpersons: [img1, img1],
      },
    ],
  },

  {
    date: "23 Apr, 2018",
    projects: [
      {
        id: 2,
        projecttitle: "Create Moodboard",
        completeddate: "Completed Apr 23 ",
        projectdesc: `
        Circle - Dashboard, Stats, and UI Kit`,

        assignedpersons: [img1, img1],
      },

      {
        id: 3,
        projecttitle: "Wireframing",
        completeddate: "Completed Apr 20 ",
        projectdesc: `
        Circle - Dashboard, Stats, and UI Kit`,

        assignedpersons: [img1, img1, img1],
      },

      {
        id: 4,
        projecttitle: "Add one section in about us page Square",
        completeddate: "",
        projectdesc: `
        Development - Square Website`,

        assignedpersons: [img1],
      },
    ],
  },

  {
    date: "29 Apr, 2018",
    projects: [
      {
        id: 5,
        projecttitle: "[Dev] Homepage - Circle website",
        completeddate: " ",
        projectdesc: `
        Development - Circle Website`,

        assignedpersons: [img1, img1],
      },
    ],
  },

  {
    date: "30 Apr, 2018",
    projects: [
      {
        id: 6,
        projecttitle: "[Dev] Homepage - Circle website",
        completeddate: " ",
        projectdesc: `
        Development - Circle Website`,

        assignedpersons: [img1],
      },
    ],
  },

  {
    date: "18 Apr, 2018",
    projects: [
      {
        id: 7,
        projecttitle: "Create Flow Chart",
        completeddate: "Completed Apr 20 ",
        projectdesc: `
        Circle - Dashboard, Stats, and UI Kit`,

        assignedpersons: [img1, img1],
      },
    ],
  },
];

const ProjectSchedule = () => {
  const [showAllCustomers, setShowAllCustomers] = useState(false);

  const [checkedItems, setCheckedItems] = useState([]);

  function toggleActive(id) {
    if (checkedItems.includes(id)) {
      setCheckedItems(checkedItems.filter((item) => item !== id));
    } else {
      setCheckedItems([...checkedItems, id]);
    }
  }

  const toggleShowAllCustomers = () => {
    setShowAllCustomers(!showAllCustomers);
  };
  return (
    <div className="project-schedule-wrapper d-flex flex-column  ">
      <div className=" d-flex flex-column  gap-4  items-area">
        {data
          .slice(0, showAllCustomers ? data.length : 4)
          .map((item, index) => (
            <div
              className="d-flex  flex-wrap gap-4 align-items-start schedule-item"
              key={index}
            >
              <div className="date-area  d-flex align-items-center gap-2">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M1.66602 9.1665H18.3327V14.1665C18.3327 16.4677 16.4672 18.3332 14.166 18.3332H5.83268C3.5315 18.3332 1.66602 16.4677 1.66602 14.1665V9.1665Z"
                      stroke="#92929D"
                      stroke-width="1.5"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M3.33268 4.1665H16.666C17.5865 4.1665 18.3327 4.9127 18.3327 5.83317V9.1665H1.66602V5.83317C1.66602 4.9127 2.41221 4.1665 3.33268 4.1665Z"
                      stroke="#92929D"
                      stroke-width="1.5"
                    />
                    <path
                      d="M14.9993 2.5V5"
                      stroke="#92929D"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M5.83333 2.5V5"
                      stroke="#92929D"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <div className="date-tag">{item.date}</div>
              </div>
              <div className="d-flex flex-column gap-3">
                {item.projects.map((project, projectIndex) => (
                  <div
                    className="d-flex flex-column gap-2  sch-desc-area"
                    key={projectIndex}
                  >
                    <div className="d-flex align-items-center  gap-2">
                      <div className="d-flex align-items-center gap-1">
                        <input
                          className="form-check-input custom-check"
                          type="checkbox"
                          value=""
                          id="flexCheckChecked"
                          onChange={() => toggleActive(project.id)}
                          checked={checkedItems.includes(project.id)}
                        />
                        <div
                          className={`project-title  ${
                            checkedItems.includes(project.id)
                              ? "text-line-active"
                              : ""
                          }`}
                        >
                          {project.projecttitle}
                        </div>
                      </div>
                      <div className="d-flex gap-1">
                        {project.assignedpersons.map(
                          (assignedPerson, assignedIndex) => (
                            <Avatar
                              src={assignedPerson}
                              size={18}
                              key={assignedIndex}
                            />
                          )
                        )}
                      </div>
                    </div>

                    <div
                      className={`completed-date  ${
                        project.completeddate === "" ? "d-none" : " "
                      } `}
                    >
                      {project.completeddate}
                    </div>
                    <div className="completed-date">{project.projectdesc}</div>
                  </div>
                ))}
              </div>
            </div>
          ))}
      </div>

      <div
        className="project-card-footer d-flex flex-wrap justify-content-center  cu-pointer"
        onClick={toggleShowAllCustomers}
      >
        {showAllCustomers ? "View less Customers" : "View more Customers"}
      </div>
    </div>
  );
};

export default ProjectSchedule;
