import React, { useState,useEffect } from "react";
import { Col, Row } from "react-bootstrap";

import SyntaxHighlighter from "react-syntax-highlighter";
import { atomOneDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import Order from "../Components/OrderCard/Order";
import VisitorSlider from "../Components/VisitorSlider/VisitorSlider";
import StudioCard from "../Components/StudioCard/StudioCard";
import {
    StarOutlined,
    StarFilled,
    StarTwoTone,
    CopyOutlined,
    CheckOutlined,
  } from "@ant-design/icons";

const SeboStudioCode = () => {
  const codeString = `
    import React from "react";
    import "./StudioCard.scss";
    import img from "../../assets/images/Team photo.png";
    import img2 from "../../assets/images/PP.png";
    import { AntDesignOutlined, UserOutlined } from "@ant-design/icons";
    
    import { Avatar, Divider, Tooltip } from "antd";
    
    const StudioCard = () => {
      return (
        <div className="studio-card-wrapper">
          <div className="d-flex flex-column gap-3">
            <div className="d-flex justify-content-between  card-header align-items-center">
              <div className="d-flex gap-2 align-items-center">
                <div>
                  <img src={img}></img>
                </div>
    
                <div className="card-title">Sebo Studio</div>
              </div>
    
              <div className="cu-pointer  add-icon " type="button">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M7 12C7 10.8954 6.10457 10 5 10C3.89543 10 3 10.8954 3 12C3 13.1046 3.89543 14 5 14C6.10457 14 7 13.1046 7 12Z"
                    fill="#92929D"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14C13.1046 14 14 13.1046 14 12Z"
                    fill="#92929D"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M21 12C21 10.8954 20.1046 10 19 10C17.8954 10 17 10.8954 17 12C17 13.1046 17.8954 14 19 14C20.1046 14 21 13.1046 21 12Z"
                    fill="#92929D"
                  />
                </svg>
              </div>
            </div>
    
            <div>
              <Avatar.Group
                maxCount={3}
                size="large"
                maxStyle={{
                  color: "#f56a00",
                  backgroundColor: "#fde3cf",
                }}
                className=""
              >
                <Avatar src={img2} />
                <Avatar
                  style={{
                    backgroundColor: "#f56a00",
                  }}
                >
                  K
                </Avatar>
                <Avatar
                  style={{
                    backgroundColor: "#A461D8",
                  }}
                >
                  RR
                </Avatar>
    
                <Avatar
                  style={{
                    backgroundColor: "#A461D8",
                  }}
                >
                  K
                </Avatar>
    
                <Avatar
                  style={{
                    backgroundColor: "#A461D8",
                  }}
                >
                  K
                </Avatar>
                <Tooltip title="Ant User" placement="top">
                  <Avatar
                    style={{
                      backgroundColor: "#87d068",
                    }}
                    icon={<UserOutlined />}
                  />
                </Tooltip>
                <Avatar
                  style={{
                    backgroundColor: "#1890ff",
                  }}
                  icon={<UserOutlined />}
                />
    
                <Avatar src={img2} />
    
                <Avatar src={img2} />
              </Avatar.Group>
            </div>
          </div>
        </div>
      );
    };
    
    export default StudioCard;
    
    
      
            `;

  const styleString = `
    @import "../../CommonStyle/Variables.scss";
.studio-card-wrapper {
  background-color: $light;
  padding: 20px;
  border-radius: 15px;

  .card-header {
    .add-icon:hover svg path {
      fill: $green98;
    }
    .card-title {
      font-size: $font-size-16px;
      line-height: $line-height-24px;
      font-weight: $font-weight-bold;
    }
  }

  .ant-avatar-group {
    gap: 20px;
  }
}

        
            `;

  const [copy, setCopy] = useState(false);
  const [styleCopy, setStyleCopy] = useState(false);
  const [active, setActive] = useState(1);


  const setActiveId = () => {
    if (active === 1) {
      navigator.clipboard.writeText(codeString);
      setCopy(true);
    }

    if (active === 2) {
      navigator.clipboard.writeText(styleString);
      setCopy(true);
    }
  };

  useEffect(() => {
    setCopy(false);
  }, [active]);
  return (
    <div>
      <Row className="mx-0 gy-lg-0  gy-3 ">
        <Col lg={4} className="position-relative">
          <div className="custom-position-sticky">
            <StudioCard />
          </div>
        </Col>

        <Col lg={8} className="d-flex flex-column  gap-4 ">
        <div>
              <div className="d-flex justify-content-between align-items-center  p-3  bg-secondary  br-top">
                <Nav variant="pills" className="d-flex  gap-2 ">
                  <Nav.Item>
                    <Nav.Link
                      eventKey="first"
                      onClick={() => setActive(1)}
                      className={active === 1 ? "active" : ""}
                    >
                       JSX
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="second"
                      onClick={() => setActive(2)}
                      className={active === 2 ? "active" : ""}
                    >
                      CSS
                    </Nav.Link>
                  </Nav.Item>
                </Nav>

                {copy ? (
                  <div className="d-flex gap-2 align-items-center">
                    <CheckOutlined
                      style={{
                        fontSize: "20px",
                        color: "#ffffff",
                      }}
                    />
                    <div
                      className="text-light  cu-pointer"
                      onClick={() => {
                        navigator.clipboard.writeText("").then(() => {
                          setCopy(false);
                        });
                      }}
                    >
                      Copied
                    </div>
                  </div>
                ) : (
                  <div className="d-flex gap-2 align-items-center">
                    <CopyOutlined
                      style={{
                        fontSize: "20px",
                        color: "#ffffff",
                      }}
                    />

                    <div
                      className="text-light  cu-pointer"
                      onClick={setActiveId}
                    >
                      Copy Code
                    </div>
                  </div>
                )}
              </div>

              <div className={`${active === 1 ? "d-block" : "d-none"}`}>
                <SyntaxHighlighter
                  language="javascript"
                  style={atomOneDark}
                  className="p-1   p-sm-2 br-bottom"
                >
                  {codeString}
                </SyntaxHighlighter>
              </div>

              <div className={`${active === 2 ? "d-block" : "d-none"}`}>
                <SyntaxHighlighter
                  language="javascript"
                  style={atomOneDark}
                  className="p-1   p-sm-2"
                >
                  {styleString}
                </SyntaxHighlighter>
              </div>
            </div>
        </Col>
      </Row>
    </div>
  );
};

export default SeboStudioCode;
