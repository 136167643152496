import React, { useState, useEffect } from "react";

import { Col, Row } from "react-bootstrap";
import SalesCard from "../Components/SalesCard/SalesCard";
import SyntaxHighlighter from "react-syntax-highlighter";
import { atomOneDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import MultipleLineGraph from "../Components/MultipleLineGraph/MultipleLineGraph";

import {
  StarOutlined,
  StarFilled,
  StarTwoTone,
  CopyOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import PendingCard from "../Components/PendingComponent/PendingCard";
import ProjectSchedule from "../Components/ProjectSchedule/ProjectSchedule";

const ProjectSchduleCode = () => {
  const codeString = `
  import React, { useState } from "react";
  import "./ProjectSchedule.scss";
  import { Avatar, Divider, Tooltip } from "antd";
  import img1 from "../../assets/images/PP.png";
  
  const data = [
    {
      date: "1 Apr, 2018",
      projects: [
        {
          projecttitle: "Kick off Project",
          completeddate: "Completed Apr 20 ",
          projectdesc: "
          Circle - Dashboard, Stats, and UI Kit",
  
          assignedpersons: [img1, img1],
        },
      ],
    },
  
    {
      date: "18 Apr, 2018",
      projects: [
        {
          id: 1,
          projecttitle: "Create Flow Chart",
          completeddate: "Completed Apr 20 ",
          projectdesc: "
          Circle - Dashboard, Stats, and UI Kit,
  
          assignedpersons: [img1, img1],
        },
      ],
    },
  
    {
      date: "23 Apr, 2018",
      projects: [
        {
          id: 2,
          projecttitle: "Create Moodboard",
          completeddate: "Completed Apr 23 ",
          projectdesc: "
          Circle - Dashboard, Stats, and UI Kit",
  
          assignedpersons: [img1, img1],
        },
  
        {
          id: 3,
          projecttitle: "Wireframing",
          completeddate: "Completed Apr 20 ",
          projectdesc: "
          Circle - Dashboard, Stats, and UI Kit",
  
          assignedpersons: [img1, img1, img1],
        },
  
        {
          id: 4,
          projecttitle: "Add one section in about us page Square",
          completeddate: "",
          projectdesc: "
          Development - Square Website",
  
          assignedpersons: [img1],
        },
      ],
    },
  
    {
      date: "29 Apr, 2018",
      projects: [
        {
          id: 5,
          projecttitle: "[Dev] Homepage - Circle website",
          completeddate: " ",
          projectdesc: "
          Development - Circle Website",
  
          assignedpersons: [img1, img1],
        },
      ],
    },
  
    {
      date: "30 Apr, 2018",
      projects: [
        {
          id: 6,
          projecttitle: "[Dev] Homepage - Circle website",
          completeddate: " ",
          projectdesc: "Development - Circle Website,
  
          assignedpersons: [img1],
        },
      ],
    },
  
    {
      date: "18 Apr, 2018",
      projects: [
        {
          id: 7,
          projecttitle: "Create Flow Chart",
          completeddate: "Completed Apr 20 ",
          projectdesc: "
          Circle - Dashboard, Stats, and UI Kit",
  
          assignedpersons: [img1, img1],
        },
      ],
    },
  ];
  
  const ProjectSchedule = () => {
    const [showAllCustomers, setShowAllCustomers] = useState(false);
  
    const [checkedItems, setCheckedItems] = useState([]);
  
    function toggleActive(id) {
      if (checkedItems.includes(id)) {
        setCheckedItems(checkedItems.filter((item) => item !== id));
      } else {
        setCheckedItems([...checkedItems, id]);
      }
    }
  
    const toggleShowAllCustomers = () => {
      setShowAllCustomers(!showAllCustomers);
    };
    return (
      <div className="project-schedule-wrapper d-flex flex-column  ">
        <div className=" d-flex flex-column  gap-4  items-area">
          {data
            .slice(0, showAllCustomers ? data.length : 4)
            .map((item, index) => (
              <div
                className="d-flex gap-4 align-items-start schedule-item"
                key={index}
              >
                <div className="date-area  d-flex align-items-center gap-2">
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M1.66602 9.1665H18.3327V14.1665C18.3327 16.4677 16.4672 18.3332 14.166 18.3332H5.83268C3.5315 18.3332 1.66602 16.4677 1.66602 14.1665V9.1665Z"
                        stroke="#92929D"
                        stroke-width="1.5"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M3.33268 4.1665H16.666C17.5865 4.1665 18.3327 4.9127 18.3327 5.83317V9.1665H1.66602V5.83317C1.66602 4.9127 2.41221 4.1665 3.33268 4.1665Z"
                        stroke="#92929D"
                        stroke-width="1.5"
                      />
                      <path
                        d="M14.9993 2.5V5"
                        stroke="#92929D"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M5.83333 2.5V5"
                        stroke="#92929D"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  <div className="date-tag">{item.date}</div>
                </div>
                <div className="d-flex flex-column gap-3">
                  {item.projects.map((project, projectIndex) => (
                    <div
                      className="d-flex flex-column gap-2  sch-desc-area"
                      key={projectIndex}
                    >
                      <div className="d-flex align-items-center  gap-2">
                        <div className="d-flex align-items-center gap-1">
                          <input
                            className="form-check-input custom-check"
                            type="checkbox"
                            value=""
                            id="flexCheckChecked"
                            onChange={() => toggleActive(project.id)}
                            checked={checkedItems.includes(project.id)}
                          />
                          <div
                            className=project-title  
                              checkedItems.includes(project.id)
                                ? "text-line-active"
                                : ""
                            }
                          >
                            {project.projecttitle}
                          </div>
                        </div>
                        <div className="d-flex gap-1">
                          {project.assignedpersons.map(
                            (assignedPerson, assignedIndex) => (
                              <Avatar
                                src={assignedPerson}
                                size={18}
                                key={assignedIndex}
                              />
                            )
                          )}
                        </div>
                      </div>
  
                      <div
                        className=completed-date  
                          project.completeddate === "" ? "d-none" : " "
                        } 
                      >
                        {project.completeddate}
                      </div>
                      <div className="completed-date">{project.projectdesc}</div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
        </div>
  
        <div
          className="project-card-footer d-flex justify-content-center  cu-pointer"
          onClick={toggleShowAllCustomers}
        >
          {showAllCustomers ? "View less Customers" : "View more Customers"}
        </div>
      </div>
    );
  };
  
  export default ProjectSchedule;
  
    
      
    
        
              `;

  const styleString = `
  @import "../../CommonStyle/Variables.scss";
  .project-schedule-wrapper {
    background-color: $light;
    border-radius: 15px;
    padding-top: 50px;
  
    .items-area {
      padding-left: 40px;
    }
  
    .schedule-item {
      padding-bottom: 21px;
      border-bottom: 1px solid $lightea;
      width: 100%;
  
      .date-area {
        .date-tag {
          font-size: $font-size-14px;
          line-height: $line-height-18px;
          color: $gray44f;
          font-weight: $font-weight-bold;
        }
      }
  
      .sch-desc-area {
        .custom-check {
          width: 20px;
          height: 20px;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          border: 1px solid #d0d5dd;
          border-radius: 6px;
          position: relative;
        }
  
        .custom-check:checked::before {
          content: 2713; /* Unicode character for checkmark */
          font-size: 16px; /* Adjust the font size of the checkmark */
          color: $light; /* Set the color of the checkmark */
          position: absolute;
          background-color: $green98;
          border-radius: 6px;
          top: 50%;
          left: 50%;
          width: 20px;
          height: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
  
          transform: translate(-50%, -50%);
        }
  
        .project-title {
          font-size: $font-size-14px;
          line-height: $line-height-18px;
          color: $gray44f;
          font-weight: $font-weight-semibold;
        }
        .text-line-active {
          text-decoration: line-through;
          text-decoration-thickness: 2px;
        }
  
        .completed-date {
          font-size: $font-size-14px;
          line-height: $line-height-24px;
          color: $gray29d;
          font-weight: $font-weight-regular;
        }
      }
    }
  
    .project-card-footer {
      font-size: $font-size-14px;
      line-height: $line-height-22px;
      color: $blue2ff;
      font-weight: $font-weight-regular;
      text-decoration: underline;
      padding-top: 46px;
      padding-bottom: 47px;
    }
    .project-card-footer:hover {
      background-color: $blue2ff;
      color: $light !important;
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
    }
  }
  
       
              `;
  const [copy, setCopy] = useState(false);
  const [styleCopy, setStyleCopy] = useState(false);
  const [active, setActive] = useState(1);

  const setActiveId = () => {
    if (active === 1) {
      navigator.clipboard.writeText(codeString);
      setCopy(true);
    }

    if (active === 2) {
      navigator.clipboard.writeText(styleString);
      setCopy(true);
    }
  };

  useEffect(() => {
    setCopy(false);
  }, [active]);
  return (
    <div>
      <div className="">
        <Row className="mx-0 gy-lg-0  gy-3 ">
          <Col lg={5} className="position-relative">
            <div className="custom-position-sticky">
              <ProjectSchedule />
            </div>
          </Col>

          <Col lg={7} className="d-flex flex-column  gap-4 ">
            <div>
              <div className="d-flex justify-content-between align-items-center  p-3  bg-secondary  br-top">
                <Nav variant="pills" className="d-flex  gap-2 ">
                  <Nav.Item>
                    <Nav.Link
                      eventKey="first"
                      onClick={() => setActive(1)}
                      className={active === 1 ? "active" : ""}
                    >
                      JSX
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="second"
                      onClick={() => setActive(2)}
                      className={active === 2 ? "active" : ""}
                    >
                      CSS
                    </Nav.Link>
                  </Nav.Item>
                </Nav>

                {copy ? (
                  <div className="d-flex gap-2 align-items-center">
                    <CheckOutlined
                      style={{
                        fontSize: "20px",
                        color: "#ffffff",
                      }}
                    />
                    <div
                      className="text-light  cu-pointer"
                      onClick={() => {
                        navigator.clipboard.writeText("").then(() => {
                          setCopy(false);
                        });
                      }}
                    >
                      Copied
                    </div>
                  </div>
                ) : (
                  <div className="d-flex gap-2 align-items-center">
                    <CopyOutlined
                      style={{
                        fontSize: "20px",
                        color: "#ffffff",
                      }}
                    />

                    <div
                      className="text-light  cu-pointer"
                      onClick={setActiveId}
                    >
                      Copy Code
                    </div>
                  </div>
                )}
              </div>

              <div className={`${active === 1 ? "d-block" : "d-none"}`}>
                <SyntaxHighlighter
                  language="javascript"
                  style={atomOneDark}
                  className="p-1   p-sm-2 br-bottom"
                >
                  {codeString}
                </SyntaxHighlighter>
              </div>

              <div className={`${active === 2 ? "d-block" : "d-none"}`}>
                <SyntaxHighlighter
                  language="javascript"
                  style={atomOneDark}
                  className="p-1   p-sm-2"
                >
                  {styleString}
                </SyntaxHighlighter>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ProjectSchduleCode;
